import classNames from "classnames"
import React, { MouseEventHandler, ReactElement } from "react"

import { NavLink } from "react-router-dom"
import { MenuItemProps } from "./MenuItem"
import { Icon, IconType } from "./utils/Icon"
import { Span } from "./utils/Inlines"

export interface MenuItemLinkProps extends Omit<MenuItemProps, "icon"> {
    primaryText?: string;
    icon?: IconType | JSX.Element | ReactElement;
}

export const MenuItemLink: React.FC<MenuItemLinkProps> = ({ onClick, icon, className, children, to, primaryText }) => {
    const handleClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
        e.preventDefault()
        onClick && onClick()
    }

    const classes = classNames("menu-item", className)
    const itemIcon = typeof icon === "string" ? <Icon name={icon} className="mr-3" /> : (
        icon ? React.cloneElement(icon, { "className": "mr-3" }) : <Span className="mr-3 material-icons icon" />
    )

    if (to !== undefined) {
        return <NavLink to={to} className={classes} onClick={() => onClick && onClick()}>{itemIcon}{primaryText || children}</NavLink>
    }

    if (typeof onClick === "function") {
        return <a href="#" className={classes} onClick={handleClick}>{itemIcon}{primaryText || children}</a>
    }

    return (
        <div className={classes}>{itemIcon}{primaryText || children}</div>
    )
}

