import * as React from 'react';
import { ReactElement } from 'react';
import PropTypes from 'prop-types';
import {
    ShowContextProvider,
    ResourceContextProvider,
    useCheckMinimumRequiredProps,
    useShowController,
    Identifier,
    Record
} from 'ra-core';

import { ShowProps as ControllerShowProps } from 'ra-core/esm/controller/details/useShowController';
import { ShowView } from './ShowView';

/**
 * Page component for the Show view
 *
 * The `<Show>` component renders the page title and actions,
 * fetches the record from the data provider.
 * It is not responsible for rendering the actual form -
 * that's the job of its child component (usually `<SimpleShowLayout>`),
 * to which it passes the `record` as prop.
 *
 * The <Show> component accepts the following props:
 *
 * - actions
 * - component
 * - title
 *
 */
export const Show = (
    props: ShowProps & { children: ReactElement }
): ReactElement => {
    useCheckMinimumRequiredProps('Show', ['children'], props);
    const controllerProps = useShowController(props);

    const body = (
        <ShowContextProvider value={controllerProps}>
            <ShowView {...props} {...controllerProps} />
        </ShowContextProvider>
    );
    return props.resource ? (
        // support resource override via props
        <ResourceContextProvider value={props.resource}>
            {body}
        </ResourceContextProvider>
    ) : (
        body
    );
};

export interface ShowProps extends ControllerShowProps {
    actions?: JSX.Element | boolean;
    className?: string;
    mainTitle?: string;
    title?: string | ((record?: Record) => string | undefined);
    subtitle?: string | ((record?: Record) => string | undefined);
}

