import React from 'react';
import classnames from 'classnames';

export interface PlaceholderProps {
    className?: string;
    classes?: Record<'root', string>;
}

export const Placeholder: React.FC<PlaceholderProps> = props => {
    return (
        <div className={classnames("d-flex", "bg-placeholder", props.className)}>&nbsp;</div>
    );
};

