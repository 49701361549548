
import { Company } from "@faktio/jsclient";
import React, { Children, cloneElement, isValidElement, useContext } from "react";
import { CompanyContext } from "../Admin";
import SimpleForm, { SimpleFormProps } from "../ui/form/SimpleForm";
import { List, ListProps } from "../ui/list/List";

export const useCompany = () => useContext(CompanyContext)

export const CompanyListener = ({ children, ...rest }: { children: (company: Company) => React.ReactNode, [key: string]: any }) => {
    const { company } = useCompany()
    if (!company) return null

    const listenerChildren = children(company)

    return (<>
        {
            Children.map(listenerChildren, child =>
                child && isValidElement<any>(child) ?
                    cloneElement(child, { ...rest })
                    : null
            )
        }
    </>)
}

export type WithCompany<T> = T & { company: Company }

export function withCompany<P>(Component: React.ComponentType<WithCompany<P>>): React.ComponentType<P> {
    return (props) => {
        const { company } = useCompany()

        if (!company) return null
        const componentProps = { company, ...props } as WithCompany<P>
        return (<Component {...componentProps} />)
    }
}

export const SimpleCompanyForm = withCompany(
    ({ company, initialValues, ...rest }: WithCompany<SimpleFormProps>) =>
        <SimpleForm {...rest} initialValues={{ ...initialValues, companyId: company.getID() }} />
)

export const ListByCompany = withCompany(
    ({ company, filter, ...rest }: WithCompany<ListProps & { children: React.ReactElement }>) =>
        <List {...rest} filter={filter ? { ...filter, companyId: company.getID() } : { companyId: company.getID() }} />
)