import classNames from "classnames";
import { ComponentProps } from "../tools/createComponent";

export type IconType = string;

export type IconVariant = "outlined"

export interface IconProps extends ComponentProps {
    name: IconType
    variant?: IconVariant
    stroke?: boolean
}

export const Icon: React.FC<IconProps> = ({ name, className, variant, stroke, as, ...props }) => {
    const Component = as || "span"
    return (<Component className={classNames(
        "material-icons" + (variant ? `-${variant}` : ""),
        "icon",
        `icon-${name}`,
        stroke && "material-icons-stroke",
        className
    )} {...props}>{name}</Component>)
}

export const createIcon = (name: IconType, variant?: IconVariant): React.FC<Omit<IconProps, "name">> =>
    (props) => <Icon name={name} variant={variant} {...props} />