import moment from "moment"
import { Pagination, Sort, Model, API } from "@faktio/jsclient"

type ActionType = "activate" | "pdf"

interface DataProviderInterface<K, V> {
    getList: (resource: string, params: {
        search?: string, pagination: Pagination, sort: Sort, filter: Object
    }) => Promise<{ data: V[], total: number, validUntil?: Date }>;

    getOne: (resource: string, params: { id: K, query?: { [key: string]: string } }) => Promise<{ data: V, validUntil?: Date }>;
    getMany: (resource: string, params: { ids: K[] }) => Promise<{ data: V, validUntil?: Date }>;

    getManyReference: (resource: string, params: {
        target: string, id: K, pagination: Pagination, sort: { field: string, order: string }, filter: Object
    }) => Promise<{ data: V[], total: number }>;

    create: (resource: string, params: { data: V }) => Promise<{ data: V }>;
    update: (resource: string, params: { id: K, data: V, previousData: V }) => Promise<{ data: V }>;
    updateMany: (resource: string, params: { ids: K[], data: V }) => Promise<{ data: K[] }>;
    delete: (resource: string, params: { id: K, previousData: V }) => Promise<{ data: V }>;
    deleteMany: (resource: string, params: { ids: K[] }) => Promise<{ data: K[] }>;

    createAction: (resource: string, params: { id: K, action: ActionType, data: Object }) => Promise<{ data: any }>
    updateAction: (resource: string, params: { id: K, action: ActionType, data: Object }) => Promise<{ data: any }>
    deleteAction: (resource: string, params: { id: K, action: ActionType }) => Promise<{ data: any }>
    getAction: (resource: string, params: { id: K, action: ActionType }) => Promise<{ data: any }>
}

// TODO: Load from API
const getEndpoint = (resource: string) => {
    if (resource.startsWith("receipts/")) {
        return API.Receipts
    }
    switch (resource) {
        case "companies":
            return API.Companies
        case "cashRegisters":
            return API.CashRegisters
        case "users":
            return API.Users
        case "roles":
            return API.Roles
        case "products":
            return API.Products
        case "persons":
            return API.Persons
        case "receipts":
            return API.Receipts
        case "contacts":
            return API.Contacts
        case "aresICO":
            return API.AresICO
        case "aresName":
            return API.AresName
        case "vatPayers":
            return API.VATPayers
        case "numericSeries":
            return API.NumericSeries
        default:
            throw new Error("Invalid resource '" + resource + "'");
    }
}

const DataProvider: DataProviderInterface<number, Model<any, any>> = {
    getList: async (resource, { search, pagination, sort, filter }) => {
        const endpoint = new (getEndpoint(resource))()
        const data = await endpoint.getList(search, pagination, sort, filter)
        // TODO: Does it make sense?
        if (resource === "companies") return ({ data: data.toArray(), total: endpoint.getCount(), validUntil: moment().add(10, "minutes").toDate() })
        return ({ data: data.toArray(), total: endpoint.getCount() })
    },

    getOne: async (resource, { id, query }) => {
        const endpoint = new (getEndpoint(resource))()
        const data = await endpoint.getOne(id, query)
        if (resource === "companies") return ({ data, validUntil: moment().add(10, "minutes").toDate() })
        return ({ data })
    },

    getMany: async (resource, { ids }) => {
        const endpoint = new (getEndpoint(resource))()
        const res = await Promise.all(ids.map(id => endpoint.getOne(id)) as Promise<any>[])
        if (resource === "companies") return ({ data: res, validUntil: moment().add(10, "minutes").toDate() })
        return { data: res }
    },

    getManyReference: async (resource, { target, id, pagination, sort, filter }) => {
        return await DataProvider.getList(resource, { pagination, sort, filter: { ...filter, [target]: id } })
    },

    create: async (resource, { data }) => {
        const endpoint = new (getEndpoint(resource))()
        const model = (new (getEndpoint(resource).getModel())()).set(data)
        const res = await endpoint.create(model as Model<any, any>)
        return ({ data: res })
    },

    update: async (resource, { id, data, previousData }) => {
        const endpoint = new (getEndpoint(resource))()
        const res = await endpoint.update(id, previousData.set(data))
        return ({ data: res })
    },

    // updateMany: async () => {
    //     const Endpoint = getEndpoint(resource)
    //     const res = await Promise.all(ids.map(id => Endpoint.getOne(id)))
    //     return { data: res }
    // },

    updateMany: async () => Promise.reject("Method not supported"),

    delete: async (resource, { id, previousData }) => {
        const endpoint = new (getEndpoint(resource))()
        await endpoint.delete(id)
        return ({ data: previousData })
    },

    deleteMany: async (resource, { ids }) => {
        const endpoint = new (getEndpoint(resource))()
        const res = await Promise.all(ids.map(id => endpoint.delete(id)) as Promise<any>[])
        return { data: res }
    },

    // TODO: Remove ts-ignore
    createAction: async (resource, { id, action, data }) => {
        const endpoint = new (getEndpoint(resource))()
        // @ts-ignore
        const res = await endpoint[action](id, data)
        return { data: res }
    },

    // TODO: IT IS THE SAME AS createAction
    updateAction: async (resource, { id, action, data }) => {
        const endpoint = new (getEndpoint(resource))()
        // @ts-ignore
        const res = await endpoint[action](id, data)
        return { data: res }
    },

    deleteAction: async (resource, { id, action }) => {
        const endpoint = new (getEndpoint(resource))()
        // @ts-ignore
        const res = await endpoint[action](id)
        return { data: res }
    },

    getAction: async (resource, { id, action }) => {
        const endpoint = new (getEndpoint(resource))()
        // @ts-ignore
        const res = await endpoint[action](id)
        return { data: res }
    },
}

export default DataProvider
