import * as React from 'react';
import { Children, cloneElement, ReactElement } from 'react';
import CardContent from '@material-ui/core/CardContent';
import {
    EditControllerProps,
    useEditContext,
    Record,
    useResourceDefinition
} from 'ra-core';

import { EditActions as DefaultActions } from './EditActions';
import { CardView } from '../layout/Card';
import { EditProps } from './Edit';
import { MainTitle } from '../layout/MainTitle';

// TODO: Common utils
const formatTitle = (defaultTitle?: string, title?: string | ((record?: Record) => string | undefined), record?: Record): string => {
    if (record === undefined && typeof title === "function") {
        return defaultTitle || ""
    }

    if (typeof title === "function") {
        return title(record) || defaultTitle || ""
    }

    return title || defaultTitle || ""
}

export const EditView = (props: EditViewProps) => {
    const {
        actions,
        children,
        className,
        component: Content,
        title,
        subtitle,
        mainTitle,
        undoable,
        mutationMode,
        ...rest
    } = props;

    const {
        basePath,
        defaultTitle,
        hasList,
        hasShow,
        record,
        redirect,
        resource,
        save,
        saving,
        version
    } = useEditContext(props);

    const { options } = useResourceDefinition(props);

    const finalActions =
        actions === true || typeof actions === 'undefined' && hasShow ?
            (<DefaultActions />) : (actions);

    if (!children || !Content) {
        return null;
    }

    return (<>
        <MainTitle defaultTitle={defaultTitle} title={mainTitle || options?.label} />
        <Content
            title={formatTitle(defaultTitle, title, record)}
            subtitle={formatTitle("", subtitle, record)}
            actions={finalActions &&
                cloneElement(finalActions, {
                    basePath,
                    data: record,
                    hasShow,
                    hasList,
                    resource,
                    //  Ensure we don't override any user provided props
                    ...finalActions.props,
                })}
        >
            {record ? (
                cloneElement(Children.only(children), {
                    basePath,
                    record,
                    redirect:
                        typeof children.props.redirect === 'undefined'
                            ? redirect
                            : children.props.redirect,
                    resource,
                    save:
                        typeof children.props.save === 'undefined'
                            ? save
                            : children.props.save,
                    saving,
                    undoable,
                    mutationMode,
                    version,
                })
            ) : (
                <CardContent>&nbsp;</CardContent>
            )}
        </Content>
    </>);
};

interface EditViewProps
    extends EditProps,
    Omit<EditControllerProps, 'resource'> {
    children: ReactElement;
}

EditView.defaultProps = {
    component: CardView
}

