import { createContext, useState } from "react";
import { AdminContext } from 'react-admin';

import AuthProvider from "./providers/AuthProvider";
import DataProvider from "./providers/DataProvider";

import Admin from "./Admin";

import './App.css'
import ClickUpProvider from "./plugins/clickup/provider";
import mixerProvider, { Mixer } from "./providers/Mixer";


const mixer: Mixer = (resource) => {
  if (resource.startsWith("clickup/"))
    return ClickUpProvider as any
  else return DataProvider
};


const App = () => {

  return (
    <AdminContext dataProvider={mixerProvider(mixer, Object.keys(DataProvider))} authProvider={AuthProvider}>
      <Admin />
    </AdminContext>
  )
}

export default App