import React, {
    Fragment,
    ReactEventHandler,
    FC,
    ReactElement,
    SyntheticEvent,
} from 'react';
import {
    useTranslate,
    MutationMode,
    Record,
    RedirectionSideEffect,
    useDeleteWithConfirmController,
    OnSuccess,
    OnFailure,
    useResourceContext,
} from 'ra-core';

import { Confirm } from '../layout/Confirm';
import Button, { ButtonProps } from './Button';
import classnames from 'classnames';

export const DeleteWithConfirmButton: FC<DeleteWithConfirmButtonProps> = props => {
    const {
        basePath,
        className,
        confirmTitle = 'ra.message.delete_title',
        confirmContent = 'ra.message.delete_content',
        icon = "delete",
        label = 'ra.action.delete',
        mutationMode,
        onClick,
        record,
        redirect = 'list',
        onSuccess,
        onFailure,
        ...rest
    } = props;
    const resource = useResourceContext(props);
    const {
        open,
        loading,
        handleDialogOpen,
        handleDialogClose,
        handleDelete,
    } = useDeleteWithConfirmController({
        record,
        redirect,
        basePath,
        mutationMode,
        onClick,
        onSuccess,
        onFailure,
        resource,
    });

    return (
        <Fragment>
            <Button
                onClick={handleDialogOpen}
                label={label}
                loading={loading}
                icon={icon}
                className={classnames(className)}
                {...rest}
            />
            <Confirm
                isOpen={open}
                loading={loading}
                title={confirmTitle}
                content={confirmContent}
                onConfirm={handleDelete}
                onClose={handleDialogClose}
            />

        </Fragment>
    );
};

DeleteWithConfirmButton.defaultProps = {
    className: "text-lowercase"
}

interface Props {
    basePath?: string;
    classes?: object;
    className?: string;
    confirmTitle?: string;
    confirmContent?: string;
    icon?: ReactElement;
    label?: string;
    mutationMode?: MutationMode;
    onClick?: ReactEventHandler<any>;
    record?: Record;
    redirect?: RedirectionSideEffect;
    resource?: string;
    // May be injected by Toolbar - sanitized in Button
    handleSubmit?: (event?: SyntheticEvent<HTMLFormElement>) => Promise<Object>;
    handleSubmitWithRedirect?: (redirect?: RedirectionSideEffect) => void;
    invalid?: boolean;
    pristine?: boolean;
    saving?: boolean;
    submitOnEnter?: boolean;
    undoable?: boolean;
    onSuccess?: OnSuccess;
    onFailure?: OnFailure;
}

export type DeleteWithConfirmButtonProps = Props & ButtonProps;
