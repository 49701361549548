import classnames from "classnames"
import React from "react"

export function ButtonBase<T extends React.ElementType = React.ElementType>({ component, className, onClick, ...rest }: {
    component?: T
    onClick?: (event: React.MouseEvent) => void
    className?: string
    children?: React.ReactNode
}) {
    const RenderComponent = component || "span"
    return (<RenderComponent role="button" className={classnames(className, "btn-base")} onClick={onClick} {...rest} />)
}