import moment from "moment"
import { CreateParams, CreateResult, DataProvider, DeleteManyParams, DeleteManyResult, DeleteParams, DeleteResult, GetListParams, GetListResult, GetManyParams, GetManyReferenceParams, GetManyReferenceResult, GetManyResult, GetOneParams, GetOneResult, Record, UpdateManyParams, UpdateManyResult, UpdateParams, UpdateResult } from "ra-core"

import * as ClickUp from '@marekl/clickup'
import { TimeEntriesQueryParams } from "@marekl/clickup/dist/timeEntries"
import { APIConfig } from "@faktio/jsclient"

export interface DataProviderInterface<RecordType extends Record = Record> {
    getList: (resource: string, params: GetListParams) => Promise<GetListResult<RecordType>>;
    getOne: (resource: string, params: GetOneParams) => Promise<GetOneResult<RecordType>>;
    getMany: (resource: string, params: GetManyParams) => Promise<GetManyResult<RecordType>>;
    getManyReference: (resource: string, params: GetManyReferenceParams) => Promise<GetManyReferenceResult<RecordType>>;
    update: (resource: string, params: UpdateParams) => Promise<UpdateResult<RecordType>>;
    updateMany: (resource: string, params: UpdateManyParams) => Promise<UpdateManyResult>;
    create: (resource: string, params: CreateParams) => Promise<CreateResult<RecordType>>;
    delete: (resource: string, params: DeleteParams) => Promise<DeleteResult<RecordType>>;
    deleteMany: (resource: string, params: DeleteManyParams) => Promise<DeleteManyResult>;
    [key: string]: any;
};

const sortByField = <T extends any>(field: (keyof T), order?: string) => (a: T, b: T) =>
    order === "DESC" || order === "desc" ? (b?.[field] as any) - (a?.[field] as any) : (a?.[field] as any) - (b?.[field] as any)

const ClickUpProvider: DataProviderInterface<Record> = {
    getList: async (resource, { pagination, sort, filter }) => {
        // TODO: Add middleware
        ClickUp.Config.Host = APIConfig.baseURL + "/clickup/"
        ClickUp.Config.AxiosConfig.auth = APIConfig.auth

        const teamId = 4745831 /*filter?.teamId*/
        if (!teamId) return Promise.resolve({ data: [], total: 0 })

        switch (resource) {
            case "clickup/timesheet":
            case "clickup/timeEntries":
                const query: TimeEntriesQueryParams = {
                    space_id: filter?.spaceId && filter?.folderId ? undefined : filter?.spaceId,
                    folder_id: filter?.folderId && filter?.listId ? undefined : filter?.folderId,
                    list_id: filter?.listId
                }

                if (resource === "clickup/timesheet") {
                    let date = moment(filter?.start)
                    if (!date.isValid()) date = moment()

                    query.start = date.clone().startOf("week")
                    query.end = date.clone().endOf("week")
                } else {
                    query.start = filter?.start ? moment(filter.start) : undefined
                    query.end = filter?.end ? moment(filter.end) : undefined
                }

                let entries = await ClickUp.Team(teamId).getTimeEntries(query) as Record[]
                if (sort?.field && ["duration", "start"].includes(sort.field)) {
                    entries = entries.sort(sortByField(sort.field, sort.order))
                }
                return { data: entries as Record[], total: entries.length }

            case "clickup/spaces":
                let spaces = await ClickUp.Team(teamId).getSpaces() as Record[]
                // if (sort?.field && ["duration", "start"].includes(sort.field)) {
                //     spaces = spaces.sort(sortByField(sort.field, sort.order))
                // }
                return { data: spaces as Record[], total: spaces.length }

            case "clickup/folders":
                if (!filter.spaceId) return Promise.reject("Cannot get folders without a space")
                let folders = await ClickUp.Space(filter.spaceId).getFolders() as Record[]
                // if (sort?.field && ["duration", "start"].includes(sort.field)) {
                //     spaces = spaces.sort(sortByField(sort.field, sort.order))
                // }
                return { data: folders as Record[], total: folders.length }
            case "clickup/lists":
                if (!filter.folderId) return Promise.reject("Cannot get lists without a folder")
                let lists = await ClickUp.Folder(filter.folderId).getLists() as Record[]
                // if (sort?.field && ["duration", "start"].includes(sort.field)) {
                //     spaces = spaces.sort(sortByField(sort.field, sort.order))
                // }
                return { data: lists as Record[], total: lists.length }

        }
        return Promise.reject("Unknown endpoint")
    },

    getOne: async () => Promise.reject("Method not supported"),
    getMany: async () => Promise.reject("Method not supported"),
    getManyReference: async () => Promise.reject("Method not supported"),
    create: async () => Promise.reject("Method not supported"),
    update: async () => Promise.reject("Method not supported"),
    updateMany: async () => Promise.reject("Method not supported"),
    delete: async () => Promise.reject("Method not supported"),
    deleteMany: async () => Promise.reject("Method not supported"),
}

export default ClickUpProvider
