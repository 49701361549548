import * as React from 'react';
import { ReactElement } from 'react';
import {
    ListContextProvider,
    useCheckMinimumRequiredProps,
    useListController,
    Record
} from 'ra-core';

import { ListProps as ControllerListProps } from 'ra-core/esm/controller/useListController';
import { ListView } from './ListView';

/**
 * List page component
 *
 * The <List> component renders the list layout (title, buttons, filters, pagination),
 * and fetches the list of records from the REST API.
 * It then delegates the rendering of the list of records to its child component.
 * Usually, it's a <Datagrid>, responsible for displaying a table with one row for each post.
 *
 * In Redux terms, <List> is a connected component, and <Datagrid> is a dumb component.
 *
 * The <List> component accepts the following props:
 *
 * - actions
 * - aside
 * - bulkActionButtons
 * - component
 * - empty
 * - exporter
 * - filter (the permanent filter to apply to the query)
 * - filterDefaultValues (the default values for `alwaysOn` filters)
 * - filters (a React component used to display the filter form)
 * - pagination
 * - perPage
 * - sort
 * - title
 * - syncWithLocation
 *
 */
export const List = (
    props: ListProps & { children: ReactElement }
): ReactElement => {
    useCheckMinimumRequiredProps('List', ['children'], props);
    const controllerProps = useListController(props);
    return (
        <ListContextProvider value={controllerProps}>
            <ListView {...props} {...controllerProps} />
        </ListContextProvider>
    );
};

List.defaultProps = {
}

export interface ListProps extends ControllerListProps {
    actions?: JSX.Element | boolean;
    pagination?: JSX.Element | boolean;
    bulkActionButtons?: JSX.Element | boolean;
    className?: string;
    mainTitle?: string;
    title?: string
    subtitle?: string
}
