import * as React from 'react';
import {
    Children,
    isValidElement,
    FC,
    ReactElement,
} from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Nav from 'react-bootstrap/esm/Nav';

export const TabbedFormNav: FC<TabbedFormNavProps> = ({
    children,
    baseUrl = "",
}) => {
    const location = useLocation();

    const validTabPaths = Children.map(children, (tab, index) => {
        if (!isValidElement(tab)) return undefined;
        return getTabFullPath(tab, index, baseUrl);
    });

    return (
        <Nav variant="tabs" className="nav-bordered mb-0">
            {
                Children.map(children, (tab, index) => {
                    if (!isValidElement(tab)) return null;

                    const tabPath = getTabFullPath(tab, index, baseUrl);

                    return (
                        <Nav.Item>
                            <NavLink exact activeClassName="active" to={tabPath} className="nav-link">
                                {tab.props.label}
                            </NavLink>
                        </Nav.Item>
                    )
                })
            }
        </Nav>
    );
};

export const getTabFullPath = (
    tab: ReactElement,
    index: number,
    baseUrl: string
): string =>
    `${baseUrl}${tab.props.path ? `/${tab.props.path}` : index > 0 ? `/${index}` : ''
        }`.replace('//', '/'); // Because baseUrl can be a single / when on the first tab

export interface TabbedFormNavProps {
    baseUrl?: string,
}

