import React from "react";
import Dropdown from "react-bootstrap/esm/Dropdown";
import { ComponentProps } from "../tools/createComponent";
import { Icon } from "../utils/Icon";
import classNames from "classnames";
import { Link, LinkProps } from "react-router-dom";
import { LocationDescriptor } from "history"

export interface QuickAddButtonProps extends ComponentProps {
    // actions: [{ to: LocationDescriptor, title: JSX.Element | string }]
    children: QuickAddActionsType
}

export type QuickAddItemType = React.ReactElement<QuickAddItemProps>
export type QuickAddActionsType = (() => QuickAddItemType) | (() => QuickAddItemType[]) | QuickAddItemType | QuickAddItemType[]

export type QuickAddItemProps = Omit<LinkProps, "component">
export const QuickAddItem: React.FC<QuickAddItemProps> = (rest) => (
    <Link component={Dropdown.Item} {...rest} />
)

export const QuickAddButton: React.FC<QuickAddButtonProps> = ({ className, children, ...props }) => (
    <Dropdown className={classNames("dropdown-caret", className)} {...props}>
        <Dropdown.Toggle id="dropdown-basic" variant="quick-add" className="p-0">
            nová položka <Icon name={"add_circle_outline"} className="ml-2"></Icon>
        </Dropdown.Toggle>

        <Dropdown.Menu>
            {(typeof children === "function") ? children() : children}
        </Dropdown.Menu>
    </Dropdown>
)