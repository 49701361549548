import React, { useState } from "react";
import { useCreate, useNotify, FormWithRedirect, required, FormDataConsumer, Record } from 'react-admin';

import { useCompany } from "./companyUtils";
import { Person } from "@faktio/jsclient";
import { Title } from "../ui/utils/Inlines";
import { Row, Col, Modal } from "react-bootstrap";
import Button from "../ui/button/Button";
import { Toolbar } from "../ui/form/Toolbar";
import classNames from "classnames";
import { ARESAutocompleteField, ARESInput, VATInput } from "./Inputs";
import TextInput from "../ui/inputs/TextInput";
import BooleanInput from "../ui/inputs/BooleanInput";
import SelectInput from "../ui/inputs/SelectInput";

const PersonQuickCreateButton = ({ onChange, className, target }: { onChange: (p: Person) => void, target: string, className?: string }) => {
    const [showDialog, setShowDialog] = useState(false);
    const [create, { loading }] = useCreate('persons');
    const notify = useNotify();
    const { company } = useCompany()

    if (!company) return null

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSubmit = async (values: Partial<Record>) => {
        create(
            { payload: { data: values } },
            {
                onSuccess: ({ data }: { data: Person }) => {
                    setShowDialog(false);
                    onChange(data);
                },
                onFailure: ({ error }: { error: Error }) => {
                    notify(error.message, 'error');
                }
            }
        );
    };

    return (
        <>
            <Button onClick={handleClick} variant="outline-primary" label="přidat" size="sm" className={classNames("text-lowercase", className)} icon="add" />
            <Modal
                show={showDialog}
                onHide={handleCloseClick}
                tabindex="-1"
                role="dialog"
                aria-hidden={showDialog ? "false" : true}
                aria-labelledby="create-person-dialog-title"
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title as="h3" id="create-person-dialog-title">
                        Create person
                    </Modal.Title>
                </Modal.Header>
                <FormWithRedirect
                    resource="posts"
                    save={handleSubmit}
                    defaultValue={{ companyId: company.getID() }}
                    render={formProps => (
                        <>
                            <Modal.Body>
                                <Title as="h4">Základní informace</Title>
                                <ARESAutocompleteField source="contact.businessName" label="Obchodní název" validate={required()} />
                                <Row>
                                    <Col xs="5">
                                        <ARESInput source="contact.ICO" label="IČO" />
                                    </Col>
                                    <Col>
                                        <FormDataConsumer subscription={{ values: true }}>
                                            {({ formData, ...rest }) => formData?.contact?.VATPayer ?
                                                <VATInput source="contact.DIC" label="DIČ" {...rest} />
                                                : null
                                            }
                                        </FormDataConsumer>
                                    </Col>
                                    <Col xs="auto" className="d-flex align-items-center">
                                        <BooleanInput source="contact.VATPayer" label="Plátce DPH" formGroupClassName={"mb-0 mt-2"} />
                                    </Col>
                                </Row>

                                <Title as="h4" className="pt-3">Adresa</Title>
                                <TextInput source="contact.street" label="Ulice" variant="outlined" fullWidth />
                                <Row className="flex-nowrap">
                                    <Col xs="auto">
                                        <TextInput source="contact.postCode" label="PSČ" />
                                    </Col>
                                    <Col>
                                        <TextInput source="contact.city" label="Město" />
                                    </Col>
                                </Row>
                                <SelectInput source="contact.state" label="Stát" choices={[
                                    { id: 'Česká republika', name: 'Česká republika' },
                                ]} defaultValue={'Česká republika'} />

                                <Title as="h4" className="pt-3">Kontaktní údaje</Title>
                                <TextInput source="contact.email" label="Email" />
                                <TextInput source="contact.phone" label="Telefon" />

                            </Modal.Body>
                            <Modal.Footer>
                                <Toolbar {...formProps} record={formProps.record as Record} />
                            </Modal.Footer>
                        </>
                    )}
                />
            </Modal>
        </>
    );
}

export default PersonQuickCreateButton