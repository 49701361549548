
import * as React from 'react';
import {
    isValidElement,
    Children,
    cloneElement,
    useCallback,
    useRef,
    useEffect,
    FC,
    ReactElement,
    useMemo,
} from 'react';
import {
    sanitizeListRestProps,
    useListContext,
    useVersion,
    Identifier,
    Record,
    RecordMap,
    SortPayload,
} from 'ra-core';

import classnames from 'classnames';

import Table, { TableProps } from 'react-bootstrap/esm/Table';
import { TableCell, TableHead, TableRow } from '../../utils/Tables';
import Form from 'react-bootstrap/esm/Form';
import { difference, union } from 'lodash';
import DatagridContextProvider from './DatagridContextProvider';
import { RowClickFunction } from './DatagridRow';
import DatagridBody, { PureDatagridBody } from './DatagridBody';
import { useId } from 'react-id-generator';
import DatagridHeaderCell from './DatagridHeaderCell';
import { DatagridLoading } from './DatagridLoading';

/**
 * The Datagrid component renders a list of records as a table.
 * It is usually used as a child of the <List> and <ReferenceManyField> components.
 *
 * Props:
 *  - rowStyle
 *
 * @example Display all posts as a datagrid
 * const postRowStyle = (record, index) => ({
 *     backgroundColor: record.nb_views >= 500 ? '#efe' : 'white',
 * });
 * export const PostList = (props) => (
 *     <List {...props}>
 *         <Datagrid rowStyle={postRowStyle}>
 *             <TextField source="id" />
 *             <TextField source="title" />
 *             <TextField source="body" />
 *             <EditButton />
 *         </Datagrid>
 *     </List>
 * );
 *
 * @example Usage outside of a <List> or a <ReferenceManyField>.
 *
 * const currentSort = { field: 'published_at', order: 'DESC' };
 *
 * export const MyCustomList = (props) => {
 *     const { ids, data, total, loaded } = useGetList(
 *         'posts',
 *         { page: 1, perPage: 10 },
 *         currentSort
 *     );
 *
 *     return (
 *         <Datagrid
 *             basePath=""
 *             currentSort={currentSort}
 *             data={data}
 *             ids={ids}
 *             selectedIds={[]}
 *             loaded={loaded}
 *             total={total}
 *             setSort={() => {
 *                 console.log('set sort');
 *             }}
 *             onSelect={() => {
 *                 console.log('on select');
 *             }}
 *             onToggleItem={() => {
 *                 console.log('on toggle item');
 *             }}
 *         >
 *             <TextField source="id" />
 *             <TextField source="title" />
 *         </Datagrid>
 *     );
 * }
 */
const Datagrid: FC<DatagridProps> = React.forwardRef((props, ref: React.Ref<HTMLTableElement>) => {
    const {
        optimized = false,
        body = optimized ? <PureDatagridBody /> : <DatagridBody />,
        children,
        className,
        expand,
        hasBulkActions = false,
        hover,
        isRowSelectable,
        isRowExpandable,
        resource,
        rowClick,
        rowStyle,
        size,
        ...rest
    } = props;

    const {
        basePath,
        currentSort,
        data,
        ids,
        loaded,
        onSelect,
        onToggleItem,
        selectedIds,
        setSort,
        total,
    } = useListContext(props);
    const version = useVersion();

    const contextValue = useMemo(() => ({ isRowExpandable }), [
        isRowExpandable,
    ]);

    const updateSortCallback = useCallback(
        event => {
            event.stopPropagation();
            const newField = event.currentTarget.dataset.field;
            const newOrder =
                currentSort.field === newField
                    ? currentSort.order === 'ASC'
                        ? 'DESC'
                        : 'ASC'
                    : event.currentTarget.dataset.order;

            setSort(newField, newOrder);
        },
        [currentSort.field, currentSort.order, setSort]
    );

    const updateSort = (setSort as unknown) ? updateSortCallback : null;

    const handleSelectAll = useCallback(
        event => {
            if (event.target.checked) {
                const all = ids.concat(
                    selectedIds.filter(id => !ids.includes(id))
                );
                onSelect(
                    isRowSelectable
                        ? all.filter(id => isRowSelectable(data[id]))
                        : all
                );
            } else {
                onSelect([]);
            }
        },
        [data, ids, onSelect, isRowSelectable, selectedIds]
    );

    const lastSelected = useRef(null);

    useEffect(() => {
        if (!selectedIds || selectedIds.length === 0) {
            lastSelected.current = null;
        }
    }, [JSON.stringify(selectedIds)]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleToggleItem = useCallback(
        (id, event) => {
            const lastSelectedIndex = ids.indexOf(lastSelected.current || 0);
            lastSelected.current = event.target.checked ? id : null;

            if (event.shiftKey && lastSelectedIndex !== -1) {
                const index = ids.indexOf(id);
                const idsBetweenSelections = ids.slice(
                    Math.min(lastSelectedIndex, index),
                    Math.max(lastSelectedIndex, index) + 1
                );

                const newSelectedIds = event.target.checked
                    ? union(selectedIds, idsBetweenSelections)
                    : difference(selectedIds, idsBetweenSelections);

                onSelect(
                    isRowSelectable
                        ? newSelectedIds.filter((id: Identifier) =>
                            isRowSelectable(data[id])
                        )
                        : newSelectedIds
                );
            } else {
                onToggleItem(id);
            }
        },
        [data, ids, isRowSelectable, onSelect, onToggleItem, selectedIds]
    );

    const [selectAllId] = useId()

    /**
     * if loaded is false, the list displays for the first time, and the dataProvider hasn't answered yet
     * if loaded is true, the data for the list has at least been returned once by the dataProvider
     * if loaded is undefined, the Datagrid parent doesn't track loading state (e.g. ReferenceArrayField)
     */
    if (loaded === false) {
        return (
            <DatagridLoading
                className={className}
                // expand={expand}
                hasBulkActions={hasBulkActions}
                nbChildren={React.Children.count(children)}
                size={size}
            />
        );
    }

    /**
     * Once loaded, the data for the list may be empty. Instead of
     * displaying the table header with zero data rows,
     * the datagrid displays nothing in this case.
     */
    if (loaded && (ids.length === 0 || total === 0)) {
        return null;
    }

    const all = isRowSelectable
        ? ids.filter(id => isRowSelectable(data[id]))
        : ids;

    /**
     * After the initial load, if the data for the list isn't empty,
     * and even if the data is refreshing (e.g. after a filter change),
     * the datagrid displays the current data.
     */
    return (
        <DatagridContextProvider value={contextValue}>
            <Table
                ref={ref}
                className={classnames("table", className)}
                size={size}
                {...sanitizeListRestProps(rest)}
            >
                <TableHead>
                    <TableRow>
                        {expand && (
                            <th />
                        )}
                        {hasBulkActions && selectedIds && (
                            <th className="shrink">
                                <Form.Check
                                    custom
                                    type="checkbox"
                                    checked={
                                        selectedIds.length > 0 &&
                                        all.length > 0 &&
                                        all.every(id =>
                                            selectedIds.includes(id)
                                        )
                                    }
                                    onChange={handleSelectAll}
                                    id={selectAllId}
                                />
                            </th>
                        )}
                        {Children.map(children, (field, index) =>
                            isValidElement(field) ? (
                                <DatagridHeaderCell
                                    currentSort={currentSort}
                                    field={field}
                                    isSorting={
                                        currentSort.field ===
                                        ((field.props as any).sortBy ||
                                            (field.props as any).source)
                                    }
                                    key={(field.props as any).source || index}
                                    resource={resource || ""}
                                    updateSort={updateSort || ((event: any) => null)}
                                />
                            ) : null
                        )}
                    </TableRow>
                </TableHead>
                {cloneElement(
                    body,
                    {
                        basePath,
                        expand,
                        rowClick,
                        data,
                        hasBulkActions,
                        hover,
                        ids,
                        onToggleItem: handleToggleItem,
                        resource,
                        rowStyle,
                        selectedIds,
                        isRowSelectable,
                        version,
                    },
                    children
                )}
            </Table>
        </DatagridContextProvider>
    );
});

export interface DatagridProps<RecordType extends Record = Record>
    extends Omit<TableProps, 'size'> {
    body?: ReactElement;
    className?: string;
    expand?:
    | ReactElement
    | FC<{
        basePath: string;
        id: Identifier;
        record: Record;
        resource: string;
    }>;
    hasBulkActions?: boolean;
    hover?: boolean;
    isRowSelectable?: (record: Record) => boolean;
    isRowExpandable?: (record: Record) => boolean;
    optimized?: boolean;
    rowClick?: string | RowClickFunction;
    rowStyle?: (record: Record, index: number) => any;
    size?: 'sm';
    // can be injected when using the component without context
    basePath?: string;
    currentSort?: SortPayload;
    data?: RecordMap<RecordType>;
    ids?: Identifier[];
    loaded?: boolean;
    onSelect?: (ids: Identifier[]) => void;
    onToggleItem?: (id: Identifier) => void;
    setSort?: (sort: string, order?: string) => void;
    selectedIds?: Identifier[];
    total?: number;
    resource?: string;
}

Datagrid.displayName = 'Datagrid';

export default Datagrid;