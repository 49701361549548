import { API, User } from "@faktio/jsclient";
import { Login } from "react-admin";

interface DataProviderError {
    message: string, status: number, body: Object
}

export interface AuthProviderInterface {
    login: (data: { username: string, password: string }) => Promise<void>;
    checkError: (error: DataProviderError) => Promise<void>;
    // Check credentials before moving to a new route
    checkAuth: () => Promise<void>;
    // Returns route to redirect after logout
    logout: () => Promise<string | false | void>
    // Get the current user identity
    getIdentity: () => Promise<User>
    // Get the current user credentials
    getPermissions: (params: Object) => Promise<Object>,
}

const AuthProvider: AuthProviderInterface = {
    login: async ({ username, password }) => {
        API.LogIn.setCredentials(username, password)
        const api = new API.LogIn()
        const user = await api.login();
        localStorage.setItem('user', JSON.stringify(user.toJSON()));
        localStorage.setItem('privileges', JSON.stringify(user.isSysAdm() || user.getAllPrivileges()));
        localStorage.setItem('auth', JSON.stringify({ username, password }));
    },

    checkError: async (error?: DataProviderError) => {
        if (error?.status === 401) {
            localStorage.removeItem('user')
            localStorage.removeItem('privileges')
            localStorage.removeItem('auth')
            return Promise.reject()
        }
        return Promise.resolve()
    },

    checkAuth: () => {
        try {
            if (localStorage.getItem('auth')) {
                const { username, password } = JSON.parse(localStorage.getItem("auth") || "")
                API.LogIn.setCredentials(username, password)
                return Promise.resolve()
            } else return Promise.reject()
        } catch (error) {
            return Promise.reject(error);
        }
    },

    logout: async () => {
        localStorage.removeItem('user');
        localStorage.removeItem('auth');
    },

    getIdentity: () => {
        try {
            const user = User.fromJSON(JSON.parse(localStorage.getItem('user') || "{}"));
            if (!(user instanceof User) || user.getID() === null) throw new Error("No user found");
            return Promise.resolve(user);
        } catch (error) {
            return Promise.reject(error);
        }
    },

    getPermissions: () => {
        const privileges = JSON.parse(localStorage.getItem('privileges') || "[]") || [];
        return privileges ? Promise.resolve(privileges) : Promise.reject();
    }
}

export default AuthProvider