import * as React from 'react';
import { isValidElement, ReactElement, ReactNode } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslate, Record } from 'ra-core';
import classnames from 'classnames';

import Nav from 'react-bootstrap/esm/Nav';
import Card from 'react-bootstrap/esm/Card';
import Labeled from './Labeled';

export const Tab = ({
    basePath,
    children,
    contentClassName,
    contentComponent: ContentComponent = Card.Body,
    context,
    className,
    icon,
    label,
    record,
    resource,
    syncWithLocation = true,
    value,
    ...rest
}: TabProps) => {
    const translate = useTranslate();
    const location = useLocation();

    const renderHeader = () => (
        <Nav.Item key={label}>
            <NavLink exact activeClassName="active" to={{
                ...location,
                pathname: typeof value === "number" ? value.toString() : value
            }} className="nav-link">
                {icon ? icon : null}
                {translate(label, { _: label })}
            </NavLink>
        </Nav.Item>
    );

    const renderContent = () => (
        <ContentComponent className={contentClassName}>
            {React.Children.map(children, field =>
                field && isValidElement<any>(field) ? (
                    <div
                        key={field.props.source}
                        className={classnames(
                            `ra-field ra-field-${field.props.source}`,
                            'mb-3',
                            field.props.className
                        )}
                    >
                        {field.props.addLabel ? (
                            <Labeled
                                record={record}
                                resource={resource}
                                basePath={basePath}
                                label={field.props.label}
                                source={field.props.source}
                                disabled={false}
                            >
                                {field}
                            </Labeled>
                        ) : typeof field.type === 'string' ? (
                            field
                        ) : (
                            React.cloneElement(field, {
                                record,
                                resource,
                                basePath,
                            })
                        )}
                    </div>
                ) : null
            )}
        </ContentComponent>
    );

    return context === 'header' ? renderHeader() : renderContent();
};

export interface TabProps {
    basePath?: string;
    children: ReactNode;
    contentClassName?: string;
    contentComponent?: React.ComponentType<any>
    context?: 'header' | 'content';
    className?: string;
    icon?: ReactElement;
    label: string;
    path?: string;
    record?: Record;
    resource?: string;
    syncWithLocation?: boolean;
    value?: string | number;
}
