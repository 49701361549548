import { useTranslate } from 'ra-core';
import React from 'react';
import { InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/esm/Form';
import { FieldInputProps, FieldMetaState } from 'react-final-form'
import { useId } from 'react-id-generator';
import { BasicFormGroup, BasicFormGroupProps } from './BasicFormGroup';

export const BasicInput: React.FC<BasicInputProps> = (props) => {
    const {
        label,
        touched,
        error,
        submitError,
        name,
        prepend,
        append,
        formGroupClassName,
        ...rest
    } = props

    const translate = useTranslate();

    const isInvalid = !!(touched && (error || submitError))

    return (
        <BasicFormGroup {...props}>
            <InputGroup>
                {prepend ? (
                    typeof prepend === "string" ?
                        <InputGroup.Text>{translate(prepend)}</InputGroup.Text> :
                        <InputGroup.Prepend>
                            {prepend}
                        </InputGroup.Prepend>
                ) : null}
                <Form.Control
                    isInvalid={isInvalid}
                    name={name}
                    {...rest}
                />
                {append ? (
                    typeof append === "string" ?
                        <InputGroup.Text>{translate(append)}</InputGroup.Text> :
                        <InputGroup.Append>
                            {append}
                        </InputGroup.Append>
                ) : null}
            </InputGroup>
        </BasicFormGroup>
    );
};

export type BasicInputProps =
    FieldInputProps<any, HTMLElement> &
    Pick<FieldMetaState<any>, "error" | "submitError" | "touched"> &
    BasicFormGroupProps &
    {
        id?: string;
        label?: string;
        prepend?: string | React.ReactElement;
        append?: string | React.ReactElement;
    }
