import React from "react";
import { Person, ReceiptType } from "@faktio/jsclient";
import { FormDataConsumer, FormWithRedirect, FormWithRedirectProps, ListBase, Pagination, required, Record } from "react-admin";
import { Col, Row } from "react-bootstrap";
import { Create, CreateProps } from "../ui/detaill/Create";
import { Edit, EditProps } from "../ui/detaill/Edit";
import TextField from "../ui/field/TextField";
import { CardBody, CardFooter, CardView } from "../ui/layout/Card";
import Datagrid from "../ui/list/datagrid/Datagrid";
import { ListProps } from "../ui/list/List";
import { Title } from "../ui/utils/Inlines";
import { ListByCompany, withCompany, WithCompany } from "../utils/companyUtils";
import { ARESAutocompleteField, ARESInput, VATInput } from "../utils/Inputs";
import { ContactLineField } from "./Contacts";
import { ReceiptsDatagrid } from "./Receipts";
import TextInput from "../ui/inputs/TextInput";
import BooleanInput from "../ui/inputs/BooleanInput";
import SelectInput from "../ui/inputs/SelectInput";
import { Toolbar } from "../ui/form/Toolbar";

export const PersonsList = (props: ListProps) => (
    <ListByCompany {...props} title="Seznam kontaktů">
        <Datagrid size="sm" rowClick="show">
            <TextField source="contact.businessName" label="Obchodní název" />
            <ContactLineField label="Adresa" />
            <TextField source="contact.ICO" label="IČO" />
            <TextField source="contact.DIC" label="DIČ" />
        </Datagrid>
    </ListByCompany>
);

export const PersonsCreate = (props: CreateProps) => (
    <Create {...props} title="Nový kontakt">
        <PersonForm mutationMode="pessimistic" />
    </Create>
);

export const PersonsEdit = (props: EditProps) => (<>
    <Edit
        mutationMode="pessimistic"
        {...props}
        title="Detail kontaktu"
        subtitle={(record) => record && (record as Person).getContact().getBusinessName()}
    >
        <PersonForm />
    </Edit>
    <CardView title="Související doklady" className="mt-4">
        <ListBase
            resource={`receipts/${ReceiptType.SalesInvoice}`}
            basePath="/sales-invoices"
            filter={{ "to.personId": props.id, type: ReceiptType.SalesInvoice }}
            sort={{ field: 'number', order: 'DESC' }}
        >
            <CardBody className="p-0">
                <ReceiptsDatagrid hasBulkActions={false} size="sm" type={ReceiptType.SalesInvoice} />
            </CardBody>
            <CardFooter>
                <Pagination />
            </CardFooter>
        </ListBase>
    </CardView>
</>);


export const PersonForm = withCompany((
    { company, initialValues, ...props }:
        WithCompany<Omit<FormWithRedirectProps, "render">>
) => (
    <FormWithRedirect
        {...props}
        initialValues={{ ...initialValues, companyId: company.getID() }}
        redirect="edit"
        render={formProps => <>
            <CardBody>
                <Row className="justify-content-between">
                    <Col md={6} className="pb-3">
                        <Title as="h4">Základní informace</Title>
                        <ARESAutocompleteField source="contact.businessName" label="Obchodní název" validate={required()} />
                        <Row>
                            <Col>
                                <ARESInput source="contact.ICO" label="IČO" />
                            </Col>
                            <Col>
                                <FormDataConsumer subscription={{ values: true }}>
                                    {({ formData, ...rest }) => formData?.contact?.VATPayer ?
                                        <VATInput source="contact.DIC" label="DIČ" {...rest} />
                                        : null
                                    }
                                </FormDataConsumer>
                            </Col>
                            <Col xs="auto" className="d-flex align-items-center">
                                <BooleanInput source="contact.VATPayer" label="Plátce DPH" formGroupClassName={"mb-0 mt-2"} />
                            </Col>
                        </Row>

                        <Title as="h4" className="pt-3">Adresa</Title>
                        <TextInput source="contact.street" label="Ulice" />
                        <Row className="flex-nowrap">
                            <Col xs="auto">
                                <TextInput source="contact.postCode" label="PSČ" />
                            </Col>
                            <Col>
                                <TextInput source="contact.city" label="Město" />
                            </Col>
                        </Row>
                        <SelectInput source="contact.state" label="Stát" choices={[
                            { id: 'Česká republika', name: 'Česká republika' },
                        ]} defaultValue={'Česká republika'} />

                    </Col>
                    <Col md={5} lg={4}>
                        <Title as="h4">Kontaktní údaje</Title>
                        <TextInput source="contact.email" label="Email" />
                        <TextInput source="contact.phone" label="Telefon" />
                    </Col>
                </Row>

            </CardBody>
            <CardFooter>
                <Toolbar {...formProps} record={formProps.record as Record} />
            </CardFooter>
        </>}
    />
))
