import * as React from 'react';
import { FC, useState, ReactElement } from 'react';
import {
    useTranslate,
    useDeleteMany,
    useRefresh,
    useNotify,
    useUnselectAll,
    CRUD_DELETE_MANY,
    useResourceContext,
} from 'ra-core';

import Button, { ButtonProps } from './Button';
import { Confirm } from '../layout/Confirm';
import { BulkActionProps } from './BulkActionProps';

const BulkDeleteWithConfirmButton: FC<BulkDeleteWithConfirmButtonProps> = props => {
    const {
        basePath,
        confirmTitle = 'ra.message.bulk_delete_title',
        confirmContent = 'ra.message.bulk_delete_content',
        icon = "delete",
        label,
        onClick,
        selectedIds,
        ...rest
    } = props;
    const [isOpen, setOpen] = useState(false);

    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const refresh = useRefresh();
    const resource = useResourceContext(props);

    const [deleteMany, { loading }] = useDeleteMany(resource, selectedIds, {
        action: CRUD_DELETE_MANY,
        onSuccess: () => {
            refresh();
            notify('ra.notification.deleted', 'info', {
                smart_count: selectedIds.length,
            });
            unselectAll(resource);
        },
        onFailure: error => {
            notify(
                typeof error === 'string'
                    ? error
                    : error.message || 'ra.notification.http_error',
                'warning',
                {
                    _:
                        typeof error === 'string'
                            ? error
                            : error && error.message
                                ? error.message
                                : undefined,
                }
            );
            setOpen(false);
        },
    });

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        setOpen(true);
        e.stopPropagation();
    };

    const handleDialogClose = () => {
        setOpen(false);
    };

    const handleDelete = (e: React.MouseEvent<HTMLElement>) => {
        deleteMany();

        if (typeof onClick === 'function') {
            onClick(e);
        }
    };

    return (
        <>
            <Button
                onClick={handleClick}
                label={label}
                icon={icon}
                className={"text-lowercase"}
                {...sanitizeRestProps(rest)}
            />
            <Confirm
                isOpen={isOpen}
                loading={loading}
                title={confirmTitle}
                content={confirmContent}
                onConfirm={handleDelete}
                onClose={handleDialogClose}
            />
        </>
    );
};

const sanitizeRestProps = ({
    basePath,
    filterValues,
    label,
    ...rest
}: Omit<
    BulkDeleteWithConfirmButtonProps,
    'resource' | 'selectedIds' | 'icon'
>) => rest;

export interface BulkDeleteWithConfirmButtonProps
    extends BulkActionProps,
    ButtonProps {
    confirmContent?: string;
    confirmTitle?: string;
    icon?: ReactElement;
}

BulkDeleteWithConfirmButton.defaultProps = {
    label: 'ra.action.delete',
};

export default BulkDeleteWithConfirmButton;