import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { ReduxState } from 'ra-core';

export const Loader = () => {
    const loading = useSelector<ReduxState>(state => state.admin.loading > 0);

    return (
        <div className={classNames("loader", !!loading && "loading")}></div>
    )
}