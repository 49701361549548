import classNames from "classnames";
import React, { HTMLProps } from "react";

export interface ComponentProps<As extends React.ElementType = React.ElementType> {
    className?: string;
    id?: string;
    style?: React.CSSProperties,
    as?: As;
    children?: React.ReactNode
}

export function createComponent<T extends React.ElementType = React.ElementType>(Component: T, predefinedClassName?: string) {
    return ({ as, className, ...props }: React.PropsWithChildren<ComponentProps>) => {
        const RenderComponent = as || Component
        return (<RenderComponent className={classNames(predefinedClassName, className)} {...props} />)
    }
}

export function createComponentWithRef<T extends React.ElementType = React.ElementType>(Component: T, predefinedClassName?: string) {
    return React.forwardRef<T, React.PropsWithChildren<ComponentProps>>(({ as, className, ...props }, ref) => {
        const RenderComponent = as || Component
        return (<RenderComponent ref={ref} className={classNames(predefinedClassName, className)} {...props} />)
    })
}