import * as React from 'react';
import { FC } from 'react';
import { useTranslate, useListContext, useResourceContext } from 'ra-core';

import { Title } from '../utils/Inlines';
import { CreateButton } from '../button/CreateButton';

export const Empty: FC<EmptyProps> = props => {
    const { basePath, hasCreate } = useListContext(props);
    const resource = useResourceContext(props);
    const translate = useTranslate();

    const emptyMessage = translate('ra.page.empty', { name: resource });
    const inviteMessage = translate('ra.page.invite');

    return (
        <div className="py-2 pt-md-5 text-center">
            <Title className="h1">
                {translate(`resources.${resource}.empty`, {
                    _: emptyMessage,
                })}
            </Title>
            {hasCreate && (
                <p>
                    {translate(`resources.${resource}.invite`, {
                        _: inviteMessage,
                    })}
                </p>
            )}
            {
                hasCreate && (
                    <CreateButton variant="outline-primary" className="mt-3" basePath={basePath} />
                )
            }
        </div>
    );
};

export interface EmptyProps {
    resource?: string;
}

