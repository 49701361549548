import React, { useCallback, useContext, useEffect, useState } from "react"
import { AutocompleteInput, AutocompleteInputProps, InputProps, LinearProgress, useGetList, useInput, useNotify, useMutation } from "react-admin"
import ErrorIcon from '@material-ui/icons/Error';
import { useForm, useFormState, useField } from 'react-final-form'

import { AsyncTypeahead, Menu, MenuItem, Typeahead } from "react-bootstrap-typeahead"

// @ts-ignore
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { makeStyles } from '@material-ui/styles'
import PersonQuickCreateButton from "./PersonQuickCreateButton";
import { Person, Contact, API, ARESShortInterface, ReceiptType, NumericSeries } from "@faktio/jsclient";
import { CompanyContext } from '../Admin';
import Button, { ButtonProps } from "../ui/button/Button";
import { useId } from "react-id-generator";
import TextInput, { TextInputProps } from "../ui/inputs/TextInput";
import { BasicFormGroup } from "../ui/inputs/BasicFormGroup";
import { debounce } from "lodash";
import InputGroup from "react-bootstrap/esm/InputGroup";
import { Icon } from "../ui/utils/Icon";
import { CircularProgress } from "../ui/layout/CircularProgress";
import moment from "moment";
import { useCompany } from "./companyUtils";
import { BasicInput } from "../ui/inputs/BasicInput";

export const VATPayerInfoAdornment = () => {
    const { values } = useFormState({ subscription: { values: true } })
    const currentInput = values?.contact?.DIC

    const [currentValue, setCurrentValue] = useState("")

    const [mutate, { loading, data, error }] = useMutation();
    const checkVATPayer = useCallback((VAT) => mutate({
        type: 'getOne',
        resource: 'vatPayers',
        payload: { id: VAT }
    }), [mutate])

    const debouncedCheckVATPayer = useCallback(debounce(checkVATPayer, 1000), [])

    useEffect(() => {
        if (currentInput === currentValue) return;
        setCurrentValue(currentInput)
        debouncedCheckVATPayer(currentInput)
    }, [currentInput, currentValue, debouncedCheckVATPayer])

    const reliable = <InputGroup.Text className="text-success bg-light" >
        <span className="small font-weight-bold">DPH</span> <Icon name="check_circle_outline" className="ml-1" />
    </InputGroup.Text>

    const unreliable = <InputGroup.Text className="text-danger bg-light">
        <span className="small font-weight-bold">DPH</span> <Icon name="error_outline" className="ml-1" />
    </InputGroup.Text>

    const unknown = <InputGroup.Text className="bg-light">
        <span className="small font-weight-bold">DPH</span> <Icon name="help_outline" className="ml-1" />
    </InputGroup.Text>

    const loadingIndicator = <InputGroup.Text className="bg-light"><CircularProgress /></InputGroup.Text>

    if (loading)
        return loadingIndicator
    else if (!error && data?.unreliablePayer === false)
        return reliable
    else if (!error && data?.unreliablePayer === true)
        return unreliable
    else
        return unknown
}

export const VATInput = (props: TextInputProps) => (
    <TextInput {...props} append={<VATPayerInfoAdornment />} />
)

export const useARES = (): [
    (ico: string) => void,
    {
        data?: any;
        total?: number;
        error?: any;
        loading: boolean;
        loaded: boolean;
    }
] => {
    const notify = useNotify()
    const { change, batch } = useForm()

    const [mutate, state] = useMutation()

    const findICO = useCallback((ico: string) => mutate(
        {
            type: 'getOne',
            resource: 'aresICO',
            payload: { id: ico }
        }, {
        onSuccess: ({ data }: { data: Contact }) => {
            batch(() => {
                change("contact.businessName", data.getBusinessName())
                change("contact.VATPayer", data.isVATPayer())
                change("contact.ICO", data.getICO())
                change("contact.DIC", data.getDIC())
                change("contact.street", data.getStreet())
                change("contact.postCode", data.getPostCode())
                change("contact.city", data.getCity())
                change("contact.state", data.getState())
            })
            notify('Data byla načtena');
        },
        onFailure: (error) => notify(`Načtení dat z ARES nebylo úspěšné`, 'warning'),
    }),
        [mutate, batch, change, notify]
    )

    return [findICO, state]
}

export const useNextNumber = (): [
    (companyId: number, receiptType: ReceiptType, year: string) => void,
    {
        data?: any;
        error?: any;
        loading: boolean;
        loaded: boolean;
    }
] => {
    const notify = useNotify()
    const { change, batch } = useForm()

    const [mutate, state] = useMutation()

    const nextNumber = useCallback((companyId: number, receiptType: ReceiptType, year: string) => mutate(
        {
            type: 'getOne',
            resource: 'numericSeries',
            payload: { id: "next", query: { companyId, receiptType, year } }
        }, {
        onSuccess: ({ data }: { data: NumericSeries }) => {
            batch(() => {
                change("number", data.nextNumber)
                change("variable", data.nextVariable)
            })
        },
        onFailure: (error) => notify(`Načtení číselné řady nebylo úspěšné`, 'warning'),
    }),
        [mutate, batch, change, notify]
    )

    return [nextNumber, state]
}


export const ARESButtonICO: React.FC<Omit<ButtonProps, "onClick">> = (props) => {
    const {
        size = "sm",
        icon = "search",
        label = "ARES",
        variant = "outline-primary",
        disabled,
        ...rest
    } = props

    const { values } = useFormState({ subscription: { "values": true } })
    const [findICO, { loading }] = useARES()

    return (
        <Button
            label={label}
            size={size}
            icon={icon}
            disabled={loading || disabled || !(values?.contact?.ICO)}
            onClick={() => findICO(values?.contact?.ICO)}
            loading={loading}
            variant={variant}
            {...rest}
        />
    );
};

export const ARESInput = (props: TextInputProps) => (
    <TextInput {...props} append={<ARESButtonICO />} />
)

export const NewReceiptNumberInput = (props: TextInputProps) => {
    const [getNextNumber, { loading, error }] = useNextNumber()

    const { values } = useFormState({ subscription: { values: true } })
    const { company } = useCompany()

    const [currentState, setCurrentState] = useState<{
        receiptType: ReceiptType | null, companyId: number | null, year: string
    }>({ receiptType: null, companyId: null, year: "" })

    useEffect(() => {
        const newState = {
            receiptType: values.receiptType,
            companyId: company?.id || null,
            year: values.issueDate ? moment(values.issueDate).format("YYYY") : ""
        }

        if (currentState.receiptType !== newState.receiptType || currentState.companyId !== newState.companyId || currentState.year !== newState.year) {
            setCurrentState(newState)
            if (newState.receiptType && newState.companyId && newState.year) {
                getNextNumber(newState.companyId, newState.receiptType, newState.year)
            }
        }
    }, [values, company, currentState, setCurrentState, getNextNumber])

    // TODO: switch to loading input
    if (loading) {
        return (
            <LinearProgress />
        );
    }

    if (error) return (<div className="text-danger font-weight-bold mb-3">
        <ErrorIcon
            aria-errormessage={error.message ? error.message : error}
            role="presentation"
            color="error"
            fontSize="small"
            className="mr-3"
        /> Číselná řada nenalezena
    </div>)

    return (<TextInput {...props} />)
}

export const OwnContactAutofillInput: React.FC<Omit<AutocompleteInputProps, "choices">> = ({ source, children, ...rest }) => {
    const { company } = useContext(CompanyContext)
    if (!company) return null

    return (<AutocompleteInput
        {...rest}
        source={source}
        defaultValue={company?.getContact().getID()}
        choices={
            [{ id: company?.getContact().getID(), name: company?.getContact().getBusinessName() }]
        }
        disabled />)
}

export type PersonAutocompleteInputProps = Omit<AutocompleteInputProps, "choices"> & { companyId: number | null }
export const PersonAutocompleteInput: React.FC<PersonAutocompleteInputProps> = ({ children, companyId, ...rest }) => {
    const { loading, error, data } = useGetList("persons", { page: 1, perPage: -1 }, { field: "id", order: "ASC" }, { "companyId": companyId })

    if (companyId === null) return null

    const persons = data ? Object.values(data) as Person[] : undefined

    if (loading) return <LinearProgress />

    if (error) return (<ErrorIcon
        aria-errormessage={error.message ? error.message : error}
        role="presentation"
        color="error"
        fontSize="small"
    />)

    return <AutocompleteInput
        {...rest}
        choices={persons?.map(person => (
            {
                id: person.getContact().getID(),
                name: person.getContact().getBusinessName()
            }
        )) || []}
    />
}

const useFlexStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center'
    }
});

const PersonAutocompleteWithCreate = (props: PersonAutocompleteInputProps) => {
    const classes = useFlexStyles();
    const [version, setVersion] = useState(0);
    const updateVersion = useCallback(() => setVersion(version + 1), [version]);
    const form = useForm()
    const handleChange = (person: Person) => {
        form.change(props.source, person?.getContact().getID())
        updateVersion()
    }

    return (
        <div className={classes.root}>
            <div>
                <PersonAutocompleteInput key={version} {...props} />
            </div>
            <PersonQuickCreateButton target={props.source} onChange={handleChange} className="ml-3 mb-3" />
        </div>
    );
};

export default PersonAutocompleteWithCreate;

// export const CurrencyField: React.FC<TextFieldProps> = (props) => {
//     const {
//         input: { name, onChange, ...rest },
//         meta: { touched, error },
//         isRequired
//     } = useInput(props as InputProps<any>);

//     return (
//         <CurrencyTextField
//             name={name}
//             label={props.label}
//             variant="outlined"
//             currencySymbol="Kč"
//             decimalCharacter=","
//             digitGroupSeparator="."
//             decimalCharacterAlternative="."
//             outputFormat="string"
//             onChange={onChange}
//             {...rest}
//         />
//     );
// }

// TODO: Input cannot be changed from final-form - When you use ARES ICO Input, the business name does not change
export const ARESAutocompleteField = (props: InputProps) => {
    const {
        input,
        meta,
        isRequired
    } = useInput(props);

    const { name, onChange, value, id, ...rest } = input

    const { change } = useForm()

    const [mutate, { loading, data }] = useMutation();

    const search = useCallback((query) => mutate({
        type: 'getList',
        resource: 'aresName',
        payload: { search: query }
    }), [mutate])

    const [findICO] = useARES()

    const inputRef = React.createRef<Typeahead<ARESShortInterface>>();

    // For external updates we need to set the value manually
    // TODO: When the user edits this filed it goes back to the autocomplete value
    useEffect(() => {
        if (inputRef.current) {
            const input = inputRef.current.getInput()
            if (input.value != value) {
                input.value = value
            }
        }
    }, [value, inputRef])


    return (<BasicFormGroup {...props} {...input} {...meta}>
        <AsyncTypeahead<ARESShortInterface>
            ref={inputRef as unknown as React.RefObject<AsyncTypeahead<ARESShortInterface>>}
            id={id}
            delay={1000}
            minLength={5}
            isInvalid={meta.touched && meta.invalid}
            defaultInputValue={value}
            isLoading={loading}
            labelKey={(option: ARESShortInterface) => `${option.businessName}`}
            onSearch={search}
            onBlur={() => input.onBlur()}
            onFocus={() => input.onFocus()}
            onChange={(option) => {
                if (option && option[0] && option[0].ICO) {
                    change("contact.ICO", option[0].ICO)
                    findICO(option[0].ICO)
                }
            }}
            onInputChange={(value) => { onChange(value) }}
            options={data ? Object.values(data) : []}
            renderMenu={(results, menuProps) => (
                <Menu {...menuProps}>
                    {results.map((result, index) => (
                        <MenuItem option={result} position={index}>
                            <b>{result.businessName}</b><br />
                            <span className="small">
                                <b>IČO: </b>{result.ICO}<br />
                                {result.address}
                            </span>
                        </MenuItem>
                    ))}
                </Menu>
            )}
        />
    </BasicFormGroup>);
};