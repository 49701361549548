import * as React from 'react';
import { FC, memo, ElementType } from 'react';
import get from 'lodash/get';
import { useRecordContext } from 'ra-core';

import sanitizeFieldRestProps from './sanitizeFieldRestProps';
import { PublicFieldProps, InjectedFieldProps } from './types';
import { Text } from '../utils/Inlines';

const TextField: FC<TextFieldProps> = memo<TextFieldProps>(props => {
    const { className, source, emptyText, record, ...rest } = props;
    // @ts-ignore
    const value = source ? get(record, source) : null;
    console.log(record, source, value)

    return (
        <Text
            className={className}
            {...sanitizeFieldRestProps(rest)}
        >
            {value != null && typeof value !== 'string'
                ? JSON.stringify(value)
                : value || emptyText}
        </Text>
    );
});

TextField.displayName = 'TextField';

TextField.defaultProps = {
    addLabel: true,
};

export interface TextFieldProps
    extends PublicFieldProps,
    InjectedFieldProps { }

export default TextField;