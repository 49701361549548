import React, { cloneElement, ReactElement, useMemo } from 'react';
import { Exporter, Identifier, SortPayload, useListContext, useResourceContext, useResourceDefinition } from 'ra-core';
import { CreateButton } from '../button/CreateButton';
import ExportButton from '../button/ExportButton';

export const ListActions = (props: ListActionsProps) => {
    const { className, exporter, filters, ...rest } = props
    const {
        currentSort,
        displayedFilters,
        filterValues,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext(props);
    const resource = useResourceContext(rest);
    const { hasCreate } = useResourceDefinition(rest);

    return useMemo(
        () => (<>
            {filters &&
                cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
            {hasCreate && <CreateButton basePath={basePath} variant="primary" size="sm" />}
            {/* {exporter !== false && (
                <ExportButton
                    disabled={total === 0}
                    resource={resource}
                    sort={currentSort}
                    filterValues={filterValues}
                    size="sm"
                />
            )} */}
        </>),
        [resource, displayedFilters, filterValues, selectedIds, filters, total] // eslint-disable-line react-hooks/exhaustive-deps
    )
};

export interface ListActionsProps {
    currentSort?: SortPayload;
    className?: string;
    resource?: string;
    filters?: ReactElement<any>;
    displayedFilters?: any;
    exporter?: Exporter | boolean;
    filterValues?: any;
    permanentFilter?: any;
    hasCreate?: boolean;
    basePath?: string;
    selectedIds?: Identifier[];
    onUnselectItems?: () => void;
    showFilter?: (filterName: string, defaultValue: any) => void;
    total?: number;
}


