import * as React from 'react';
import { memo } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classnames from 'classnames';
import { useTimeout, Identifier, Record } from 'ra-core';

import Table from 'react-bootstrap/esm/Table';
import { TableBody, TableHead, TableRow } from '../../utils/Tables';
import Form from 'react-bootstrap/esm/Form';
import { Placeholder } from './Placeholder';

function times<T>(nbChildren: number, fn: (key: number) => T): T[] {
    return Array.from({ length: nbChildren }, (_, key) => fn(key))
}

const DatagridLoading: React.FC<DatagridLoadingProps> = ({
    className,
    // expand,
    hasBulkActions,
    nbChildren,
    nbFakeLines = 5,
    size,
}) => {
    const oneSecondHasPassed = useTimeout(1000);

    return oneSecondHasPassed ? (
        <Table className={classnames("table", className)} size={size}>
            <TableHead>
                <TableRow>
                    {/* {expand && (
                        <TableCell
                            padding="none"
                            className={classes.expandHeader}
                        />
                    )} */}
                    {hasBulkActions && (
                        <th className="shrink">
                            <Form.Check
                                custom
                                checked={false}
                            />
                        </th>
                    )}
                    {times(nbChildren, key => (
                        <th key={key}>
                            <Placeholder />
                        </th>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {times(nbFakeLines, key1 => (
                    <TableRow key={key1} style={{ opacity: 1 / (key1 + 1) }}>
                        {/* {expand && (
                            <TableCell
                                padding="none"
                                className={classes.expandIconCell}
                            >
                                <IconButton
                                    className={classes.expandIcon}
                                    component="div"
                                    aria-hidden="true"
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                            </TableCell>
                        )} */}
                        {hasBulkActions && (
                            <td>
                                <Form.Check
                                    custom
                                    checked={false}
                                />
                            </td>
                        )}
                        {times(nbChildren, key2 => (
                            <td key={key2}>
                                <Placeholder />
                            </td>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    ) : null;
};

export interface DatagridLoadingProps {
    className?: string;
    // expand?:
    //     | ReactElement
    //     | FC<{
    //           basePath: string;
    //           id: Identifier;
    //           record: Record;
    //           resource: string;
    //       }>;
    hasBulkActions?: boolean;
    nbChildren: number;
    nbFakeLines?: number;
    size?: 'sm';
}

const MemoDatagridLoading = memo(DatagridLoading)
export { MemoDatagridLoading as DatagridLoading }