import React, { Children } from 'react';
import classnames from 'classnames';
import { FormWithRedirectRenderProps, MutationMode, Record } from 'ra-core';
import { CardBody, CardFooter } from '../layout/Card';
import { FormInput } from 'react-admin';
import { Toolbar } from './Toolbar';

export const SimpleFormView: React.FC<SimpleFormViewProps> = ({
    basePath = "",
    children,
    className,
    component: Component,
    footerComponent: FooterComponent,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    mutationMode,
    pristine,
    record,
    redirect,
    resource,
    saving,
    submitOnEnter,
    toolbar,
    ...rest
}) => {

    if (!Component || !FooterComponent || !record || !resource) return null
    return (
        <form
            className={classnames('simple-form', className)}
            {...sanitizeRestProps(rest)}
        >
            <Component>
                {Children.map(
                    children,
                    (input) =>
                        React.isValidElement(input) && (
                            <FormInput
                                basePath={basePath}
                                input={input}
                                record={record}
                                resource={resource}
                                //TODO: REMOVE AFTER FORM INPUT REWRITE
                                variant={"outlined"}
                            />
                        )
                )}
            </Component>
            <FooterComponent>
                {toolbar &&
                    React.cloneElement(toolbar, {
                        basePath,
                        handleSubmitWithRedirect,
                        handleSubmit,
                        invalid,
                        mutationMode,
                        pristine,
                        record,
                        redirect,
                        resource,
                        saving,
                        submitOnEnter,
                    })}
            </FooterComponent>
        </form>
    )
}

SimpleFormView.defaultProps = {
    submitOnEnter: true,
    toolbar: <Toolbar />,
    component: CardBody,
    footerComponent: CardFooter
};

export interface SimpleFormViewProps extends FormWithRedirectRenderProps {
    basePath?: string;
    className?: string;
    component?: React.ComponentType<any>;
    footerComponent?: React.ComponentType<any>;
    mutationMode?: MutationMode;
    record?: Partial<Record>;
    resource?: string;
    toolbar?: React.ReactElement;
    children?: React.ReactNode;
    submitOnEnter?: boolean;
    __versions?: any; // react-final-form internal prop, missing in their type
}

const sanitizeRestProps = ({
    active,
    dirty,
    dirtyFields,
    dirtyFieldsSinceLastSubmit,
    dirtySinceLastSubmit,
    error,
    errors,
    form,
    hasSubmitErrors,
    hasValidationErrors,
    initialValues,
    modified = null,
    modifiedSinceLastSubmit,
    save = null,
    submitError,
    submitErrors,
    submitFailed,
    submitSucceeded,
    submitting,
    touched = null,
    valid,
    validating,
    values,
    visited = null,
    __versions = null,
    ...props
}: any) => props;