import { Resource } from "ra-core"
import { TimeEntriesList, TimeSheetList } from "./resource"

const ClickUpResources = [
    <Resource name="clickup/timeEntries" list={TimeEntriesList} options={{ label: 'Time entries', company: true, category: "clickUp" }} />,
    <Resource name="clickup/timesheet" list={TimeSheetList} options={{ label: 'Timesheet', company: true, category: "clickUp" }} />,
    <Resource name="clickup/spaces" />,
    <Resource name="clickup/folders" />,
    <Resource name="clickup/lists" />
]

export default ClickUpResources