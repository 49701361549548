import React, { cloneElement, Children, ReactElement, useMemo } from 'react';
import classnames from 'classnames';
import {
    ShowControllerProps,
    useResourceDefinition,
    useShowContext,
    Record
} from 'ra-core';

import { ShowActions as DefaultActions } from './ShowActions';
import { ShowProps } from './Show';
import { CardView } from '../layout/Card';
import { MainTitle } from '../layout/MainTitle';

const formatTitle = (defaultTitle?: string, title?: string | ((record?: Record) => string | undefined), record?: Record): string => {
    if (record === undefined && typeof title === "function") {
        return defaultTitle || ""
    }

    if (typeof title === "function") {
        return title(record) || defaultTitle || ""
    }

    return title || defaultTitle || ""
}

export const ShowView = (props: ShowViewProps) => {
    const {
        actions,
        children,
        className,
        component: Content,
        title,
        subtitle,
        mainTitle,
        ...rest
    } = props;

    const {
        basePath,
        defaultTitle,
        hasList,
        hasEdit,
        record,
        resource,
        version,
    } = useShowContext(props);

    const { options } = useResourceDefinition(props);

    const finalActions =
        actions === true || typeof actions === 'undefined' && hasEdit ? (
            <DefaultActions />
        ) : (
            actions
        );

    if (!children || !Content) {
        return null;
    }
    return (<>
        <MainTitle defaultTitle={defaultTitle} title={mainTitle || options?.label} />
        <Content
            title={formatTitle(defaultTitle, title, record)}
            subtitle={formatTitle("", subtitle, record)}
            actions={finalActions &&
                cloneElement(finalActions, {
                    basePath,
                    data: record,
                    hasList,
                    hasEdit,
                    resource,
                    //  Ensure we don't override any user provided props
                    ...finalActions.props,
                }) || undefined}
        >
            {record &&
                cloneElement(Children.only(children), {
                    resource,
                    basePath,
                    record,
                    version,
                })}
        </Content>
    </>);
};

ShowView.defaultProps = {
    component: CardView
}

export interface ShowViewProps
    extends ShowProps,
    Omit<ShowControllerProps, 'resource'> {
    children: ReactElement;
}
