import React from 'react';
import { FC, AnchorHTMLAttributes, memo } from 'react';
import { useRecordContext } from 'ra-core';
import { A } from '../utils/Inlines';
import { InjectedFieldProps, PublicFieldProps } from './types';
import { get } from 'lodash';

import { Text } from '../utils/Inlines'
import sanitizeFieldRestProps from './sanitizeFieldRestProps'

const EmailField: FC<EmailFieldProps> = memo<EmailFieldProps>(props => {
    const { className, emptyText, source, ...rest } = props;
    const record = useRecordContext(props);
    const value = source ? get(record, source) : null;

    if (value == null && emptyText) {
        return (
            <Text
                className={className}
                {...sanitizeFieldRestProps(rest)}
            >
                {emptyText}
            </Text>
        );
    }

    return (
        <A
            className={className}
            href={`mailto:${value}`}
            {...sanitizeFieldRestProps(rest)}
        >
            {value}
        </A>
    );
});

EmailField.defaultProps = {
    addLabel: true,
};

EmailField.displayName = 'EmailField';

export interface EmailFieldProps
    extends PublicFieldProps,
    InjectedFieldProps,
    AnchorHTMLAttributes<HTMLAnchorElement> { }

export default EmailField;