import * as React from 'react';
import { useCallback, FunctionComponent } from 'react';
import get from 'lodash/get';
import {
    useInput,
    useTranslate,
    ChoicesInputProps,
    useChoices,
    warning,
} from 'ra-core';

import { sanitizeInputRestProps } from './sanitizeInputRestProps';
import { LinearProgress } from 'react-admin';
import { BasicInput, BasicInputProps } from './BasicInput';
import { BasicFormGroupProps } from './BasicFormGroup';

const sanitizeRestProps = ({
    addLabel,
    afterSubmit,
    allowNull,
    beforeSubmit,
    choices,
    className,
    crudGetMatching,
    crudGetOne,
    data,
    filter,
    filterToQuery,
    formatOnBlur,
    isEqual,
    limitChoicesToValue,
    multiple,
    name,
    pagination,
    perPage,
    ref,
    reference,
    render,
    setFilter,
    setPagination,
    setSort,
    sort,
    subscription,
    type,
    validateFields,
    validation,
    value,
    ...rest
}: any) => sanitizeInputRestProps(rest);

/**
 * An Input component for a select box, using an array of objects for the options
 *
 * Pass possible options as an array of objects in the 'choices' attribute.
 *
 * By default, the options are built from:
 *  - the 'id' property as the option value,
 *  - the 'name' property as the option text
 * @example
 * const choices = [
 *    { id: 'M', name: 'Male' },
 *    { id: 'F', name: 'Female' },
 * ];
 * <SelectInput source="gender" choices={choices} />
 *
 * You can also customize the properties to use for the option name and value,
 * thanks to the 'optionText' and 'optionValue' attributes.
 * @example
 * const choices = [
 *    { _id: 123, full_name: 'Leo Tolstoi', sex: 'M' },
 *    { _id: 456, full_name: 'Jane Austen', sex: 'F' },
 * ];
 * <SelectInput source="author_id" choices={choices} optionText="full_name" optionValue="_id" />
 *
 * `optionText` also accepts a function, so you can shape the option text at will:
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 * ];
 * const optionRenderer = choice => `${choice.first_name} ${choice.last_name}`;
 * <SelectInput source="author_id" choices={choices} optionText={optionRenderer} />
 *
 * `optionText` also accepts a React Element, that will be cloned and receive
 * the related choice as the `record` prop. You can use Field components there.
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 * ];
 * const FullNameField = ({ record }) => <span>{record.first_name} {record.last_name}</span>;
 * <SelectInput source="gender" choices={choices} optionText={<FullNameField />}/>
 *
 * The choices are translated by default, so you can use translation identifiers as choices:
 * @example
 * const choices = [
 *    { id: 'M', name: 'myroot.gender.male' },
 *    { id: 'F', name: 'myroot.gender.female' },
 * ];
 *
 * However, in some cases (e.g. inside a `<ReferenceInput>`), you may not want
 * the choice to be translated. In that case, set the `translateChoice` prop to false.
 * @example
 * <SelectInput source="gender" choices={choices} translateChoice={false}/>
 *
 * The object passed as `options` props is passed to the material-ui <Select> component
 *
 * You can disable some choices by providing a `disableValue` field which name is `disabled` by default
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 *    { id: 976, first_name: 'William', last_name: 'Rinkerd', disabled: true },
 * ];
 *
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 *    { id: 976, first_name: 'William', last_name: 'Rinkerd', not_available: true },
 * ];
 * <SelectInput source="gender" choices={choices} disableValue="not_available" />
 *
 */

//FIXME: Does not work... labels do not show and, if used in ReferenceInput, it duplicates the selected records
const SelectInput: FunctionComponent<SelectInputProps> = props => {
    const {
        allowEmpty,
        choices = [],
        disableValue,
        emptyText,
        emptyValue,
        format,
        helperText,
        loaded,
        loading,
        onBlur,
        onChange,
        onFocus,
        optionText,
        optionValue,
        parse,
        resource,
        source,
        translateChoice,
        validate,
        ...rest
    } = props;

    warning(
        source === undefined,
        `If you're not wrapping the SelectInput inside a ReferenceInput, you must provide the source prop`
    );

    warning(
        choices === undefined,
        `If you're not wrapping the SelectInput inside a ReferenceInput, you must provide the choices prop`
    );

    const translate = useTranslate()

    const { getChoiceText, getChoiceValue } = useChoices({
        optionText,
        optionValue,
        translateChoice,
    });

    const { id, input, isRequired, meta } = useInput({
        format,
        onBlur,
        onChange,
        onFocus,
        parse,
        resource,
        source: source || "",
        validate,
        ...rest,
    });

    const { touched, error, submitError } = meta;

    const renderMenuItemOption = useCallback(choice => getChoiceText(choice), [
        getChoiceText,
    ]);

    if (loading) {
        return (
            <BasicInput
                id={id}
                as={LinearProgress}
                loading={loading}
                {...input}
            />
        );
    }

    return (
        <BasicInput
            id={id}
            {...input}
            as="select"
            custom
            clearAlwaysVisible
            error={!!(touched && (error || submitError))}
            helperText={helperText}
            {...sanitizeRestProps(rest)}
        >
            {allowEmpty ? (
                <option
                    value={emptyValue}
                    key="null"
                >
                    {emptyText ? translate(emptyText, { _: emptyText }) : ""}
                </option>
            ) : null}
            {choices.map(choice => (
                <option
                    key={getChoiceValue(choice)}
                    value={getChoiceValue(choice)}
                    disabled={get(choice, disableValue)}
                >
                    {renderMenuItemOption(choice)}
                </option>
            ))}
        </BasicInput>
    );
};

SelectInput.defaultProps = {
    emptyText: '',
    emptyValue: '',
    options: {},
    optionText: 'name',
    optionValue: 'id',
    translateChoice: true,
    disableValue: 'disabled',
};

export type SelectInputProps = ChoicesInputProps<BasicInputProps> &
    Omit<BasicInputProps, 'label' | 'helperText'> &
    Partial<BasicFormGroupProps> & {
        emptyText?: string;
    }

export default SelectInput;