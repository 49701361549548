import * as React from 'react';
import { ReactElement } from 'react';
import {
    EditContextProvider,
    ResourceContextProvider,
    useCheckMinimumRequiredProps,
    useEditController,
} from 'ra-core';
import { EditProps as ControllerEditProps } from 'ra-core/esm/controller/details/useEditController';
import { ShowProps } from './Show';
import { EditView } from './EditView';

/**
 * Page component for the Edit view
 *
 * The `<Edit>` component renders the page title and actions,
 * fetches the record from the data provider.
 * It is not responsible for rendering the actual form -
 * that's the job of its child component (usually `<SimpleForm>`),
 * to which it passes the `record` as prop.
 *
 * The <Edit> component accepts the following props:
 *
 * - actions
 * - aside
 * - component
 * - successMessage //TODO: How?
 * - title
 * - mutationMode
 * - undoable (deprecated)
 *
 */
export const Edit = (
    props: EditProps & { children: ReactElement }
): ReactElement => {
    useCheckMinimumRequiredProps('Edit', ['children'], props);
    const controllerProps = useEditController(props);
    const body = (
        <EditContextProvider value={controllerProps}>
            <EditView {...props} {...controllerProps} />
        </EditContextProvider>
    );
    return props.resource ? (
        // support resource override via props
        <ResourceContextProvider value={props.resource}>
            {body}
        </ResourceContextProvider>
    ) : (
        body
    );
};

export interface EditProps extends ControllerEditProps, ShowProps { }

