import React, { ReactElement } from "react";
import { ReferenceInput, required, SelectInput, SimpleForm } from "react-admin";
import { PermissionsEditDatagrid, PermissionsListDatagrid } from "./Permissions";

import { UndoableDeleteToolbar } from "../utils/Wrappers";
import { CompanyField, LabeledCompanyField } from "../utils/Fields";
import { List, ListProps } from "../ui/list/List";
import { Create, CreateProps } from "../ui/detaill/Create";
import { Edit, EditProps } from "../ui/detaill/Edit";
import Datagrid from "../ui/list/datagrid/Datagrid";
import TextField from "../ui/field/TextField";
import { Show, ShowProps } from "../ui/detaill/Show";
import { TabbedShowLayout } from "../ui/detaill/TabbedShowLayout";
import { TabbedForm } from "../ui/form/TabbedForm";
import { FormTab } from "../ui/form/FormTab";
import TextInput from "../ui/inputs/TextInput";
import { Tab } from "../ui/detaill/Tab";

export const RolesList = (props: ListProps) => (
    <List {...props} title="Role" sort={{ field: "name", order: "DESC" }}>
        <Datagrid rowClick="show" size="sm">
            <TextField source="name" label="Název" />
        </Datagrid>
    </List>
);

export const RolesShow = (props: ShowProps) => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="Obecné">
                <TextField source="name" label="Název" />
                <LabeledCompanyField source="companyId" label="Společnost" />
            </Tab>
            <Tab label="Oprávnění" contentComponent={React.Fragment}>
                <PermissionsListDatagrid />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export const RolesCreate = (props: CreateProps) => (
    <Create {...props}>
        <SimpleForm mutationMode="pessimistic" redirect="show" variant="outlined">
            <TextInput source="name" label="Název" validate={required()} />
            <ReferenceInput source="companyId" reference="companies">
                <SelectInput optionText="contact.businessName" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);

export const RolesEdit = (props: EditProps) => (
    <Edit {...props} mutationMode="pessimistic">
        <TabbedForm toolbar={<UndoableDeleteToolbar />} redirect="show" variant="outlined">
            <FormTab label="General">
                <TextInput source="name" label="Název" validate={required()} />
                <ReferenceInput source="companyId" reference="companies">
                    <SelectInput optionText="contact.businessName" />
                </ReferenceInput>
            </FormTab>
            <FormTab label="Permissions" path="permissions">
                <PermissionsEditDatagrid />
            </FormTab>
        </TabbedForm>
    </Edit>
);