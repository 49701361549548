import React, { ReactElement } from "react"
import classNames from "classnames"
import { MouseEventHandler } from "react"
import { ComponentProps, createComponent } from "./tools/createComponent"
import { Icon, IconType } from "./utils/Icon"
import { LocationDescriptor, Location } from "history"
import { Link } from "react-router-dom"
import { Span } from "./utils/Inlines"

export interface MenuItemProps extends ComponentProps {
    onClick?: () => void
    to?: LocationDescriptor | ((location: Location) => LocationDescriptor)
    icon?: IconType | JSX.Element | ReactElement;
}

export class MenuItem extends React.Component<MenuItemProps> {
    render() {
        const { onClick, icon, className, children, to } = this.props

        const handleClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
            e.preventDefault()
            onClick && onClick()
        }

        const classes = classNames("menu-item", className)
        const itemIcon = typeof icon === "string" ? <Icon name={icon} className="mr-3" /> : (
            icon ? React.cloneElement(icon, { "className": "mr-3" }) : <Span className="mr-3 material-icons icon" />
        )

        if (to !== undefined) {
            return <Link to={to} className={classes} onClick={() => onClick && onClick()}>{itemIcon}{children}</Link>
        }

        if (typeof onClick === "function") {
            return <a href="#" className={classes} onClick={handleClick}>{itemIcon}{children}</a>
        }

        return (
            <div className={classes}>{itemIcon}{children}</div>
        )
    }

    static Title = createComponent("div", "menu-item menu-item--title")
}
