import classnames from 'classnames';
import { Record } from 'ra-core';
import React from 'react';

// TODO: td to TableCell
const DatagridCell = React.forwardRef<HTMLTableCellElement, DatagridCellProps>(
    ({ className, field, record, basePath, resource, ...rest }, ref) => (
        <td
            className={classnames(
                className,
                field?.props.cellClassName,
                field?.props.extend && "extend",
                field?.props.shrink && "shrink"
            )}
            ref={ref}
            {...rest}
        >
            {field && React.cloneElement(field, {
                record,
                basePath: field.props.basePath || basePath,
                resource,
            })}
        </td>
    )
);

export interface DatagridCellProps extends React.HTMLProps<HTMLTableCellElement> {
    basePath?: string;
    className?: string;
    field?: JSX.Element;
    record?: Record;
    resource?: string;
}

// What? TypeScript loses the displayName if we don't set it explicitly
DatagridCell.displayName = 'DatagridCell';

export default DatagridCell;