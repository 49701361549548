import React from 'react';
import { FC, cloneElement, ReactElement } from 'react';
import { createPortal } from 'react-dom';
import { useTranslate, Record, warning } from 'ra-core';

export interface MainTitleProps {
    className?: string;
    defaultTitle?: string;
    record?: Record;
    title?: string | ReactElement;
}

export const MainTitle: FC<MainTitleProps> = ({
    className,
    defaultTitle,
    record,
    title,
    ...rest
}) => {
    const translate = useTranslate();
    const container =
        typeof document !== 'undefined'
            ? document.getElementById('react-admin-title')
            : null;
    if (!container) return null;
    warning(!defaultTitle && !title, 'Missing title prop in <MainTitle> element');

    const titleElement = !title ? (
        <span className={className} {...rest}>
            {defaultTitle}
        </span>
    ) : typeof title === 'string' ? (
        <span className={className} {...rest}>
            {translate(title, { _: title })}
        </span>
    ) : (
        cloneElement(title, { className, record, ...rest })
    );
    return createPortal(titleElement, container);
};
