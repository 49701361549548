import { TimeEntryInterface } from "@marekl/clickup/dist/timeEntries";
import moment from "moment";
import { ReactNode, useEffect, useState } from "react";
import { BulkActionProps, FunctionField, ReferenceInput, useListContext } from "react-admin";
import Table from "react-bootstrap/esm/Table";
import { TextFieldProps } from "../../ui/field/TextField";
import SelectInput from "../../ui/inputs/SelectInput";
import Datagrid from "../../ui/list/datagrid/Datagrid";
import Filter from "../../ui/list/filter/Filter";
import { List, ListProps } from "../../ui/list/List";
import { Icon } from "../../ui/utils/Icon";
import { Span } from "../../ui/utils/Inlines";
import { DateInput } from "../../utils/DateTimePickers";

const DateMSField: React.FC<TextFieldProps> = ({ record, source, emptyText }) => {
    const value = source ? record?.[source] : null
    const date = moment.isMoment(value) ? value : moment(value, "x")

    if (!moment.isMoment(date) || !date.isValid()) {
        return <Span>{emptyText}</Span>
    }

    return <Span>{date.format("DD. MM. YYYY")}</Span>
}

const formatDuration = (duration: moment.Duration, zero?: string | ReactNode) => {
    if (zero !== undefined && duration.asSeconds() === 0) {
        return zero
    }

    const minutes = duration.minutes()
    const hours = duration.hours() + duration.days() * 24

    return `${hours.toString().padStart(1, "0")}:${minutes.toString().padStart(2, "0")}`
}

const DurationMSField: React.FC<TextFieldProps> = ({ record, source, emptyText }) => {
    const value = source ? record?.[source] : null
    const duration = moment.isDuration(value) ? value : moment.duration(value)

    if (!moment.isDuration(duration) || !duration.isValid()) {
        return <Span>{emptyText}</Span>
    }

    return <Span>{formatDuration(duration)}</Span>
}

const truncate = (str: string, length: number) => {
    var dots = str.length > length ? '...' : '';
    return str.substring(0, length) + dots;
}

const TimeEntriesFilters = ({ range = false, lists = false, ...props }: any & { range?: boolean, lists?: boolean }) => {
    const {
        setFilters,
        filterValues,
        displayedFilters
    } = useListContext<TimeEntryInterface>(props);


    useEffect(() => setFilters({ ...filterValues, folderId: undefined, listId: undefined }, displayedFilters), [filterValues?.spaceId])
    useEffect(() => setFilters({ ...filterValues, listId: undefined }, displayedFilters), [filterValues?.folderId])

    return <Filter {...props}>
        <DateInput label={range ? "Od" : "Datum"} alwaysOn source="start" options={{ format: "DD.MM.YYYY", autoOk: true, inputVariant: "outlined", placeholder: "DD.MM.YYYY" }} />

        {range ?
            <DateInput label="Do" alwaysOn source="end" options={{ format: "DD.MM.YYYY", autoOk: true, inputVariant: "outlined", placeholder: "DD.MM.YYYY" }} />
            : null}

        <ReferenceInput label="Space" allowEmpty alwaysOn source="spaceId" reference="clickup/spaces">
            <SelectInput optionText="name" />
        </ReferenceInput>
        {filterValues?.spaceId ?
            <ReferenceInput label="Folder" source="folderId" allowEmpty alwaysOn filter={{ spaceId: filterValues.spaceId }} reference="clickup/folders">
                <SelectInput optionText="name" />
            </ReferenceInput>
            : null
        }
        {lists && filterValues?.folderId ?
            <ReferenceInput label="List" source="listId" allowEmpty alwaysOn filter={{ folderId: filterValues.folderId }} reference="clickup/lists">
                <SelectInput optionText="name" />
            </ReferenceInput>
            : null
        }
    </Filter>
}

export const TimeEntriesSum = (props: BulkActionProps) => {
    const {
        data,
        selectedIds,
    } = useListContext<TimeEntryInterface>(props);

    const entries = Object.values(data).filter(entry => selectedIds.includes(entry.id))
    const total = entries.reduce((total, entry) => total.add(moment.duration(entry.duration)), moment.duration())

    return <Span className="font-weight-bold">Total: {formatDuration(total)}</Span>
}

export const TimeEntriesListFooter = (props: any) => {
    const {
        data,
        selectedIds,
    } = useListContext<TimeEntryInterface>(props);

    const total = Object.values(data).reduce((total, entry) => total.add(moment.duration(entry.duration)), moment.duration())

    return <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Span className="font-weight-bold">Total</Span>
        <Span className="font-weight-bold">{formatDuration(total)}</Span>
    </div>
}

export const TimeEntriesList = (props: ListProps) => (
    <List {...props}
        pagination={false}
        title="Time entries"
        mainTitle="ClickUp"
        hasCreate={false}
        hasEdit={false}
        hasShow={false}
        bulkActionButtons={<TimeEntriesSum />}
        filters={<TimeEntriesFilters range lists />}
        filterDefaultValues={{
            start: moment().startOf("month").toDate(),
            end: moment().endOf("month").toDate()
        }}
        sort={{ field: "start", order: "DESC" }}
        footer={<TimeEntriesListFooter />}
    >
        <Datagrid size="sm">
            <DateMSField source="start" label="Datum" shrink />
            <FunctionField<TimeEntryInterface> label="Description" render={(record?: TimeEntryInterface) => {
                const name = record?.task?.name || (<i>No name</i>)

                const location = [record?.task_location?.space_name, record?.task_location?.folder_name, record?.task_location?.list_name].
                    filter(str => str !== undefined && str !== "hidden")
                    .join(" > ")

                return (<div style={{ lineHeight: 1.15 }}>
                    {location ? <><span className="small text-dark">{location}</span><br /></> : null}
                    <a href={record?.task_url} title="Detail" target="_blank">{name}</a>
                </div>)
            }
            } />
            <DurationMSField source="duration" label="Délka" shrink cellClassName="text-right" />
        </Datagrid>
    </List>
);

type ClickUpSpaces = {
    [id: number]: ClickUpSpace
}

type ClickUpSpace = {
    name: string,
    folders: {
        [id: number]: ClickUpFolder
    }
}

type ClickUpFolder = {
    name: string,
    lists: {
        [id: number]: ClickUpList
    }
}

type ClickUpList = {
    name: string,
    tasks: {
        [id: string]: ClickUpTask
    }
}

type ClickUpTask = {
    name: string,
    timeEntries: {
        start: moment.Moment,
        duration: moment.Duration
    }[]
}

const Timesheet: React.FC<any> = (props) => {
    const {
        data,
        ids,
        filterValues
    } = useListContext<TimeEntryInterface>(props);

    const spaces: ClickUpSpaces = {}

    ids.map(id => data[id]).forEach(entry => {
        const { id } = entry
        const { space_id = 0, space_name, folder_id = 0, folder_name, list_id = 0, list_name } = entry.task_location || {}
        const { id: task_id = 0, name: task_name } = entry.task || {}
        const space = spaces?.[space_id] || { name: space_name, folders: {} }
        const folder = space?.folders[folder_id] || { name: folder_name === "hidden" ? "" : folder_name, lists: {} }
        const list = folder?.lists[list_id] || { name: list_name, tasks: {} }
        const task = list?.tasks[task_id] || { name: task_name, timeEntries: {} }

        spaces[space_id] = {
            ...space, folders: {
                ...space.folders,
                [folder_id]: {
                    ...folder, lists: {
                        ...folder.lists,
                        [list_id]: {
                            ...list,
                            tasks: {
                                ...list.tasks,
                                [task_id]: {
                                    ...task,
                                    timeEntries: {
                                        ...task.timeEntries,
                                        [id]: {
                                            start: moment(entry.start, "x"),
                                            duration: moment.duration(entry.duration)
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    })

    const [extended, setExtended] = useState(false)

    const start = (moment(filterValues?.start) || moment()).startOf("week")
    const dates = Array.from(Array(7).keys()).map(i => start.clone().add(i, "days").format("dd DD.MM."))
    const moments = Array.from(Array(7).keys()).map(i => start.clone().add(i, "days"))

    const allEntries = ids.map(id => data[id]).map(entry => ({
        duration: moment.duration(entry.duration),
        start: moment(entry.start, "x"),
        listId: entry.task_location?.list_id || "0"
    }))

    return (
        <Table size={"sm"}>
            <thead>
                <th className={extended ? "shrink" : "extend"}>Folder <span
                    style={{ cursor: "pointer", display: "inline-flex", verticalAlign: "middle", userSelect: "none" }}
                    onClick={() => {
                        setExtended(!extended)
                    }}>
                    {extended ? <Icon name="remove_circle_outline" /> : <Icon name="add_circle_outline" />}
                </span></th>
                {extended ? <th className="extend">List</th> : null}
                {dates.map(date => <th className="text-center shrink">{date}</th>)}
                <th className="text-center shrink">Total</th>
            </thead>
            <tbody>
                {Object.values(spaces).map((space, spacei) =>
                    Object.values(space.folders).map((folder, folderi) =>
                        Object.values(folder.lists).map((list, listi) => {
                            const tasks = extended ?
                                Object.values(list.tasks) :
                                Object.values(folder.lists).reduce(
                                    (tasks, list) => [...tasks, ...Object.values(list.tasks)],
                                    [] as ClickUpTask[]
                                )

                            const entries = tasks.reduce((all, task) =>
                                all.concat(Object.values(task.timeEntries)),
                                [] as { start: moment.Moment, duration: moment.Duration }[])

                            return <tr>
                                {listi == 0 ?
                                    <td className={extended ? "shrink" : "extend"} rowSpan={Object.keys(folder.lists).length} style={{ lineHeight: 1.15 }}>
                                        {space.name ? <><span className="small text-dark">{space.name}</span><br /></> : null}
                                        {folder.name || "Nezařazené"}
                                    </td> : null
                                }
                                {extended ?
                                    <td className={listi == 0 ? "pl-4 extend" : "extend"}>
                                        {list.name}
                                    </td> : null}

                                {(extended || listi == 0) ? <>
                                    {moments.map(d =>
                                        <td className="text-center shrink">
                                            {formatDuration(
                                                entries
                                                    .filter(({ start }) => d.isSame(start, "day"))
                                                    .reduce((total, { duration }) => total.add(duration), moment.duration()),
                                                "-"
                                            )}
                                        </td>
                                    )}
                                    <td className="text-center font-weight-bold shrink">
                                        {formatDuration(
                                            entries.reduce((total, { duration }) => total.add(duration), moment.duration()),
                                            "-"
                                        )}
                                    </td>
                                </> : null}
                            </tr>
                        })
                    )
                )}
            </tbody>
            <tfoot>
                <th className="border-top extend" colSpan={extended ? 2 : 1}>Total</th>
                {moments.map(d =>
                    <th className="border-top text-center shrink">
                        {formatDuration(
                            allEntries
                                .filter(({ start }) => d.isSame(start, "day"))
                                .reduce((total, { duration }) => total.add(duration), moment.duration()),
                            "-"
                        )}
                    </th>
                )}
                <th className="border-top text-center shrink">
                    {formatDuration(
                        allEntries.reduce((total, { duration }) => total.add(duration), moment.duration()),
                        "-"
                    )}
                </th>
            </tfoot>
        </Table>
    )
}

export const TimeSheetList: React.FC<ListProps> = (props) =>
    <List {...props}
        pagination={false}
        title="Timesheet"
        mainTitle="ClickUp"
        hasCreate={false}
        hasEdit={false}
        hasShow={false}
        bulkActionButtons={false}
        filters={<TimeEntriesFilters />}
        filterDefaultValues={{
            start: moment().toDate()
        }}
        sort={{ field: "start", order: "DESC" }}
    >
        <Timesheet />
    </List>
