import React, { useEffect, useState } from "react";
import { Record, ReferenceField, required, TextInput, useMutation, useNotify } from "react-admin";
import { UndoableDeleteToolbar } from "../utils/Wrappers";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconCancel from "@material-ui/icons/Cancel";

import QRCodeGenerator from 'qrcode'
import { SimpleCompanyForm, ListByCompany } from "../utils/companyUtils";
import { CashRegister, CashRegisterActivation } from "@faktio/jsclient";
import { Show, ShowProps } from "../ui/detaill/Show";
import { EditButton } from "../ui/button/EditButton";
import Button, { ButtonProps } from "../ui/button/Button";
import { Edit, EditProps } from "../ui/detaill/Edit";
import { Create, CreateProps } from "../ui/detaill/Create";
import { ListProps } from "../ui/list/List";
import TextField from "../ui/field/TextField";
import Datagrid from "../ui/list/datagrid/Datagrid";
import SimpleForm from "../ui/form/SimpleForm";
import SimpleShowLayout from "../ui/detaill/SimpleShowLayout";

// TODO: LoadingButton
const ActivateButton: React.FC<{ record?: Record } & ButtonProps> = ({ record, ...rest }) => {
    const notify = useNotify();

    // DIALOG STATE
    const [open, setIsOpen] = useState(false)
    const closeDialog = () => setIsOpen(false)

    // LOAD API KEY
    const [apiKey, setApiKey] = useState<string | null>(null)
    const [activate, { loading }] = useMutation({
        type: 'createAction',
        resource: 'cashRegisters',
        payload: { id: record?.id, action: "activate" }
    }, {
        onSuccess: ({ data }: { data: CashRegisterActivation }) => {
            notify('Cash register has been activated');
            setApiKey(data.apiKey)
        },
        onFailure: (error) => notify(`Cash register activation error: ${error.message}`, 'warning'),
    });

    // GENERATE QR CODE
    const [QRcode, setQRcode] = useState<string | null>(null)
    useEffect(() => {
        if (apiKey) {
            QRCodeGenerator.toDataURL("APIKEY:" + apiKey)
                .then(qrcode => {
                    setQRcode(qrcode)
                    setIsOpen(true)
                })
                .catch(error => notify(`QR Code generation failed: ${error.message}`, 'warning'))
        }
    }, [apiKey])

    return (<>
        <Button label="activate" onClick={() => QRcode ? setIsOpen(true) : activate()} disabled={loading} {...rest} />
        <Dialog
            fullWidth
            open={open}
            onClose={closeDialog}
            aria-label="Activation"
        >
            <DialogTitle>Cash register activation</DialogTitle>
            <DialogContent>
                <img src={QRcode || ""} />
                <p>
                    <b>API Key:</b> {apiKey}
                </p>
            </DialogContent>
            <DialogActions>
                <Button label="ra.action.close" onClick={closeDialog} icon="close" />
            </DialogActions>
        </Dialog>
    </>);
};

const CashRegisterShowToolbar: React.FC<any> = ({ basePath, data }) => (<>
    <EditButton basePath={basePath} record={data} size="sm" />
    <ActivateButton record={data} size="sm" />
</>)


// TODO: No SHOW
export const CashRegisterShow = (props: ShowProps) => (
    <Show {...props}
        actions={<CashRegisterShowToolbar />}
        title={(record) => record && (record as CashRegister).getName()}>
        <SimpleShowLayout>
            <TextField source="name" label="Název" />
            <ReferenceField source="companyId" reference="companies">
                <TextField source="contact.businessName" label="Společnost" />
            </ReferenceField>
        </SimpleShowLayout>
    </Show>
)

// <Route
// path="/cashRegisters/create"
// render={() => (
//     <CashRegistersCreate {...props} />
// )}
// />
export const CashRegistersList = (props: ListProps) => (
    <ListByCompany {...props} title="Pokladny">
        <Datagrid size="sm" rowClick="show">
            <TextField source="name" label="Název" />
        </Datagrid>
    </ListByCompany>
);

export const CashRegistersCreate = (props: CreateProps) => (
    <Create {...props}>
        <SimpleCompanyForm mutationMode="pessimistic" redirect="show" variant="outlined">
            <TextInput source="name" label="Název" validate={required()} />
        </SimpleCompanyForm>
    </Create>
);

export const CashRegistersEdit = (props: EditProps) => (
    <Edit
        title={(record) => record && (record as CashRegister).getName()}
        {...props}
        mutationMode="pessimistic">
        <SimpleForm toolbar={<UndoableDeleteToolbar />} redirect="show" variant="outlined">
            <TextInput source="name" label="Název" validate={required()} />
        </SimpleForm>
    </Edit>
);