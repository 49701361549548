import { ReactElement, memo, useState, useContext, useEffect, useMemo } from 'react';
import { DrawerProps } from '@material-ui/core';
import { useGetIdentity, useGetList, useLocale, useLogout } from 'ra-core';
import { Icon } from '../utils/Icon';
import { MenuItem } from '../MenuItem';
import Collapse from 'react-bootstrap/esm/Collapse';
import classNames from 'classnames';
import { Company } from '@faktio/jsclient';
import { useCompany } from '../../utils/companyUtils';

// TODO: MOVE IT INTO APP CODE!!!
const UserInfo = memo(() => {
    //const translate = useTranslate();
    const { loaded, identity } = useGetIdentity();
    const logout = useLogout();
    const handleLogout = () => logout();

    const [open, setOpen] = useState(false);

    const { company: currentCompany, setCompany } = useCompany()
    const { data } = useGetList("companies", { page: 1, perPage: -1 }, { field: "id", order: "ASC" }, {}, { enabled: loaded })
    const companies = useMemo(() => data ? Object.values(data) as Company[] : undefined, [data])

    // Set initial company
    // TODO: What if fails?
    // TODO: Is it slow?
    useEffect(() => {
        if (!currentCompany && companies && companies.length > 0 && typeof setCompany === "function") {
            // LOAD FROM LOCALSTORAGE
            const savedCompany = JSON.parse(localStorage.getItem("currentCompany") || "{}")
            if (savedCompany?.value) {
                const foundCompany = companies.find(company => company.getID() === savedCompany?.value)
                if (foundCompany) {
                    setCompany(foundCompany)
                    return
                }
            }

            // ELSE: Set first company in the list
            setCompany(companies[0])
        }
    }, [currentCompany, companies, setCompany]);

    // Save current company
    useEffect(() => {
        if (currentCompany) localStorage.setItem("currentCompany", JSON.stringify({ value: currentCompany.getID() }))
    }, [currentCompany])

    return (<>
        {loaded ?
            <>
                <div
                    className={classNames("sidebar__userinfo", open && "expanded")}
                    role="button" aria-expanded={open} aria-controls="usermenu"
                    onClick={() => setOpen(!open)}
                >
                    <div className="sidebar__userinfo__content">
                        <div className="text-uppercase font-weight-bold">{currentCompany?.getContact().getBusinessName()}</div>
                        <>{
                            identity?.displayName ||
                            (identity?.firstName && identity?.lastName ?
                                (identity?.firstName + " " + identity?.lastName) :
                                identity?.username)
                        }</>
                    </div>
                    <Icon variant="outlined" name="arrow_drop_down_circle" />
                </div>
                <Collapse in={open}>
                    <div className="sidebar__usermenu" id="usermenu">
                        {(companies?.length || 0) > 1 ? <>
                            <div className="sidebar__usermenu__separator"></div>
                            <MenuItem.Title>Companies</MenuItem.Title>
                            {companies?.map(company =>
                                <MenuItem
                                    icon={currentCompany?.getID() === company.getID() ? "check_box" : "check_box_outline_blank"}
                                    to={"/companies/" + company.getID()}
                                    onClick={() => {
                                        setOpen(false)
                                        setCompany && setCompany(company)
                                    }}
                                >
                                    {company.getContact().getBusinessName()}
                                </MenuItem>
                            )}
                        </> : null}
                        <div className="sidebar__usermenu__separator"></div>
                        {identity?.id ?
                            <MenuItem
                                to={{
                                    pathname: `/users/${identity.id}/show`
                                }}
                                icon={"person"}
                                onClick={() => setOpen(false)}
                            >
                                My profile
                            </MenuItem> : null}
                        <MenuItem onClick={handleLogout} icon={"logout"}>Logout</MenuItem>
                    </div>
                </Collapse>
            </>
            :
            <div className="sidebar__userinfo"></div>
        }
    </>)
})

export interface SidebarProps extends DrawerProps {
    children: ReactElement;
}

export const Sidebar = (props: SidebarProps) => {
    const { children, ...rest } = props;
    useLocale(); // force redraw on locale change
    //const dispatch = useDispatch();
    // const open = useSelector<ReduxState, boolean>(
    //     state => state.admin.ui.sidebarOpen
    // );
    // const handleClose = () => dispatch(setSidebarVisibility(false));
    // const toggleSidebar = () => dispatch(setSidebarVisibility(!open));

    return (
        <aside className="sidebar">
            <UserInfo />
            <div className="sidebar__content">
                {children}
            </div>
        </aside>
        // isXSmall ? (
        //     <Drawer
        //         variant="temporary"
        //         open={open}
        //         PaperProps={{
        //             className: drawerPaper,
        //         }}
        //         onClose={toggleSidebar}
        //         classes={classes}
        //         {...rest}
        //     >
        //         {cloneElement(Children.only(children), {
        //             onMenuClick: handleClose,
        //         })}
        //     </Drawer>
        // ) : isSmall ? (
        //     <Drawer
        //         variant="permanent"
        //         open={open}
        //         PaperProps={{
        //             className: drawerPaper,
        //         }}
        //         onClose={toggleSidebar}
        //         classes={classes}
        //         {...rest}
        //     >
        //         {cloneElement(Children.only(children), {
        //             onMenuClick: handleClose,
        //         })}
        //     </Drawer>
        // ) : (
        //     <Drawer
        //         variant="permanent"
        //         open={open}
        //         PaperProps={{
        //             className: drawerPaper,
        //         }}
        //         onClose={toggleSidebar}
        //         classes={classes}
        //         {...rest}
        //     >
        //         {cloneElement(Children.only(children), {
        //             onMenuClick: defaultOnMenuClick,
        //         })}
        //     </Drawer>
    );
};
