import React from 'react';
import { FunctionComponent } from 'react';
import { useInput, InputProps } from 'ra-core';

import { sanitizeInputRestProps } from './sanitizeInputRestProps';
import { BasicInput, BasicInputProps } from './BasicInput';

export type TextInputProps = InputProps<BasicInputProps> &
    Omit<BasicInputProps, 'label' | 'helperText'>;

/**
 * An Input component for a string
 *
 * @example
 * <TextInput source="first_name" />
 *
 * You can customize the `type` props (which defaults to "text").
 * Note that, due to a React bug, you should use `<NumberField>` instead of using type="number".
 * @example
 * <TextInput source="email" type="email" />
 * <NumberInput source="nb_views" />
 *
 * The object passed as `options` props is passed to the <ResettableTextField> component
 */
const TextInput: FunctionComponent<TextInputProps> = ({
    source,
    resource,
    ...rest
}) => {
    const {
        id,
        input,
        isRequired,
        meta: { error, submitError, touched },
    } = useInput({
        type: 'text',
        source,
        resource,
        ...rest,
    });

    return (<BasicInput id={id} error={error} submitError={submitError} touched={touched} {...sanitizeInputRestProps(rest)} {...input} />);
};

export default TextInput;