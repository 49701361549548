import React, { Children, cloneElement, ReactElement, isValidElement, ReactNode, useCallback } from "react"
import classNames from "classnames"
import { ComponentProps } from "./tools/createComponent"
import { Icon, IconType } from "./utils/Icon"
import { useId } from "react-id-generator";
import Collapse from 'react-bootstrap/esm/Collapse';

export interface SubmenuProps extends ComponentProps {
    icon?: IconType | JSX.Element | ReactElement;
    title: JSX.Element | string;
    children?: ReactElement | ReactElement[] | ReactNode[];
    isExpanded: boolean;
    setIsExpanded: (expanded: boolean) => void;
}

export const Submenu: React.FC<SubmenuProps> = ({ icon, className, title, children, isExpanded, setIsExpanded }) => {
    const [id] = useId(1, "submenu-");

    const handleClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
        setIsExpanded(!isExpanded)
    }, [setIsExpanded, isExpanded])

    const classes = classNames("menu-item", "menu-item--has-submenu", className)
    const itemIcon = typeof icon === "string" ? <Icon name={icon} className="mr-3" /> : (
        icon ? React.cloneElement(icon, { "className": "mr-3" }) : null
    )

    if (!children || (Array.isArray(children) && children.length == 0)) { return null }

    return (<>
        <div
            className={classes}
            onClick={handleClick}
            role="button"
            aria-expanded={isExpanded}
            aria-controls={id}
        >
            {itemIcon}{title}
        </div>
        <Collapse in={isExpanded}>
            <div className="menu-item__submenu" id={id}>
                {Children.map(children, child =>
                    child && isValidElement<any>(child) ?
                        cloneElement(child, { icon: undefined })
                        : null
                )}
                {/* {children && cloneElement(Children.only(children), {
                    icon: undefined,
                })} */}
            </div>
        </Collapse>
    </>)
}
