import { DataProvider } from "ra-core";

export type Mapper = (params: any) => any;

export type Mixer = (
    resource: string
) =>
    | [DataProvider, string, Mapper]
    | [DataProvider, string]
    | DataProvider
    | undefined;

const mix = (mixer: Mixer, resource: string, params: any) => {
    const mixed = mixer(resource);

    if (!mixed) {
        throw new Error(`Provider not found for resource: '${resource}'`);
    }

    if (Array.isArray(mixed) && mixed.length === 3) {
        return [mixed[0], mixed[1], mixed[2](params)];
    }

    if (Array.isArray(mixed) && mixed.length === 2) {
        return [mixed[0], mixed[1], params];
    }

    return [mixed, resource, params]
};

export default (mixer: Mixer, fns: string[] = [
    "getList",
    "getOne",
    "getMany",
    "getManyReference",
    "create",
    "update",
    "updateMany",
    "delete",
    "deleteMany"
]): DataProvider => {
    const provider: Partial<DataProvider> = {}

    fns.forEach(fn => {
        provider[fn] = async (resource: string, params: any) => {
            const [mixedProvider, mixedResource, mixedParams] = mix(
                mixer,
                resource,
                params
            );

            return mixedProvider[fn](mixedResource, mixedParams);
        }
    })

    console.log(provider)

    return provider as DataProvider
}