import React from 'react';
import PropTypes from 'prop-types';
import { Record, useResourceDefinition, useShowContext } from 'ra-core';
import { EditButton } from '../button/EditButton';

// import { EditButton } from '../button';
// import TopToolbar from '../layout/TopToolbar';

/**
 * Action Toolbar for the Show view
 *
 * Internal component. If you want to add or remove actions for a Show view,
 * write your own ShowActions Component. Then, in the <Show> component,
 * use it in the `actions` prop to pass a custom component.
 *
 */
export const ShowActions = ({ className, ...rest }: ShowActionsProps) => {
    const { basePath, record } = useShowContext(rest);
    const { hasEdit } = useResourceDefinition(rest);
    return (<>
        { hasEdit && <EditButton basePath={basePath} record={record} size="sm" />}
    </>);
};

export interface ShowActionsProps {
    basePath?: string;
    className?: string;
    data?: Record;
    hasEdit?: boolean;
    hasList?: boolean;
    resource?: string;
}

