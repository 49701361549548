import * as React from 'react';
import { ReactElement } from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { FieldTitle } from 'ra-core';

export interface LabeledProps {
    label?: string | ReactElement;
    source?: string;
    [key: string]: any;
}
/**
 * Use any component as read-only Input, labeled just like other Inputs.
 *
 * Useful to use a Field in the Edit or Create components.
 * The child component will receive the current record.
 *
 * This component name doesn't have a typo. We had to choose between
 * the American English "Labeled", and the British English "Labelled".
 * We flipped a coin.
 *
 * @example
 * <Labeled label="Comments">
 *     <FooComponent source="title" />
 * </Labeled>
 */
const Labeled = (props: LabeledProps) => {
    const {
        label,
        source,
        children,
        ...rest
    } = props;
    if (!label && !source) {
        // @ts-ignore
        const name = children && children.type && children.type.name;

        throw new Error(
            `Cannot create label for component <${name}>: You must set either the label or source props. You can also disable automated label insertion by setting 'addLabel: false' in the component default props`
        );
    }
    const restProps = { ...rest, source }

    return (<>
        <h5 className='mb-0'>
            {label}
        </h5>
        {children && typeof children.type !== 'string'
            ? React.cloneElement(children, {
                ...restProps,
            })
            : children}
    </>);
};

export default Labeled;
