import * as React from 'react';
import { Children, cloneElement, ReactElement } from 'react';
import {
    Record,
    useResourceDefinition,
    useCreateContext,
    CreateControllerProps
} from 'ra-core';

import { CardView } from '../layout/Card';
import { MainTitle } from '../layout/MainTitle';
import { CreateProps } from './Create';

// TODO: Common utils
const formatTitle = (defaultTitle?: string, title?: string | ((record?: Partial<Record>) => string | undefined), record?: Partial<Record>): string => {
    if (record === undefined && typeof title === "function") {
        return defaultTitle || ""
    }

    if (typeof title === "function") {
        return title(record) || defaultTitle || ""
    }

    return title || defaultTitle || ""
}

export const CreateView = (props: CreateViewProps) => {
    const {
        actions,
        children,
        className,
        component: Content,
        mainTitle,
        title,
        subtitle,
        ...rest
    } = props;

    const {
        basePath,
        defaultTitle,
        hasList,
        record,
        redirect,
        resource,
        save,
        saving,
        version,
    } = useCreateContext(props);

    const { options } = useResourceDefinition(props);

    if (!children || !Content) {
        return null;
    }

    return (<>
        <MainTitle defaultTitle={defaultTitle} title={mainTitle || options?.label} />
        <Content
            title={formatTitle(defaultTitle, title, record)}
            subtitle={formatTitle("", subtitle, record)}
            actions={actions &&
                cloneElement(actions, {
                    basePath,
                    resource,
                    hasList,
                    //  Ensure we don't override any user provided props
                    ...actions.props,
                })}
        >
            {cloneElement(Children.only(children), {
                basePath,
                record,
                redirect:
                    typeof children.props.redirect === 'undefined'
                        ? redirect
                        : children.props.redirect,
                resource,
                save:
                    typeof children.props.save === 'undefined'
                        ? save
                        : children.props.save,
                saving,
                version,
            })}
        </Content>
    </>);
};

interface CreateViewProps
    extends CreateProps,
    Omit<CreateControllerProps, 'resource'> {
    children: ReactElement;
}

CreateView.defaultProps = {
    component: CardView
}

