import { useListContext } from 'ra-core';
import * as React from 'react';
import { ReactNode } from 'react';
import Card from 'react-bootstrap/esm/Card';
import FilterButton from './FilterButton';
import FilterForm from './FilterForm';


export interface FilterProps {
    children: ReactNode;
    context?: 'form' | 'button';
}

const Filter = (props: FilterProps) => {
    const {
        resource,
        showFilter,
        hideFilter,
        setFilters,
        displayedFilters,
        filterValues,
    } = useListContext(props);
    const renderButton = () => {
        const {
            context,
            children,
            ...rest
        } = props;

        return (
            <FilterButton
                resource={resource}
                filters={React.Children.toArray(children)}
                showFilter={showFilter}
                displayedFilters={displayedFilters}
                filterValues={filterValues}
            />
        );
    };

    const renderForm = () => {
        const { context, children, ...rest } = props;

        return (
            <Card.Body className="border-bottom bg-light py-3">
                <FilterForm
                    resource={resource}
                    filters={React.Children.toArray(children)}
                    initialValues={filterValues}
                />
            </Card.Body>
        );
    };

    return props.context === 'button' ? renderButton() : renderForm();
};

export default Filter;
