import React from "react";
import { ArrayInput, ListProps, RadioButtonGroupInput, ReferenceField, ReferenceInput, required, Record, useEditContext, useMutation, useNotify, useListContext } from "react-admin";
import createDecorator from 'final-form-calculate'

import { KeyboardDateInput } from "../utils/DateTimePickers";
import { DueDateField, DateField, LinkField, ReceiptTotalInclVAT } from "../utils/Fields";

import PersonAutocompleteWithCreate, { NewReceiptNumberInput } from "../utils/Inputs";
import { saveAs } from 'file-saver'

import moment from "moment";
import { ListByCompany, useCompany } from "../utils/companyUtils";
import { PaymentType, ReceiptType, Company, Receipt, ReceiptInterface } from "@faktio/jsclient";
import { Create, CreateProps } from "../ui/detaill/Create";
import { Edit, EditProps } from "../ui/detaill/Edit";
import Datagrid, { DatagridProps } from "../ui/list/datagrid/Datagrid";
import TextField from "../ui/field/TextField";
import { ListActions, ListMenuButton, ListMenuButtonItem, ListMenuButtonLink, ListShowButton } from "../utils/ListButtons";
import { EditActionsProps } from "../ui/detaill/EditActions";
import Button, { ButtonProps } from "../ui/button/Button";
import printJS from "print-js";
import SimpleForm from "../ui/form/SimpleForm";
import { DeleteWithConfirmButton } from "../ui/button/DeleteWithConfirmButton";

import { ReceiptItemsIterator } from "../utils/ReceiptItemsIterator";
import NumberInput from "../ui/inputs/NumberInput";
import TextInput from "../ui/inputs/TextInput";
import { FinalPrice, VATRate } from "@marekl/prices";
import Link from "../ui/Link";
import Filter from "../ui/list/filter/Filter";
import SelectInput from "../ui/inputs/SelectInput";
import { Span } from "../ui/utils/Inlines";
import BulkDeleteWithConfirmButton from "../ui/button/BulkDeleteWithConfirmButton";
import classNames from "classnames";

type WithReceiptType<T> = T & { type?: ReceiptType }

// @ts-ignore
//import { DateInput as RADateInput } from 'react-admin-date-inputs';

// const DateInput: React.FC<{
//     options?: Object,
//     label?: string,
//     source: string,
//     resource?: string,
//     className?: string,
//     isRequired?: boolean,
//     providerOptions?: Object,
// }> = ({ providerOptions, ...rest }) => {
//     const providerOptionsOrEmpty = providerOptions ? providerOptions : {}
//     return <RADateInput {...rest} providerOptions={{ ...providerOptionsOrEmpty, utils: MomentUtils }} />
// }

const getTitle = (type: ReceiptType, isVATPayer: boolean) => {
    switch (type) {
        case ReceiptType.Retail:
            return { default: "Prodejky", list: "Seznam prodejek", edit: "Úprava prodejky", create: "Nová prodejka" }
        case ReceiptType.SalesInvoice:
            return { default: "Vydaná faktura", list: "Seznam vydaných faktur", edit: "Úprava vydané faktury", create: "Nová vydaná faktura" }
        case ReceiptType.PurchaseInvoice:
            return { default: "Přijatá faktura", list: "Seznam přijatých faktur", edit: "Úprava přijaté faktury", create: "Nová přijatá faktura" }
        case ReceiptType.SalesOrder:
            return { default: "Cenová nabídka", list: "Seznam cenových nabídek", edit: "Úprava cenové nabídky", create: "Nová cenová nabídka" }
        case ReceiptType.CreditNote:
            return isVATPayer ?
                { default: "Dobropis", list: "Seznam dobropisů", edit: "Úprava dobropisu", create: "Nový dobropis" } :
                { default: "Storno faktura", list: "Seznam storno faktur", edit: "Úprava storno faktury", create: "Nová storno faktura" }
    }
}

// TODO: LoadingButton
// TODO: Disable when form changed
const DownloadButton: React.FC<
    ButtonProps & { record?: Record, resource?: string }
> = ({ record, resource, ...props }) => {
    const notify = useNotify()

    const [getPDF, { loading }] = useMutation({
        type: 'getAction',
        resource: resource,
        payload: { id: record?.id, action: "pdf" }
    }, {
        onSuccess: ({ data }: { data: any }) => {
            saveAs(data, `faktura-${record?.number}.pdf`)
        },
        onFailure: (error) => notify('Stažení PDF nebylo úspěšné', 'warning'),
    });

    return (<>
        <Button
            label="PDF"
            icon="download"
            onClick={(e) => {
                getPDF()
                e.stopPropagation()
                e.preventDefault()
            }}
            disabled={loading}
            loading={loading}
            variant="outline-primary"
            {...props} />
    </>);
};

const CreateInvoiceButton: React.FC<
    ButtonProps & { record?: Record, resource?: string }
> = ({ record, resource, ...props }) => {
    const notify = useNotify()
    const salesOrder = record as Receipt
    const { salesOrderId, issueDate, dueDate, meta, number, taxableSupplyDate, from, to, ...newInvoice } = salesOrder

    return (<Button
        label="vyfakturovat"
        icon="receipt"
        as={Link}
        to={{
            pathname: '/sales-invoices/create',
            state: {
                record: {
                    ...newInvoice,
                    receiptType: ReceiptType.SalesInvoice,
                    salesOrderId: salesOrder.id
                } as Receipt
            },
        }}
        variant="outline-primary"
        {...props}
    />);
};

const CreateCreditNoteButton: React.FC<
    ButtonProps & { record?: Record, resource?: string, isVATPayer?: boolean }
> = ({ record, resource, isVATPayer = false, ...props }) => {
    const salesInvoice = record as Receipt
    if (!salesInvoice.isSalesInvoice()) return null
    const { salesOrderId, issueDate, dueDate, meta, number, taxableSupplyDate, items, from, to, ...newCreditNote } = salesInvoice

    return (<Button
        label={isVATPayer ? "dobropis" : "storno"}
        icon="replay"
        as={Link}
        to={{
            pathname: '/credit-notes/create',
            state: {
                record: {
                    ...newCreditNote,
                    receiptType: ReceiptType.CreditNote,
                    creditedInvoiceId: salesInvoice.id,
                    items: salesInvoice.items.map(item => ({ ...item, unitPrice: item.unitPrice.mul(-1) })),
                } as Receipt
            },
        }}
        variant="outline-primary"
        {...props}
    />);
};

const PrintButton: React.FC<
    ButtonProps & { record?: Record, resource?: string }
> = ({ record, resource, ...props }) => {
    const notify = useNotify()

    const [printPDF, { loading }] = useMutation({
        type: 'getAction',
        resource: resource,
        payload: { id: record?.id, action: "pdf" }
    }, {
        onSuccess: ({ data }: { data: any }) => {
            let reader = new FileReader();
            reader.readAsDataURL(data); // converts the blob to base64 and calls onload

            reader.onload = function () {
                console.log(reader)
                printJS({
                    printable: (reader.result as string).replace(/^data:application\/pdf;base64,/, ''),
                    type: 'pdf',
                    base64: true
                })
            };
        },
        onFailure: (error) => notify('Stažení PDF nebylo úspěšné', 'warning'),
    });

    return (<>
        <Button
            label="tisk"
            icon="print"
            onClick={(e) => {
                printPDF()
                e.stopPropagation()
                e.preventDefault()
            }}
            disabled={loading}
            variant="outline-primary"
            {...props} />
    </>);
};

export const ReceiptListSum = (props: any) => {
    const {
        data,
        selectedIds,
    } = useListContext<Receipt>(props);

    const receipts = selectedIds.map(id => data[id])
    const total = receipts.reduce((total, receipt) => total.add(receipt.getTotal().getTotalInclVAT()), new FinalPrice())

    return <Span className={classNames(
        "font-weight-bold",
        props.className
    )}>Total: {total.format()}</Span>
}

const receiptDecorator = (company: Company, type: ReceiptType) => createDecorator(
    {
        field: 'issueDate',
        updates: (type === ReceiptType.SalesInvoice ?
            {
                dueDate: (issueDate, values) =>
                    moment(issueDate).add(company.getDefaultDueDays() || 30, "days").toDate(),
                taxableSupplyDate: (issueDate, values) =>
                    moment(issueDate).toDate()
            } : {})
    }
)

// TODO: Payment type - faktura převodem; projeka hotově
// TODO: DUZP - Auto dle data vystavení
export const ReceiptsCreate: React.FC<WithReceiptType<CreateProps>> = ({ type, ...props }) => {
    const { company } = useCompany()
    if (!company) return null
    if (!type) type = ReceiptType.SalesInvoice

    let defaults: any = {
        companyId: company.getID(),
        receiptType: type,
        issueDate: moment().toDate(),
        items: [{}]
    }
    if ([ReceiptType.SalesInvoice, ReceiptType.Retail, ReceiptType.SalesOrder, ReceiptType.CreditNote].includes(type)) {
        defaults.fromId = company.getContact().getID()
    } else {
        defaults.toId = company.getContact().getID()
    }

    const titles = getTitle(type, company.getContact().isVATPayer())

    return (
        <Create {...props}
            title={titles.create}
            mainTitle={titles.default}
        >
            <SimpleForm
                mutationMode="pessimistic"
                redirect="edit"

                decorators={[receiptDecorator(company, type)]}
                defaultValue={defaults}>

                <NewReceiptNumberInput source="number" label="Číslo dokladu" validate={required()} />

                {([ReceiptType.SalesInvoice, ReceiptType.SalesOrder, ReceiptType.CreditNote].includes(type)) ?
                    <PersonAutocompleteWithCreate companyId={company.getID()} source="toId" label="Odběratel" validate={required()} /> :
                    (type === ReceiptType.Retail ?
                        <PersonAutocompleteWithCreate companyId={company.getID()} source="toId" label="Odběratel" /> :
                        <PersonAutocompleteWithCreate companyId={company.getID()} source="fromId" label="Dodavatel" validate={required()} />)
                }

                <KeyboardDateInput source="issueDate" label="Datum vystavení" options={{ format: "DD.MM.YYYY", autoOk: true, variant: "inline", inputVariant: "outlined", placeholder: "DD.MM.YYYY" }} validate={required()} />

                {([ReceiptType.SalesInvoice, ReceiptType.PurchaseInvoice].includes(type)) &&
                    <KeyboardDateInput source="dueDate" label="Datum splatnosti" options={{ format: "DD.MM.YYYY", autoOk: true, variant: "inline", inputVariant: "outlined", placeholder: "DD.MM.YYYY" }} validate={required()} defaultValue={moment().add(company.getDefaultDueDays() || 30, "days").toDate()} />
                }

                {// TODO: Jak funguje DUZP u dobropisů?
                }
                {(company.contact.VATPayer && [ReceiptType.SalesInvoice, ReceiptType.PurchaseInvoice, ReceiptType.SalesOrder].includes(type)) &&
                    <KeyboardDateInput source="taxableSupplyDate" label="Datum zdaň. plnění" options={{ format: "DD.MM.YYYY", autoOk: true, variant: "inline", inputVariant: "outlined", placeholder: "DD.MM.YYYY" }} validate={required()} defaultValue={moment().toDate()} />
                }

                {([ReceiptType.SalesInvoice, ReceiptType.SalesOrder].includes(type)) &&
                    <TextInput label="Číslo objednávky" source="orderNumber" />
                }

                {(type === ReceiptType.Retail) &&
                    <ReferenceInput label="Pokladna" source="cashRegisterId" reference="cashRegisters">
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                }

                <TextInput label="Text před položkami" source="itemsTextPrefix" />

                <SelectInput source="paymentType" label="Platba" choices={[
                    { id: PaymentType.Cash, name: 'Hotově' },
                    { id: PaymentType.PaymentCard, name: 'Kartou' },
                    { id: PaymentType.Bank, name: 'Převodem' }
                ]} defaultValue={type === ReceiptType.Retail ? PaymentType.Cash : PaymentType.Bank} />

                <ArrayInput source="items">
                    <ReceiptItemsIterator>
                        <TextInput source="name" label="Název" validate={required()} />
                        <NumberInput source="quantity" label="Množství" validate={required()} />
                        <TextInput source="unit" label="Jednotka" />
                        <NumberInput source="unitPrice.value" label="Jednotková cena" />
                        {company.getContact().isVATPayer() ?
                            <SelectInput defaultValue={company.getDefaultVATRate() || VATRate.BasicVATRate} source="unitPrice.vatRate" label="DPH" choices={[
                                { id: VATRate.ZeroVATRate, name: '0 %' },
                                { id: VATRate.SecondReducedVATRate, name: '10 %' },
                                { id: VATRate.FirstReducedVATRate, name: '15 %' },
                                { id: VATRate.BasicVATRate, name: '21 %' }
                            ]} />
                            : null}
                    </ReceiptItemsIterator>
                </ArrayInput>

                <TextInput label="Text za položkami" source="itemsTextSuffix" />

            </SimpleForm>
        </Create >
    );
}


const ReceiptsEditActions: React.FC<EditActionsProps> = (props) => {
    const { resource, record } = useEditContext(props)
    return <>
        {record?.receiptType === ReceiptType.SalesOrder ?
            <CreateInvoiceButton record={record} resource={resource} size="sm" />
            : null}
        <PrintButton record={record} resource={resource} size="sm" />
        <DownloadButton record={record} resource={resource} size="sm" />
    </>
}

// TODO: Payment type - faktura převodem; projeka hotově
// TODO: DUZP - Auto dle data vystavení
export const ReceiptsEdit: React.FC<WithReceiptType<EditProps>> = ({ type, ...props }) => {
    const { company } = useCompany()
    if (!company) return null
    if (!type) type = ReceiptType.SalesInvoice

    const titles = getTitle(type, company.getContact().isVATPayer())

    return (
        <Edit {...props}
            mutationMode="pessimistic"
            title={titles.edit}
            mainTitle={titles.default}
            actions={<ReceiptsEditActions />}
        >
            <SimpleForm
                redirect="edit"

                decorators={[receiptDecorator(company, type)]}
                defaultValue={{ companyId: company.getID() }}>

                <TextInput source="number" label="Číslo dokladu" disabled validate={required()} />

                {([ReceiptType.SalesInvoice, ReceiptType.SalesOrder, ReceiptType.CreditNote].includes(type)) ?
                    <PersonAutocompleteWithCreate companyId={company.getID()} source="toId" label="Odběratel" validate={required()} /> :
                    (type === ReceiptType.Retail ?
                        <PersonAutocompleteWithCreate companyId={company.getID()} source="toId" label="Odběratel" /> :
                        <PersonAutocompleteWithCreate companyId={company.getID()} source="fromId" label="Dodavatel" validate={required()} />)
                }

                <KeyboardDateInput source="issueDate" label="Datum vystavení" options={{ format: "DD.MM.YYYY", autoOk: true, variant: "inline", inputVariant: "outlined", placeholder: "DD.MM.YYYY" }} validate={required()} />

                {([ReceiptType.SalesInvoice, ReceiptType.PurchaseInvoice, ReceiptType.CreditNote].includes(type)) &&
                    <KeyboardDateInput source="dueDate" label="Datum splatnosti" options={{ format: "DD.MM.YYYY", autoOk: true, variant: "inline", inputVariant: "outlined", placeholder: "DD.MM.YYYY" }} validate={required()} />
                }

                {// TODO: Jak funguje DUZP u dobropisů?
                }
                {(company.contact.VATPayer && [ReceiptType.SalesInvoice, ReceiptType.PurchaseInvoice, ReceiptType.CreditNote].includes(type)) &&
                    <KeyboardDateInput source="taxableSupplyDate" label="Datum zdaň. plnění" options={{ format: "DD.MM.YYYY", autoOk: true, variant: "inline", inputVariant: "outlined", placeholder: "DD.MM.YYYY" }} validate={required()} />
                }

                {(type === ReceiptType.Retail) &&
                    <ReferenceInput label="Pokladna" source="cashRegisterId" reference="cashRegisters">
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                }

                {([ReceiptType.SalesInvoice, ReceiptType.SalesOrder].includes(type)) &&
                    <TextInput label="Číslo objednávky" source="orderNumber" />
                }

                <SelectInput source="paymentType" label="Platba" choices={[
                    { id: PaymentType.Cash, name: 'Hotově' },
                    { id: PaymentType.PaymentCard, name: 'Kartou' },
                    { id: PaymentType.Bank, name: 'Převodem' }
                ]} defaultValue={type === ReceiptType.Retail ? PaymentType.Cash : PaymentType.Bank} />

                <TextInput label="Text před položkami" source="itemsTextPrefix" />

                <ArrayInput source="items">
                    <ReceiptItemsIterator>
                        <TextInput source="name" label="Název" validate={required()} />
                        <NumberInput source="quantity" label="Množství" validate={required()} />
                        <TextInput source="unit" label="Jednotka" />
                        <NumberInput source="unitPrice.value" label="Jednotková cena" />
                        {company.getContact().isVATPayer() ?
                            <SelectInput
                                fullWidth
                                formClassName="col-2"

                                defaultValue={company.getDefaultVATRate() || VATRate.BasicVATRate}
                                source="unitPrice.vatRate"
                                label="DPH"
                                choices={[
                                    { id: VATRate.ZeroVATRate, name: '0 %' },
                                    { id: VATRate.SecondReducedVATRate, name: '10 %' },
                                    { id: VATRate.FirstReducedVATRate, name: '15 %' },
                                    { id: VATRate.BasicVATRate, name: '21 %' }
                                ]} />
                            : null}
                    </ReceiptItemsIterator>
                </ArrayInput>

                <TextInput label="Text za položkami" source="itemsTextSuffix" />

                <ReferenceField source="salesOrderId" reference={`receipts/${ReceiptType.SalesOrder}`}>
                    <TextField source="number" />
                </ReferenceField>

            </SimpleForm>
        </Edit>
    );
}

const ReceiptsListBulkActions: React.FC<any> = (props) => <>
    <ReceiptListSum {...props} className="mr-3" />
    <BulkDeleteWithConfirmButton variant="danger" size="sm" {...props} />
</>

export const RecieptsList: React.FC<WithReceiptType<ListProps>> = ({ type, ...props }) => {
    if (!type) type = ReceiptType.SalesInvoice
    const { company } = useCompany()
    if (!company) return null
    const titles = getTitle(type, company.getContact().isVATPayer())
    return (
        <ListByCompany {...props}
            filters={<RecieptsFilter />}
            title={titles.list}
            mainTitle={titles.default}
            filter={{ receiptType: type }}
            hasCreate={type !== ReceiptType.CreditNote}
            bulkActionButtons={<ReceiptsListBulkActions />}
        >
            <ReceiptsDatagrid type={type} company={company} />
        </ListByCompany>
    )
}

export const ReceiptsDatagrid: React.FC<WithReceiptType<DatagridProps> & { company?: Company }> = ({ type, company, ...props }) => (
    <Datagrid rowClick="edit" {...props}>
        {[
            <LinkField target="edit" source="number" label="Číslo" className="font-weight-bold" shrink />,
            // @ts-ignore
            type === ReceiptType.Retail ? <ReferenceField source="cashRegisterId" reference="cashRegisters" label="Pokladna" link={false} shrink>
                <TextField source="name" />
            </ReferenceField> : null,
            <TextField source="to.businessName" label="Odběratel" expand />,
            type === ReceiptType.Retail ?
                <DateField source="issueDate" label="Datum" cellClassName="text-center" headerClassName="text-center" shrink /> :
                <DateField source="issueDate" label="Vystaveno" cellClassName="text-center" headerClassName="text-center" shrink />,
            type === ReceiptType.PurchaseInvoice || type === ReceiptType.SalesInvoice ?
                <DueDateField source="dueDate" label="Splatnost" cellClassName="text-right" headerClassName="text-center" shrink /> : null,
            //TODO: Company without VAT - different title
            <ReceiptTotalInclVAT cellClassName="text-right cell-big" headerClassName="text-center" label="Cena s DPH" shrink />,
            <ListActions shrink>
                <ListMenuButton>
                    {type === ReceiptType.SalesOrder ?
                        <CreateInvoiceButton as={Link} variant="text" />
                        : null}
                    {type === ReceiptType.SalesInvoice ?
                        <CreateCreditNoteButton as={Link} variant="text" isVATPayer={company?.isVATPayer()} />
                        : null}
                    <DownloadButton as={ListMenuButtonItem} variant="text" />
                    <PrintButton as={ListMenuButtonItem} variant="text" label="Tisk" />
                    <DeleteWithConfirmButton as={ListMenuButtonItem} variant="text" className={"text-danger"} label="Smazat" />
                </ListMenuButton>
                <ListShowButton />
            </ListActions>,
        ]}
    </Datagrid>
)


const RecieptsFilter = (props: any) => (
    <Filter {...props}>
        <NumberInput label="Číslo" source="number" alwaysOn />
        <TextInput label="Title" source="title" defaultValue="Hello, World!" />
    </Filter>
);

