import * as React from 'react';
import {
    ChangeEvent,
    Children,
    cloneElement,
    isValidElement,
    ReactElement,
    ReactNode,
    useState,
} from 'react';
import classnames from 'classnames';
import { Route, useRouteMatch, useLocation } from 'react-router-dom';
import {
    escapePath,
    FormWithRedirectRenderProps,
    MutationMode,
    Record,
} from 'ra-core';
import { Toolbar } from './Toolbar';
import { CardBody, CardFooter, CardNav } from '../layout/Card';
import { getTabFullPath, TabbedFormNav } from './TabbedFormNav';

export const TabbedFormView: React.FC<TabbedFormViewProps> = (props) => {
    const {
        basePath,
        children,
        className,
        navComponent: NavComponent,
        footerComponent: FooterComponent,
        handleSubmit,
        handleSubmitWithRedirect,
        invalid,
        mutationMode,
        pristine,
        record,
        redirect,
        resource,
        saving,
        submitOnEnter,
        navigation,
        toolbar,
        ...rest
    } = props;
    const match = useRouteMatch();
    const location = useLocation();
    const url = match ? match.url : location.pathname;

    if (!navigation || !record || !resource || !FooterComponent || !NavComponent) return null

    return (
        <form
            className={classnames('tabbed-form', className)}
            {...sanitizeRestProps(rest)}
        >
            <NavComponent>
                {cloneElement(
                    navigation,
                    { baseUrl: url },
                    children
                )}
            </NavComponent>
            {/* All tabs are rendered (not only the one in focus), to allow validation
                on tabs not in focus. The tabs receive a `hidden` property, which they'll
                use to hide the tab using CSS if it's not the one in focus.
                See https://github.com/marmelab/react-admin/issues/1866 */}
            {Children.map(children, (tab, index) => {
                if (!tab || !React.isValidElement(tab)) {
                    return;
                }
                const tabPath = getTabFullPath(tab, index, url);
                return (
                    <Route exact path={escapePath(tabPath)}>
                        {routeProps =>
                            isValidElement<any>(tab)
                                ? React.cloneElement(tab, {
                                    resource,
                                    record,
                                    basePath,
                                    hidden: !routeProps.match,
                                    value: tabPath
                                })
                                : null
                        }
                    </Route>
                );
            })}
            <FooterComponent>
                {toolbar &&
                    React.cloneElement(toolbar, {
                        basePath,
                        handleSubmitWithRedirect,
                        handleSubmit,
                        invalid,
                        mutationMode,
                        pristine,
                        record,
                        redirect,
                        resource,
                        saving,
                        submitOnEnter,
                    })}
            </FooterComponent>
        </form >
    );
};

TabbedFormView.defaultProps = {
    submitOnEnter: true,
    toolbar: <Toolbar />,
    navigation: <TabbedFormNav />,
    navComponent: CardNav,
    footerComponent: CardFooter
};

export interface TabbedFormViewProps extends FormWithRedirectRenderProps {
    basePath?: string;
    children?: ReactNode;
    className?: string;
    navComponent?: React.ComponentType<any>;
    footerComponent?: React.ComponentType<any>;
    mutationMode?: MutationMode;
    record?: Partial<Record>;
    resource?: string;
    syncWithLocation?: boolean;
    navigation?: ReactElement;
    toolbar?: ReactElement;
    submitOnEnter?: boolean;
    __versions?: any; // react-final-form internal prop, missing in their type
}

const sanitizeRestProps = ({
    active,
    dirty,
    dirtyFields,
    dirtyFieldsSinceLastSubmit,
    dirtySinceLastSubmit,
    error,
    errors,
    form,
    hasSubmitErrors,
    hasValidationErrors,
    initialValues,
    modified = null,
    modifiedSinceLastSubmit,
    save = null,
    submitError,
    submitErrors,
    submitFailed,
    submitSucceeded,
    submitting,
    touched = null,
    valid,
    validating,
    values,
    visited = null,
    __versions = null,
    ...props
}: any) => props;