import React from "react";
import { FieldProps } from "react-admin";
import { Contact } from '@faktio/jsclient'

export const ContactLineField: React.FC<FieldProps> = ({ record }) => {
    const contact = record?.contact as Contact | undefined
    if (!contact) return null


    return <>{contact.getAddressLine()}</>
}

ContactLineField.defaultProps = {
    addLabel: true,
};