import { Product } from "@faktio/jsclient";
import { VATRate } from '@marekl/prices';
import React from "react";
import { RadioButtonGroupInput, ReferenceField, ReferenceInput, required } from "react-admin";
import { Edit, EditProps } from "../ui/detaill/Edit";
import { List, ListProps } from "../ui/list/List";
import { Create, CreateProps } from "../ui/detaill/Create";
import { CompanyListener, ListByCompany, SimpleCompanyForm } from "../utils/companyUtils";
import { UndoableDeleteToolbar } from "../utils/Wrappers";
import Datagrid from "../ui/list/datagrid/Datagrid";
import TextField from "../ui/field/TextField";
import { PriceInclVATField, VATField } from "../utils/Fields";
import SimpleForm from "../ui/form/SimpleForm";
import TextInput from "../ui/inputs/TextInput";
import NumberInput from "../ui/inputs/NumberInput";
import SelectInput from "../ui/inputs/SelectInput";


export const ProductsList = (props: ListProps) => (
    <ListByCompany {...props} title="Produkty">
        <Datagrid size="sm" rowClick="edit">
            <TextField source="name" label="Název" />
            <TextField cellClassName="text-center" source="unit" label="Jednotka" shrink />
            <VATField cellClassName="text-center" source="price.vatRate" label="DPH" shrink />
            <PriceInclVATField headerClassName="text-center" cellClassName="text-right cell-big" source="price" label="Cena vč. DPH" shrink />
        </Datagrid>
    </ListByCompany>
);

export const ProductsCreate = (props: CreateProps) => (
    <Create {...props}>
        <SimpleCompanyForm mutationMode="pessimistic" variant="outlined">

            <TextInput source="name" label="Název" validate={required()} />

            <TextInput source="unit" label="Jednotka" validate={required()} />

            <NumberInput source="price.value" label="Cena" validate={required()} />
            <CompanyListener>
                {company => company.getContact().isVATPayer() ?
                    <RadioButtonGroupInput label="DPH" source="price.vatRate" choices={[
                        { id: VATRate.ZeroVATRate, name: 'Bez DPH' },
                        { id: VATRate.FirstReducedVATRate, name: '10 %' },
                        { id: VATRate.SecondReducedVATRate, name: '15 %' },
                        { id: VATRate.BasicVATRate, name: '21 %' }
                    ]} />
                    : null
                }
            </CompanyListener>

        </SimpleCompanyForm>
    </Create>
);

export const ProductsEdit = (props: EditProps) => (
    <Edit
        title={(record) => record && (record as Product).getName()}
        {...props}
        mutationMode="pessimistic"
    >
        <SimpleForm mutationMode="pessimistic" variant="outlined">
            <TextInput source="name" label="Název" validate={required()} />
            <NumberInput source="price.value" label="Cena" validate={required()} />

            <TextInput source="unit" label="Jednotka" validate={required()} />

            <RadioButtonGroupInput label="DPH" source="price.vatRate" choices={[
                { id: VATRate.ZeroVATRate, name: 'Bez DPH' },
                { id: VATRate.SecondReducedVATRate, name: '10 %' },
                { id: VATRate.FirstReducedVATRate, name: '15 %' },
                { id: VATRate.BasicVATRate, name: '21 %' }
            ]} />
        </SimpleForm>
    </Edit>
);