import React from 'react';
import { FC, ReactElement, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { linkToRecord, Record } from 'ra-core';
import { IconVariant } from '../utils/Icon';
import Button, { ButtonProps } from './Button';
import classnames from 'classnames';

/**
 * Opens the Edit view of a given record:
 *
 * @example // basic usage
 * import { EditButton } from 'react-admin';
 *
 * const CommentEditButton = ({ record }) => (
 *     <EditButton basePath="/comments" label="Edit comment" record={record} />
 * );
 */
export const EditButton: FC<EditButtonProps> = ({
    basePath = '',
    label = 'ra.action.edit',
    record,
    scrollToTop = true,
    className,
    ...rest
}) => (
    <Button
        as={Link}
        to={useMemo(
            () => ({
                pathname: record ? linkToRecord(basePath, record.id) : '',
                state: { _scrollToTop: scrollToTop },
            }),
            [basePath, record, scrollToTop]
        )}
        label={label}
        onClick={stopPropagation}
        className={classnames("text-lowercase", className)}
        {...(rest as any)}
    />
);

EditButton.defaultProps = {
    icon: "edit",
    variant: "outline-primary"
}

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = (e: any) => e.stopPropagation();

interface Props {
    basePath?: string;
    record?: Record;
    scrollToTop?: boolean;
}

export type EditButtonProps = Props & ButtonProps

