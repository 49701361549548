import { User, UserInterface } from "@faktio/jsclient";
import React from "react";
import { ChipField, ReferenceArrayField, ReferenceArrayInput, ReferenceInput, required, SelectArrayInput, SingleFieldList } from "react-admin";
import { Create, CreateProps } from "../ui/detaill/Create";
import { Edit, EditProps } from "../ui/detaill/Edit";
import { Show, ShowProps } from "../ui/detaill/Show";
import EmailField from "../ui/field/EmailField";
import TextField from "../ui/field/TextField";
import { FormTab } from "../ui/form/FormTab";
import SimpleForm from "../ui/form/SimpleForm";
import { TabbedForm } from "../ui/form/TabbedForm";
import BooleanInput from "../ui/inputs/BooleanInput";
import PasswordInput from "../ui/inputs/PasswordInput";
import SelectInput from "../ui/inputs/SelectInput";
import TextInput from "../ui/inputs/TextInput";
import Datagrid from "../ui/list/datagrid/Datagrid";
import { List, ListProps } from "../ui/list/List";
import { CompanyField, LabeledCompanyField } from "../utils/Fields";
import { UndoableDeleteToolbar } from "../utils/Wrappers";
import { TabbedShowLayout } from "../ui/detaill/TabbedShowLayout";
import { Tab } from "../ui/detaill/Tab";

import { PermissionsEditDatagrid, PermissionsListDatagrid } from "./Permissions";

const validateUserForm = (values: Partial<UserInterface> & { password2?: string }) => {
    const errors: { [key: string]: string[] } = {};
    if (values.password && values.password !== values.password2) {
        errors.password2 = ['Hesla se neshodují'];
    }
    return errors
};

export const UsersList = (props: ListProps) => (
    <List {...props} title="Seznam uživatelů" sort={{ field: "username", order: "DESC" }}>
        <Datagrid rowClick="show" size="sm">
            <TextField source="username" label="Uživatelské jméno" />
            <CompanyField source="companyId" label="Společnost" />
            <TextField source="firstName" label="Jméno" />
            <TextField source="lastName" label="Příjmení" />
            <EmailField source="email" label="Email" />
        </Datagrid>
    </List>
);

// TODO: displayName to getDisplayName (displayName does not have to be set... or should it?)
export const UsersShow = (props: ShowProps) => (
    <Show {...props} title="Detail uživatele" subtitle={(record) => (record as User).displayName}>
        <TabbedShowLayout>
            <Tab label="General">
                <TextField source="username" label="Uživatelské jméno" />
                <TextField source="firstName" label="Jméno" />
                <TextField source="lastName" label="Příjmení" />
                <TextField source="displayName" label="Zobrazené jméno" />
                <EmailField source="email" label="Email" />
                <TextField source="phone" label="Telefon" />

                <ReferenceArrayField label="Roles" reference="roles" source="rolesIds">
                    <SingleFieldList>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>

                <LabeledCompanyField source="companyId" label="Společnost" />
            </Tab>
            <Tab label="Oprávnění" contentComponent={React.Fragment}>
                <PermissionsListDatagrid />
            </Tab>
        </TabbedShowLayout>
    </Show>
)

// TODO: Hide permissions for sysadm
export const UsersCreate = (props: CreateProps) => (
    <Create {...props} title="Nový uživatele">
        <SimpleForm mutationMode="pessimistic" redirect="show" variant="outlined" validate={validateUserForm}>
            <TextInput source="username" label="Uživatelské jméno" validate={required()} />

            {props.permissions === true &&
                <BooleanInput source="sysAdm" label="System admin" />}

            <TextInput source="firstName" label="Jméno" />
            <TextInput source="lastName" label="Příjmení" />
            <TextInput source="displayName" label="Zobrazené jméno" />
            <TextInput source="email" label="Email" />
            <TextInput source="phone" label="Telefon" />

            <ReferenceArrayInput label="Role" reference="roles" source="rolesIds">
                <SelectArrayInput optionText="name" />
                {/* <AutocompleteArrayInput /> */}
            </ReferenceArrayInput>

            <ReferenceInput source="companyId" reference="companies">
                <SelectInput optionText="contact.businessName" label="Společnost" />
            </ReferenceInput>

            <PasswordInput source="password" label="Nové heslo" validate={required()} />
            <PasswordInput source="password2" label="Heslo znovu" />

        </SimpleForm>
    </Create>
);

export const UsersEdit = (props: EditProps) => (
    <Edit {...props} mutationMode="pessimistic" title="Editace uživatele" subtitle={(record) => (record as User).displayName}>
        <TabbedForm toolbar={<UndoableDeleteToolbar />} redirect="show" variant="outlined" validate={validateUserForm}>
            <FormTab label="General">
                <TextInput source="username" label="Uživatelské jméno" disabled={true} />

                {props.permissions === true &&
                    <BooleanInput source="sysAdm" label="System admin" />}

                <TextInput source="firstName" label="Jméno" />
                <TextInput source="lastName" label="Příjmení" />
                <TextInput source="displayName" label="Zobrazené jméno" />
                <TextInput source="email" label="Email" />
                <TextInput source="phone" label="Telefon" />

                <ReferenceArrayInput label="Role" reference="roles" source="rolesIds">
                    <SelectArrayInput optionText="name" />
                </ReferenceArrayInput>


                <ReferenceInput source="companyId" reference="companies">
                    <SelectInput optionText="contact.businessName" label="Společnost" />
                </ReferenceInput>

                <PasswordInput source="password" label="Nové heslo" />
                <PasswordInput source="password2" label="Heslo znovu" />

            </FormTab>
            <FormTab label="Permissions" path="permissions" component={React.Fragment}>
                <PermissionsEditDatagrid />
            </FormTab>
        </TabbedForm>
    </Edit>
);


