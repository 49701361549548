import { useTranslate } from 'ra-core';
import React from 'react';
import { InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/esm/Form';
import { FieldInputProps, FieldMetaState } from 'react-final-form'
import { useId } from 'react-id-generator';

export const BasicFormGroup: React.FC<BasicFormGroupProps> = ({
    label,
    touched,
    error,
    submitError,
    name,
    id,
    children,
    formGroupClassName,
    ...rest
}) => {
    const [genId] = useId()
    const translate = useTranslate();

    const isInvalid = !!(touched && (error || submitError))

    return (
        <Form.Group controlId={id ? id : name + "-" + genId} className={formGroupClassName}>
            {typeof label === "string" && (
                <Form.Label>{label && translate(label || "")}</Form.Label>
            )}
            {children}
            {isInvalid ?
                <Form.Control.Feedback className="visibile d-block" type="invalid">
                    {translate(error || submitError || "")}
                </Form.Control.Feedback>
                : <Form.Control.Feedback type="invalid">&nbsp;</Form.Control.Feedback>}
        </Form.Group>
    )
};

export interface BasicFormGroupProps extends
    Pick<FieldMetaState<any>, "error" | "submitError" | "touched">,
    Pick<FieldInputProps<any>, "name"> {
    id?: string;
    label?: string | boolean;
    formGroupClassName?: string;
}
