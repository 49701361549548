import React, { memo } from 'react';
import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { linkToRecord, Record } from 'ra-core';
import Button, { ButtonProps } from './Button';
import classnames from "classnames";

/**
 * Opens the Show view of a given record
 *
 * @example // basic usage
 * import { ShowButton } from 'react-admin';
 *
 * const CommentShowButton = ({ record }) => (
 *     <ShowButton basePath="/comments" label="Show comment" record={record} />
 * );
 */
const ShowButton: FC<ShowButtonProps> = ({
    basePath = '',
    label = 'ra.action.show',
    record,
    scrollToTop = true,
    className,
    ...rest
}) => (
    <Button
        as={Link}
        to={useMemo(
            () => ({
                pathname: record
                    ? `${linkToRecord(basePath, record.id)}/show`
                    : '',
                state: { _scrollToTop: scrollToTop },
            }),
            [basePath, record, scrollToTop]
        )}
        label={label}
        onClick={stopPropagation}
        className={classnames("text-lowercase", className)}
        {...(rest as any)}
    />
);

ShowButton.defaultProps = {
    // icon: "visibility",
    // iconVariant: "outlined",
    variant: "outline-primary"
}

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = (e: any) => e.stopPropagation();

interface Props {
    basePath?: string;
    record?: Record;
    scrollToTop?: boolean;
}

export type ShowButtonProps = Props & ButtonProps

const PureShowButton = memo(
    ShowButton,
    (props: ShowButtonProps, nextProps: ShowButtonProps) =>
        (props.record && nextProps.record
            ? props.record.id === nextProps.record.id
            : props.record == nextProps.record) && // eslint-disable-line eqeqeq
        props.basePath === nextProps.basePath &&
        props.to === nextProps.to &&
        props.disabled === nextProps.disabled
);


export { PureShowButton as ShowButton }