import React, { Children, memo, ReactNode } from "react";
import { Link } from "react-router-dom";
import { QuickAddActionsType, QuickAddButton, QuickAddItem, QuickAddItemType } from "../button/QuickAdd";
import { RefreshButton } from "../button/RefreshButton";
import { createComponent } from "../tools/createComponent";
import { Title, VR } from "../utils/Inlines";
import { Container } from "./Container";

export interface AppBarProps {
    className?: string;
    logout?: ReactNode;
    open?: boolean; // dispatch(toggleSidebar())
    title?: string | JSX.Element;
    userMenu?: JSX.Element | boolean;
    actions?: JSX.Element;
    quickAddActions?: QuickAddActionsType;
}

const TopBar = createComponent("div", "top-bar")
const TopBarActions = createComponent("div", "top-bar__actions")

/**
 * The AppBar component renders a custom AppBar.
 **/

const AppBar: React.FC<AppBarProps> = (props) => {
    const {
        children,
        quickAddActions,
        className
    } = props;

    // const dispatch = useDispatch();
    // const translate = useTranslate();

    return (
        // <HideOnScroll>
        <TopBar className={className}>
            <Container>
                {Children.count(children) === 0 ? (
                    <Title as="h1" id="react-admin-title" />
                ) : (
                    children
                )}
            </Container>
            <TopBarActions>
                {quickAddActions ?
                    <QuickAddButton className="p-1">
                        {quickAddActions}
                    </QuickAddButton>
                    : null}
                <VR />
                <RefreshButton className="p-1" />
                {/* {(userMenu === undefined || userMenu === true) ? cloneElement(<DefaultUserMenu />, { logout }) :
                    userMenu === false
                        ? null
                        : cloneElement(userMenu, { logout })} */}
            </TopBarActions>
        </TopBar >
        // </HideOnScroll>
    );
};

const MemoAppBar = memo(AppBar)
export { MemoAppBar as AppBar }