import React from 'react';
import { FC } from 'react';
import classNames from 'classnames';
import { Link as RRLink, LinkProps as RRLinkProps } from 'react-router-dom';

export interface LinkProps extends RRLinkProps {
    className?: string;
}

const Link: FC<LinkProps> = props => {
    const {
        to,
        children,
        className,
        ...rest
    } = props;
    return (
        <RRLink
            to={to}
            className={classNames(className)}
            {...rest}
        >
            {children}
        </RRLink>
    );
};

export default Link;