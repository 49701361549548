import * as React from 'react';
import { ReactElement } from 'react';
import {
    CreateContextProvider,
    ResourceContextProvider,
    useCheckMinimumRequiredProps,
    useCreateController,
} from 'ra-core';
import { CreateProps as ControllerCreateProps } from 'ra-core/esm/controller/details/useCreateController';
import { ShowProps } from './Show';
import { CreateView } from './CreateView';

/**
 * Page component for the Create view
 *
 * The `<Create>` component renders the page title and actions.
 * It is not responsible for rendering the actual form -
 * that's the job of its child component (usually `<SimpleForm>`),
 * to which it passes the `record` as prop.
 *
 * The <Create> component accepts the following props:
 *
 * - actions
 * - aside
 * - component
 * - successMessage
 * - title
 * 
 */
export const Create = (
    props: CreateProps & { children: ReactElement }
): ReactElement => {
    useCheckMinimumRequiredProps('Create', ['children'], props);
    const controllerProps = useCreateController(props);
    const body = (
        <CreateContextProvider value={controllerProps}>
            <CreateView {...props} {...controllerProps} />
        </CreateContextProvider>
    );
    return props.resource ? (
        // support resource override via props
        <ResourceContextProvider value={props.resource}>
            {body}
        </ResourceContextProvider>
    ) : (
        body
    );

};
export interface CreateProps
    extends ControllerCreateProps,
    Omit<ShowProps, "actions"> {
    actions?: JSX.Element
}

