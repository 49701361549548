import * as React from 'react';
import { FC, ReactElement, ReactNode, HtmlHTMLAttributes } from 'react';
import { FormWithRedirect, FormWithRedirectProps, MutationMode } from 'ra-core';
import { SimpleFormView } from './SimpleFormView';

/**
 * Form with a one column layout, one input per line.
 *
 * Pass input components as children.
 *
 * @example
 *
 * import * as React from "react";
 * import { Create, Edit, SimpleForm, TextInput, DateInput, ReferenceManyField, Datagrid, TextField, DateField, EditButton } from 'react-admin';
 * import RichTextInput from 'ra-input-rich-text';
 *
 * export const PostCreate = (props) => (
 *     <Create {...props}>
 *         <SimpleForm>
 *             <TextInput source="title" />
 *             <TextInput source="teaser" options={{ multiline: true }} />
 *             <RichTextInput source="body" />
 *             <DateInput label="Publication date" source="published_at" defaultValue={new Date()} />
 *         </SimpleForm>
 *     </Create>
 * );
 *
 * @typedef {Object} Props the props you can use (other props are injected by Create or Edit)
 * @prop {ReactElement[]} children Input elements
 * @prop {Object} initialValues
 * @prop {Function} validate
 * @prop {boolean} submitOnEnter
 * @prop {string} redirect
 * @prop {ReactElement} toolbar The element displayed at the bottom of the form, containing the SaveButton
 * @prop {string} variant Apply variant to all inputs. Possible values are 'standard', 'outlined', and 'filled' (default)
 * @prop {boolean} sanitizeEmptyValues Whether or not deleted record attributes should be recreated with a `null` value (default: true)
 *
 * @param {Props} props
 */
const SimpleForm: FC<SimpleFormProps> = props => (
    <FormWithRedirect
        {...props}
        render={formProps => <SimpleFormView {...formProps} />}
    />
);

export interface SimpleFormProps
    extends Omit<FormWithRedirectProps, 'render'>,
    Omit<
    HtmlHTMLAttributes<HTMLFormElement>,
    'defaultValue' | 'onSubmit' | 'children'
    > {
    basePath?: string;
    children: React.ReactNode;
    className?: string;
    component?: React.ComponentType<any>;
    mutationMode?: MutationMode;
    resource?: string;
    submitOnEnter?: boolean;
    toolbar?: ReactElement;
}

export default SimpleForm;