import * as React from 'react';
import { HtmlHTMLAttributes, ReactElement } from 'react';
import classnames from 'classnames';
import { Record } from 'ra-core';

const sanitizeRestProps = ({
    basePath,
    record,
    ...rest
}: {
    basePath?: string;
    record?: unknown;
}) => rest;


export const FormInput = <RecordType extends Record | Omit<Record, 'id'> = Record>(
    props: FormInputProps<RecordType>
) => {
    const { input, ...rest } = props;

    const { id, className, ...inputProps } = input
        ? input.props
        : { id: undefined, className: undefined };
    return input ? (
        <div
            className={classnames(
                'ra-input',
                `ra-input-${input.props.source}`,
                input.props.formClassName as any
            )}
        >
            {React.cloneElement(input, {
                id: input.props.id || input.props.source,
                ...rest,
                ...inputProps,
            })}
        </div>
    ) : null;
};

export interface FormInputProps<
    RecordType extends Record | Omit<Record, 'id'> = Record
    > extends HtmlHTMLAttributes<HTMLDivElement> {
    basePath: string;
    input: ReactElement<{
        label?: string;
        source?: string;
        id?: string;
        [key: string]: unknown;
    }>;
    margin?: 'none' | 'normal' | 'dense';
    record?: RecordType;
    resource?: string;
    variant?: 'standard' | 'outlined' | 'filled';
}

// What? TypeScript loses the displayName if we don't set it explicitly
FormInput.displayName = 'FormInput';

