import { AdminUI, AdminUIProps, LayoutProps, LoadingPage, Resource, useAuthState, useGetIdentity, usePermissions } from "react-admin";
import { Menu } from "./utils/Menu";

import { CompaniesList, CompanyCreate, CompanyEdit } from "./resources/Companies";
import { CashRegistersCreate, CashRegistersEdit, CashRegisterShow, CashRegistersList } from "./resources/CashRegisters";
import { RolesCreate, RolesEdit, RolesList, RolesShow } from "./resources/Roles";
import { UsersCreate, UsersEdit, UsersList, UsersShow } from "./resources/Users";
import { ProductsCreate, ProductsEdit, ProductsList } from "./resources/Products";
import { PersonsCreate, PersonsEdit, PersonsList } from "./resources/Persons";
import { ReceiptsCreate, ReceiptsEdit, RecieptsList } from "./resources/Receipts";

import React, { createContext, useMemo, useState } from "react";
import { Route } from "react-router-dom";
import { PrivilegeAssignment, ReceiptType, ReceiptsPrivileges, Can, CanOneOf, Privilege, Company, User } from "@faktio/jsclient";
import { AppBar } from "./ui/layout/AppBar";
import { Layout } from "./ui/layout/Layout";
import { createIcon } from "./ui/utils/Icon";
import { QuickAddActionsType, QuickAddItem } from "./ui/button/QuickAdd";
import { PluginID as ClickUpPluginID } from './plugins/clickup/plugin'
import { TimeEntriesList, TimeSheetList } from "./plugins/clickup/resource";
import ClickUpResources from "./plugins/clickup/resources";

const createReceiptRoutes = (path: string, type: ReceiptType, permissions: PrivilegeAssignment[]) => {
    const routes = []

    if (Can(permissions, ReceiptsPrivileges[type].Add)) routes.push(
        <Route exact
            path={`/${path}/create`}
            render={() =>
                <ReceiptsCreate
                    basePath={`/${path}`}
                    resource={`receipts/${type}`}
                    type={type}
                    hasCreate={Can(permissions, ReceiptsPrivileges[type].Add)}
                    hasShow={false}
                    hasEdit={Can(permissions, ReceiptsPrivileges[type].Modify)}
                />}
        />)

    if (Can(permissions, ReceiptsPrivileges[type].List)) routes.push(
        <Route exact
            path={`/${path}`}
            render={() =>
                <RecieptsList
                    syncWithLocation
                    basePath={`/${path}`}
                    resource={`receipts/${type}`}
                    type={type}
                    hasCreate={Can(permissions, ReceiptsPrivileges[type].Add)}
                    hasShow={false}
                    hasEdit={Can(permissions, ReceiptsPrivileges[type].Modify)}
                />}
        />)


    if (Can(permissions, ReceiptsPrivileges[type].Modify)) routes.push(
        <Route exact
            path={`/${path}/:id`}
            render={({ match: { params } }) =>
                <ReceiptsEdit
                    basePath={`/${path}`}
                    resource={`receipts/${type}`}
                    type={type}
                    id={params.id}
                    hasCreate={Can(permissions, ReceiptsPrivileges[type].Add)}
                    hasShow={false}
                    hasEdit={Can(permissions, ReceiptsPrivileges[type].Modify)}
                />}
        />)

    return routes
}

export const CompanyContext = createContext<{ company?: Company, companies?: Company[], setCompany?: (company: Company) => void }>({})

export const QuickActions: QuickAddActionsType = () => {
    const { permissions: rawPermissions } = usePermissions()
    const permissions = rawPermissions as boolean | PrivilegeAssignment[]

    let actions = []

    if (Can(permissions, Privilege.AddSalesInvoices))
        actions.push(<QuickAddItem to="/sales-invoices/create">Vydaná faktura</QuickAddItem>)

    if (Can(permissions, Privilege.AddPurchaseInvoices))
        actions.push(<QuickAddItem to="/purchase-invoices/create">Přijatá faktura</QuickAddItem>)

    if (Can(permissions, Privilege.AddSalesOrders))
        actions.push(<QuickAddItem to="/sales-orders/create">Cenová nabídka</QuickAddItem>)

    if (Can(permissions, Privilege.AddRetail))
        actions.push(<QuickAddItem to="/retail/create">Prodejka</QuickAddItem>)

    if (Can(permissions, Privilege.AddProducts))
        actions.push(<QuickAddItem to="/products/create">Produkt</QuickAddItem>)

    if (Can(permissions, Privilege.AddPersons))
        actions.push(<QuickAddItem to="/persons/create">Kontakt</QuickAddItem>)

    return actions
}

const AdminLayout = (props: LayoutProps) => <Layout {...props} menu={Menu} appBar={AppBar} quickAddActions={QuickActions} />;

const Admin: React.FC<AdminUIProps> = ({ customRoutes, ...props }) => {
    const { identity, loading: authLoading } = useGetIdentity()
    const user = useMemo(() => identity as (User | undefined), [identity])
    const permissions = user?.isSysAdm() || user?.privileges || []

    const [company, setCompany] = useState<Company>()

    // FIXME: Does not work on first login
    const routes = useMemo(() => [
        ...createReceiptRoutes("sales-invoices", ReceiptType.SalesInvoice, permissions as PrivilegeAssignment[]),
        ...createReceiptRoutes("purchase-invoices", ReceiptType.PurchaseInvoice, permissions as PrivilegeAssignment[]),
        ...createReceiptRoutes("sales-orders", ReceiptType.SalesOrder, permissions as PrivilegeAssignment[]),
        ...createReceiptRoutes("retail", ReceiptType.Retail, permissions as PrivilegeAssignment[]),
        ...createReceiptRoutes("credit-notes", ReceiptType.CreditNote, permissions as PrivilegeAssignment[]),
    ].filter(e => e !== null), [permissions])

    if (customRoutes) routes.push(...customRoutes)

    // @ts-ignore
    if (authLoading) return <LoadingPage />

    return (
        <CompanyContext.Provider value={{ company, setCompany }}>
            <AdminUI {...props} layout={AdminLayout} disableTelemetry customRoutes={routes}>

                {(permissions: PrivilegeAssignment[]) => [
                    <Resource
                        name="companies"
                        options={{ label: 'Společnosti', system: CanOneOf(permissions, Privilege.ModifyCompanies, Privilege.AddCompany) }}
                        // show={CompaniesShow}
                        icon={createIcon("work", "outlined")}
                        list={CompaniesList}
                        edit={Can(permissions, Privilege.ModifyCompanies) ? CompanyEdit : undefined}
                        create={Can(permissions, Privilege.AddCompany) ? CompanyCreate : undefined}
                    />,
                    <Resource
                        name="cashRegisters"
                        options={{ label: 'Pokladní zařízení', company: Can(permissions, Privilege.ListCashRegisters), category: "settings" }}
                        show={CashRegisterShow}
                        icon={createIcon("store", "outlined")}
                        list={CashRegistersList}
                        edit={CanOneOf(permissions, Privilege.ModifyCashRegisters) ? CashRegistersEdit : undefined}
                        create={CanOneOf(permissions, Privilege.AddCashRegisters) ? CashRegistersCreate : undefined}
                    />,
                    <Resource
                        name="products"
                        options={{ label: 'Produkty', company: Can(permissions, Privilege.ListProducts) }}
                        icon={createIcon("category", "outlined")}
                        // show={ProductsShow}
                        list={ProductsList}
                        edit={CanOneOf(permissions, Privilege.ModifyProducts) ? ProductsEdit : undefined}
                        create={CanOneOf(permissions, Privilege.AddProducts) ? ProductsCreate : undefined}
                    />,
                    <Resource
                        name="persons"
                        options={{ label: 'Adresář', company: Can(permissions, Privilege.ListPersons) }}
                        icon={createIcon("contacts", "outlined")}
                        list={PersonsList}
                        edit={CanOneOf(permissions, Privilege.ModifyUsers) ? PersonsEdit : undefined}
                        create={CanOneOf(permissions, Privilege.AddUsers) ? PersonsCreate : undefined}
                    />,
                    <Resource
                        name="roles"
                        icon={createIcon("group", "outlined")}
                        options={{ label: 'Role', system: Can(permissions, Privilege.ListSystemRoles), company: Can(permissions, Privilege.ListRoles), category: "settings" }}
                        show={RolesShow}
                        list={RolesList}
                        edit={CanOneOf(permissions, Privilege.ModifyRoles, Privilege.ModifySystemRoles) ? RolesEdit : undefined}
                        create={CanOneOf(permissions, Privilege.AddRoles, Privilege.AddSystemRoles) ? RolesCreate : undefined}
                    />,
                    <Resource
                        name="users"
                        options={{ label: 'Uživatelé', system: Can(permissions, Privilege.ListSystemUsers), company: Can(permissions, Privilege.ListUsers), category: "settings" }}
                        icon={createIcon("person", "outlined")}
                        show={UsersShow}
                        list={UsersList}
                        edit={CanOneOf(permissions, Privilege.ModifyUsers, Privilege.ModifySystemUsers) ? UsersEdit : undefined}
                        create={CanOneOf(permissions, Privilege.AddUsers, Privilege.AddSystemUsers) ? UsersCreate : undefined}
                    />,
                    ...Object.values(ReceiptType).map(type =>
                        <Resource name={`receipts/${type}`} />),
                    ...(user?.hasPluginEnabled(ClickUpPluginID) ? ClickUpResources : []),
                ]}
            </AdminUI>
        </CompanyContext.Provider>
    )
}

export default Admin