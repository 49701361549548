import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { DashboardMenuItem, getResources, usePermissions } from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
import { ListSubheader } from '@material-ui/core'
import { PrivilegeAssignment, Can, Privilege } from '@faktio/jsclient';
import { CompanyContext } from '../Admin';
import { MenuItemLink } from '../ui/MenuItemLink';
import { MenuItem } from '../ui/MenuItem';
import { createIcon, Icon } from '../ui/utils/Icon';
import { Submenu } from '../ui/Submenu';

export const Menu = ({ onMenuClick, logout }: { onMenuClick?: () => void, logout?: React.ReactNode }) => {
    const resources: { name: string, icon?: React.FC, options?: { label: string, system?: boolean, company?: boolean, category?: string } }[] = useSelector(getResources);

    const { permissions: rawPermissions } = usePermissions()
    const permissions = rawPermissions as boolean | PrivilegeAssignment[]

    const { company } = useContext(CompanyContext)
    const [expanded, setExpanded] = useState("")

    const systemLinks = resources.reduce<React.ReactNode[]>((links, resource) => resource.options?.system ? [...links,
    <MenuItemLink
        key={resource.name}
        to={{
            pathname: `/${resource.name}`,
            search: `filter=`,
        }}
        primaryText={
            (resource.options?.label) ||
            resource.name
        }
        icon={
            resource.icon ? <resource.icon /> : <DefaultIcon />
        }
    />
    ] : links, [])

    const salesLinks = resources.reduce<React.ReactNode[]>((links, resource) =>
        resource.options?.category === "sales" && resource.options?.company ? [...links,
        <MenuItemLink
            key={resource.name}
            to={{
                pathname: `/${resource.name}`,
                search: `filter=`,
            }}
            primaryText={
                (resource.options?.label) ||
                resource.name
            }
            icon={
                resource.icon ? <resource.icon /> : <DefaultIcon />
            }
        />
        ] : links,
        [
            company && Can(permissions, Privilege.ListSalesInvoices) ? <MenuItemLink
                key={"receipts-sales-invoice"}
                to="/sales-invoices"
                primaryText={"Vydané faktury"}
                icon={<DefaultIcon />}
            /> : null,
            company && Can(permissions, Privilege.ListCreditNotes) ? <MenuItemLink
                key={"credit-notes"}
                to="/credit-notes"
                primaryText={company.isVATPayer() ? "Dobropisy" : "Storno faktury"}
                icon={<Icon name="receipt" variant="outlined" />}
            /> : null,
            company && Can(permissions, Privilege.ListRetail) ? <MenuItemLink
                key={"receipts-retail"}
                to="/retail"
                primaryText={"Prodejky"}
                icon={<Icon name="receipt_long" variant="outlined" />}
            /> : null,
            company && Can(permissions, Privilege.ListSalesOrders) ? <MenuItemLink
                key={"sales-orders"}
                to="/sales-orders"
                primaryText={"Cenové nabídky"}
                icon={<Icon name="receipt" variant="outlined" />}
            /> : null
        ].filter(value => value !== null)
    )

    const purchasesLinks = resources.reduce<React.ReactNode[]>((links, resource) =>
        resource.options?.category === "purchases" && resource.options?.company ? [...links,
        <MenuItemLink
            key={resource.name}
            to={{
                pathname: `/${resource.name}`,
                search: `filter=`,
            }}
            primaryText={
                (resource.options?.label) ||
                resource.name
            }
            icon={
                resource.icon ? <resource.icon /> : <DefaultIcon />
            }
        />
        ] : links,
        (company && Can(permissions, Privilege.ListPurchaseInvoices) ? [<MenuItemLink
            key={"receipts-purchase-invoice"}
            to="/purchase-invoces"
            primaryText={"Přijaté faktury"}
            icon={<DefaultIcon />}
        />] : [])
    )

    const settingsLinks = resources.reduce<React.ReactNode[]>((links, resource) =>
        resource.options?.category === "settings" && resource.options?.company ? [...links,
        <MenuItemLink
            key={resource.name}
            to={{
                pathname: `/${resource.name}`,
                search: `filter=`,
            }}
            primaryText={
                (resource.options?.label) ||
                resource.name
            }
            icon={
                resource.icon ? <resource.icon /> : <DefaultIcon />
            }
        />
        ] : links,
        (company && Can(permissions, Privilege.ModifyCompanies) ? [<MenuItemLink
            key={"show-company"}
            to={`/companies/${company.getID()}`}
            primaryText={"Firma"}
            icon={<Icon name="store" />}
        />] : [])
    )

    const clickUpLinks = resources.reduce<React.ReactNode[]>((links, resource) =>
        resource.options?.category === "clickUp" && resource.options?.company ? [...links,
        <MenuItemLink
            key={resource.name}
            to={{
                pathname: `/${resource.name}`,
            }}
            primaryText={
                (resource.options?.label) ||
                resource.name
            }
            icon={
                resource.icon ? <resource.icon /> : <DefaultIcon />
            }
        />
        ] : links,
        []
    )

    return (
        <div>
            {/* <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} /> */}
            {salesLinks.length > 0 ?
                <Submenu icon={<Icon name="file_upload" variant="outlined" />} title="Prodej"
                    isExpanded={expanded === "sales"} setIsExpanded={(expanded) => setExpanded(expanded ? "sales" : "")}>
                    {salesLinks}
                </Submenu>
                : null}
            {purchasesLinks.length > 0 ?
                <Submenu icon={<Icon name="file_download" variant="outlined" />} title="Nákup"
                    isExpanded={expanded === "purchases"} setIsExpanded={(expanded) => setExpanded(expanded ? "purchases" : "")}>
                    {purchasesLinks}
                </Submenu>
                : null}
            {company ? resources.map(resource => resource.options?.company && !resource.options?.category && (
                <MenuItemLink
                    key={resource.name}
                    to={{
                        pathname: `/${resource.name}`,
                        //search: `filter=${JSON.stringify({ companyId: company.getID() })}`,
                    }}
                    primaryText={
                        (resource.options?.label) ||
                        resource.name
                    }
                    icon={
                        resource.icon ? <resource.icon /> : <DefaultIcon />
                    }
                />
            )) : null}
            {settingsLinks.length > 0 ?
                <Submenu icon={<Icon name="settings" variant="outlined" />} title="Nastavení"
                    isExpanded={expanded === "settings"} setIsExpanded={(expanded) => setExpanded(expanded ? "settings" : "")}>
                    {settingsLinks}
                </Submenu>
                : null}

            {clickUpLinks.length > 0 ?
                <Submenu icon={<Icon name="timer" variant="outlined" />} title="ClickUp"
                    isExpanded={expanded === "clickup"} setIsExpanded={(expanded) => setExpanded(expanded ? "clickup" : "")}>
                    {clickUpLinks}
                </Submenu>
                : null}

            {systemLinks.length > 0 ? <>
                <MenuItem.Title>
                    Systémové menu
                </MenuItem.Title>
                <Submenu icon={<Icon name="tune" variant="outlined" />} title="Předvolby"
                    isExpanded={expanded === "system-settings"} setIsExpanded={(expanded) => setExpanded(expanded ? "system-settings" : "")}>
                    {systemLinks}
                </Submenu>
            </> : null}
        </div>
    );
};