import React, { useMemo } from "react"
import { Labeled, LinearProgress, linkToRecord, useGetOne } from "react-admin"
import ErrorIcon from '@material-ui/icons/Error';
import { Receipt } from "@faktio/jsclient";
import { Price } from '@marekl/prices';
import Link from "../ui/Link";
import get from "lodash/get";
import { Span } from "../ui/utils/Inlines";
import moment from "moment";
import { TextFieldProps } from "../ui/field/TextField";

const CompanyFieldDisplay: React.FC<{ companyId: number }> = ({ companyId }) => {
    const { data, loading, error } = useGetOne("companies", companyId)

    return <>
        {(!data || loading) ? <LinearProgress /> :
            (error ? <ErrorIcon
                aria-errormessage={error.message ? error.message : error}
                role="presentation"
                color="error"
                fontSize="small"
            /> :
                <>{data?.contact?.businessName}</>
            )}
    </>
}

export const CompanyField: React.FC<TextFieldProps> = ({ record, source, ...rest }) => {
    const companyId = record && source && record[source]

    if (!companyId) {
        return <>SYSTEM</>
    }

    return <CompanyFieldDisplay companyId={companyId} />
}

export const LabeledCompanyField: React.FC<TextFieldProps> = ({ record, source, ...rest }) => {
    const companyId = record && source && record[source]

    if (!companyId) {
        return <Labeled label={rest.label || "Company"}><>SYSTEM</></Labeled>
    }

    return <Labeled label={rest.label || "Company"}><CompanyFieldDisplay companyId={companyId} /></Labeled>
}

// TODO: Currencies
export const ReceiptTotalInclVAT: React.FC<TextFieldProps> = ({ record, source, ...rest }) => {
    if (!(record instanceof Receipt)) return null

    return <>{record.getTotal().getTotalInclVAT().format()}</>
}

export const PriceInclVATField: React.FC<TextFieldProps> = ({ record, source, ...rest }) => {
    const value = (source && record) ? get(record, source) : null

    if (!(value instanceof Price)) return null

    return <>{value.getInclVAT().format()}</>
}

export const VATField: React.FC<TextFieldProps> = ({ record, source, ...rest }) => {
    const value = (source && record) ? get(record, source) : null

    if (!Number.isInteger(value)) return null

    return <>{value} %</>
}

// TODO: Labeled should not be neccesary
export const LabeledReceiptTotalInclVAT: React.FC<TextFieldProps> = ({ record, source, ...rest }) => {
    if (!(record instanceof Receipt)) return null

    return <Labeled label={rest.label || "Celkem vč. DPH"}><>{record.getTotal().getTotalInclVAT().format()}</></Labeled>
}

export const LinkField: React.FC<TextFieldProps & {
    target: "edit" | "show"
}> = ({ target, record, source, basePath, emptyText, ...rest }) => {
    return (<Link
        to={useMemo(
            () => ({
                pathname: record
                    ? (target === "edit" ?
                        linkToRecord(basePath, record.id) :
                        `${linkToRecord(basePath, record.id)}/show`)
                    : '',
            }),
            [basePath, record, target]
        )}
        onClick={(e) => e.stopPropagation()}
        {...rest as any}
    >
        {(record && source) ? get(record, source) : emptyText}
    </Link>)
}

export const DueDateField: React.FC<TextFieldProps> = ({ record, source, basePath, emptyText }) => {
    if (!(record instanceof Receipt)) return <Span>{emptyText}</Span>

    const today = moment().startOf("day")
    const dueDate = (record as Receipt).dueDate
    const daysToDue = dueDate !== null ? dueDate.diff(today) : null;
    const diffString = dueDate !== null ? dueDate.from(today) : "";

    if (daysToDue === null) {
        return <Span>{emptyText}</Span>
    } else if (daysToDue >= 5) {
        return (<>
            <Span className="text-warning font-weight-bolder with-cell-info">
                {dueDate?.format("DD. MM. YYYY")}
            </Span>
            <Span className="cell-info text-warning">
                {diffString}
            </Span>
        </>)
    } else if (daysToDue <= 0) {
        return (<>
            <Span className="text-danger font-weight-bolder with-cell-info">
                {dueDate?.format("DD. MM. YYYY")}
            </Span>
            <Span className="cell-info text-danger">
                {diffString}
            </Span>
        </>)
    } else {
        return <Span>{dueDate?.format("DD. MM. YYYY")}</Span>
    }
}

export const DateField: React.FC<TextFieldProps> = ({ record, source, emptyText }) => {
    const value = (source && record) ? get(record, source) : null

    if (!moment.isMoment(value)) {
        return <Span>{emptyText}</Span>
    }

    return <Span>{value.format("DD. MM. YYYY")}</Span>
}

