import classnames from "classnames";
import React from "react";
import { ButtonBase } from "../../button/ButtonBase";
import { Icon } from "../../utils/Icon";

/**
 * A button based label for placing inside `TableCell` for column sorting.
 */
export const TableSortLabel = (props: TableSortLabelProps) => {
    const {
        active = false,
        children,
        className,
        direction = 'asc',
        hideSortIcon = true,
        onClick,
        ...other
    } = props;

    return (
        <ButtonBase
            component="span"
            onClick={onClick}
            {...other}
        >
            {children}
            {hideSortIcon && !active ? null : (
                <Icon
                    name={direction === "asc" ? "north" : "south"}
                    stroke
                    className={classnames("ml-2")}
                />
            )}
        </ButtonBase>
    );
}

export interface TableSortLabelProps {
    className?: string;
    active?: boolean;
    children?: React.ReactNode;
    direction?: 'asc' | 'desc';
    hideSortIcon?: boolean;
    onClick: (event: React.MouseEvent) => void
}