import * as React from 'react';
import { FC, useCallback, MouseEventHandler } from 'react';
import Dialog from '@material-ui/core/Dialog';
import classnames from 'classnames';
import { useTranslate } from 'ra-core';
import Modal from 'react-bootstrap/esm/Modal';
import Button from '../button/Button';


/**
 * Confirmation dialog
 *
 * @example
 * <Confirm
 *     isOpen={true}
 *     title="Delete Item"
 *     content="Are you sure you want to delete this item?"
 *     confirm="Yes"
 *     confirmColor="primary"
 *     ConfirmIcon=ActionCheck
 *     CancelIcon=AlertError
 *     cancel="Cancel"
 *     onConfirm={() => { // do something }}
 *     onClose={() => { // do something }}
 * />
 */
export const Confirm: FC<ConfirmProps> = props => {
    const {
        isOpen,
        loading,
        title,
        content,
        confirm,
        cancel,
        confirmColor,
        ConfirmIcon,
        CancelIcon,
        onClose,
        onConfirm,
        translateOptions = {},
    } = props;
    const translate = useTranslate();

    const handleConfirm = useCallback(
        e => {
            e.stopPropagation();
            onConfirm(e);
        },
        [onConfirm]
    );

    return (
        <Modal
            show={isOpen}
            onHide={onClose}
            tabindex="-1"
            role="dialog"
            aria-hidden={isOpen ? "false" : true}
            aria-labelledby="alert-dialog-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="alert-dialog-title">
                    {translate(title, { _: title, ...translateOptions })}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {translate(content, {
                    _: content,
                    ...translateOptions,
                })}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="primary"
                    onClick={onClose}
                    disabled={loading}
                    icon={CancelIcon}
                    label={translate(cancel || "", { _: cancel })} />
                <Button
                    variant={confirmColor}
                    onClick={handleConfirm}
                    disabled={loading}
                    icon={ConfirmIcon}
                    label={translate(confirm || "", { _: confirm })} />
            </Modal.Footer>
        </Modal>
    );
};

Confirm.defaultProps = {
    cancel: 'ra.action.cancel',
    classes: {},
    confirm: 'ra.action.confirm',
    confirmColor: 'primary',
    ConfirmIcon: "check",
    isOpen: false,
};

export interface ConfirmProps {
    cancel?: string;
    classes?: object;
    confirm?: string;
    confirmColor?: string;
    ConfirmIcon?: string | JSX.Element;
    CancelIcon?: string | JSX.Element;
    content: string;
    isOpen?: boolean;
    loading?: boolean;
    onClose: MouseEventHandler;
    onConfirm: MouseEventHandler;
    title: string;
    translateOptions?: object;
}
