import React, {
    isValidElement,
    useCallback,
    memo,
    FC,
    useState,
} from 'react';
import {
    linkToRecord,
    useExpanded,
    Identifier,
    Record,
    useResourceContext,
    RecordContextProvider,
} from 'ra-core';
import { shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DatagridCell from './DatagridCell';
import { TableCell } from '../../utils/Tables';
import Form from 'react-bootstrap/esm/Form';
import { useId } from 'react-id-generator';
import classNames from 'classnames';

// const computeNbColumns = (expand, children, hasBulkActions) =>
//     expand
//         ? 1 + // show expand button
//         (hasBulkActions ? 1 : 0) + // checkbox column
//         React.Children.toArray(children).filter(child => !!child).length // non-null children
//         : 0; // we don't need to compute columns if there is no expand panel;

const defaultClasses = { expandIconCell: '', checkbox: '', rowCell: '' };

const DatagridRow = React.forwardRef<HTMLTableRowElement, DatagridRowProps>((props, ref) => {
    const {
        basePath,
        children,
        className,
        // expand,
        hasBulkActions,
        hover,
        id,
        onToggleItem,
        record,
        rowClick,
        selected,
        style,
        selectable,
        ...rest
    } = props;

    // const context = useDatagridContext();
    // const expandable =
    //     (!context ||
    //         !context.isRowExpandable ||
    //         context.isRowExpandable(record)) &&
    //     expand;
    const resource = useResourceContext(props);
    // const [expanded, toggleExpanded] = useExpanded(resource, id);
    // const [nbColumns, setNbColumns] = useState(
    //     computeNbColumns(expandable, children, hasBulkActions)
    // );
    // useEffect(() => {
    //     // Fields can be hidden dynamically based on permissions;
    //     // The expand panel must span over the remaining columns
    //     // So we must recompute the number of columns to span on
    //     const newNbColumns = computeNbColumns(
    //         expandable,
    //         children,
    //         hasBulkActions
    //     );
    //     if (newNbColumns !== nbColumns) {
    //         setNbColumns(newNbColumns);
    //     }
    // }, [expandable, nbColumns, children, hasBulkActions]);

    const history = useHistory();
    const [htmlId] = useId();

    // const handleToggleExpand = useCallback(
    //     event => {
    //         toggleExpanded();
    //         event.stopPropagation();
    //     },
    //     [toggleExpanded]
    // );

    const handleToggleSelection = useCallback(
        event => {
            if (!selectable || !id) return;
            onToggleItem && onToggleItem(id, event);
            event.stopPropagation();
        },
        [id, onToggleItem, selectable]
    );
    const handleClick = useCallback(
        async event => {
            if (!rowClick || !id || !record) return;
            event.persist();

            const effect =
                typeof rowClick === 'function'
                    ? await rowClick(id, basePath || `/${resource}`, record)
                    : rowClick;
            switch (effect) {
                case 'edit':
                    history.push(linkToRecord(basePath || `/${resource}`, id));
                    return;
                case 'show':
                    history.push(
                        linkToRecord(basePath || `/${resource}`, id, 'show')
                    );
                    return;
                // case 'expand':
                //     handleToggleExpand(event);
                //     return;
                case 'toggleSelection':
                    handleToggleSelection(event);
                    return;
                default:
                    if (effect) {
                        if (effect.startsWith("http"))
                            window.open(effect, '_blank')
                        else
                            history.push(effect)
                    };
                    return;
            }
        },
        [
            basePath,
            history,
            // handleToggleExpand,
            handleToggleSelection,
            id,
            record,
            resource,
            rowClick,
        ]
    );

    const [isHovered, setIsHovered] = useState(false)

    return (
        <RecordContextProvider value={record}>
            <tr
                ref={ref}
                className={classNames(className, isHovered && "active")}
                key={id}
                style={style}
                onClick={handleClick}
                onMouseOver={() => setIsHovered(true)}
                onMouseOut={() => setIsHovered(false)}
                {...rest}
            >
                {/* {expand && (
                    <TableCell
                        padding="none"
                        className={classes.expandIconCell}
                    >
                        {expandable && (
                            <ExpandRowButton
                                classes={classes}
                                expanded={expanded}
                                onClick={handleToggleExpand}
                                expandContentId={`${id}-expand`}
                            />
                        )}
                    </TableCell>
                )} */}
                {hasBulkActions && (
                    <td onClick={(event) => event.stopPropagation()}>
                        {selectable && (
                            <Form.Check
                                custom
                                checked={selected}
                                onChange={handleToggleSelection}
                                id={htmlId + `-table-row-` + id}
                            />
                        )}
                    </td>
                )}
                {React.Children.map(children, (field, index) =>
                    isValidElement(field) ? (
                        <DatagridCell
                            key={`${id}-${(field.props as any).source || index
                                }`}
                            // className={classnames(
                            //     `column-${(field.props as any).source}`,
                            //     classes.rowCell
                            // )}
                            record={record}
                            {...{ field, basePath, resource }}
                        />
                    ) : null
                )}
            </tr>
            {/* {expandable && expanded && (
                <TableRow key={`${id}-expand`} id={`${id}-expand`}>
                    <TableCell colSpan={nbColumns}>
                        {isValidElement(expand)
                            ? cloneElement(expand, {
                                // @ts-ignore
                                record,
                                basePath,
                                resource,
                                id: String(id),
                            })
                            : createElement(expand, {
                                record,
                                basePath,
                                resource,
                                id: String(id),
                            })}
                    </TableCell>
                </TableRow>
            )} */}
        </RecordContextProvider>
    );
});

DatagridRow.defaultProps = {
    hasBulkActions: false,
    hover: true,
    selected: false,
    selectable: true,
};

export interface DatagridRowProps
    extends Omit<React.HTMLProps<HTMLTableRowElement>, 'id'> {
    basePath?: string;
    className?: string;
    // expand?:
    // | ReactElement
    // | FC<{
    //     basePath: string;
    //     id: Identifier;
    //     record: Record;
    //     resource: string;
    // }>;
    hasBulkActions?: boolean;
    hover?: boolean;
    id?: Identifier;
    onToggleItem?: (
        id: Identifier,
        event: React.TouchEvent | React.MouseEvent
    ) => void;
    record?: Record;
    resource?: string;
    rowClick?: RowClickFunction | string;
    selected?: boolean;
    style?: any;
    selectable?: boolean;
}

export type RowClickFunction = (
    id: Identifier,
    basePath: string,
    record: Record
) => string | Promise<string>;

const areEqual = (prevProps: DatagridRowProps, nextProps: DatagridRowProps) => {
    const { children: _1, /* expand: _2,*/ ...prevPropsWithoutChildren } = prevProps;
    const { children: _3, /* expand: _4, */ ...nextPropsWithoutChildren } = nextProps;
    return shallowEqual(prevPropsWithoutChildren, nextPropsWithoutChildren);
};

export const PureDatagridRow = memo(DatagridRow, areEqual);

PureDatagridRow.displayName = 'PureDatagridRow';

export default DatagridRow;