import React, { memo } from 'react';
import { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Record } from 'ra-core';
import Button, { ButtonProps } from './Button';
import classnames from "classnames";

/**
 * Opens the Create view of a given resource
 *
 * Renders as a regular button on desktop, and a Floating Action Button
 * on mobile.
 *
 * @example // basic usage
 * import { CreateButton } from 'react-admin';
 *
 * const CommentCreateButton = () => (
 *     <CreateButton basePath="/comments" label="Create comment" />
 * );
 */
const CreateButton: FC<CreateButtonProps> = ({
    basePath = '',
    label = 'ra.action.create',
    record,
    scrollToTop = true,
    className,
    ...rest
}) => (
    <Button
        as={Link}
        to={useMemo(
            () => ({
                pathname: `${basePath}/create`,
                state: { _scrollToTop: scrollToTop },
            }),
            [basePath, scrollToTop]
        )}
        label={label}
        onClick={stopPropagation}
        className={classnames("text-lowercase", className)}
        {...(rest as any)}
    />
);

CreateButton.defaultProps = {
    icon: "add",
    variant: "outline-primary"
}

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = (e: any) => e.stopPropagation();

interface Props {
    basePath?: string;
    record?: Record;
    scrollToTop?: boolean;
}

export type CreateButtonProps = Props & ButtonProps

const PureCreateButton = memo(CreateButton, (prevProps, nextProps) => {
    return (
        prevProps.basePath === nextProps.basePath &&
        prevProps.label === nextProps.label &&
        prevProps.translate === nextProps.translate &&
        prevProps.to === nextProps.to &&
        prevProps.disabled === nextProps.disabled
    );
});

export { PureCreateButton as CreateButton }