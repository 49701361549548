import * as React from 'react';
import {
    useCallback,
    ReactNode,
    HtmlHTMLAttributes,
} from 'react';
import lodashGet from 'lodash/get';
import { useListContext, useResourceContext } from 'ra-core';

import Button from '../../button/Button';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import { FilterButtonMenuItem } from './FilterButtonMenuItem';
import { useId } from 'react-id-generator';

const FilterButton: React.FC<FilterButtonProps> = (props) => {
    const {
        filters,
        className,
        ...rest
    } = props;
    const resource = useResourceContext(props);

    const {
        displayedFilters = {},
        filterValues,
        showFilter
    } = useListContext(props)

    const hiddenFilters = (filters as JSX.Element[] | undefined)?.filter(
        (filterElement: JSX.Element) =>
            !filterElement.props.alwaysOn &&
            !displayedFilters[filterElement.props.source] &&
            typeof lodashGet(filterValues, filterElement.props.source) ===
            'undefined'
    ) || [];

    const handleShow = useCallback(
        ({ source, defaultValue }) => {
            showFilter(source, defaultValue);
        },
        [showFilter]
    );

    const [id] = useId()

    if (hiddenFilters.length === 0) return null;
    return (
        <Dropdown className={className} style={{ display: "inline-block" }}>
            <Dropdown.Toggle as={Button} id={id} icon="filter_list" size="sm" variant="dark">
                filters
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {hiddenFilters.map((filterElement: JSX.Element) => (
                    <FilterButtonMenuItem
                        key={filterElement.props.source}
                        filter={filterElement}
                        resource={resource}
                        onShow={handleShow}
                    />
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export interface FilterButtonProps extends HtmlHTMLAttributes<HTMLDivElement> {
    className?: string;
    resource?: string;
    filterValues?: any;
    showFilter?: (filterName: string, defaultValue: any) => void;
    displayedFilters?: any;
    filters?: ReactNode[];
}

export default FilterButton;