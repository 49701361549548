import * as React from 'react';
import {
    Children,
    cloneElement,
    isValidElement,
    useRef,
    ReactElement,
    FC,
} from 'react';
import { useTranslate, ValidationError, Record } from 'ra-core';
import classnames from 'classnames';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import { Row, Table } from 'react-bootstrap';
import { createComponent } from '../ui/tools/createComponent';
import Button, { ButtonProps } from '../ui/button/Button';
import { Icon } from '../ui/utils/Icon';
import { FormInput } from 'react-admin';
import { RowFormIteratorProps } from '../ui/form/RowFormIterator';
import { TableBody, TableCell, TableHead, TableRow } from '../ui/utils/Tables';
import DatagridHeaderCell from '../ui/list/datagrid/DatagridHeaderCell';

const AddButton = ({ className, ...props }: ButtonProps) => {
    return (
        <Button icon="add" label="add row" size="sm" variant="outline-primary" className={classnames(className, "mt-4", "mb-4")} {...props} />
    );
};

const RemoveButton = ({ className, ...props }: ButtonProps) => {
    return (
        <Button size="sm" variant="outline-primary" className={classnames("btn-icon", "h3", "lh-1", "m-0", 'border-1', className)} {...props}>
            <Icon name="close" variant="outlined" />
        </Button>
    );
};

const FormRow = createComponent("div", "d-flex flex-row flex-nowrap align-items-center justify-content-center")

export type ReceiptItemIteratorProps = Omit<RowFormIteratorProps, "addButton" | "removeButton">
type DisableRemoveFunction = (record: Record) => boolean;

// TODO: tbody to TableBody
export const ReceiptItemsIterator: FC<ReceiptItemIteratorProps> = props => {
    const {
        basePath,
        children,
        className,
        fields,
        meta,
        record,
        resource,
        source,
        disabled,
        disableAdd,
        disableRemove,
        defaultValue,
    } = props;

    const addButton = <AddButton />
    const removeButton = <RemoveButton />

    // We need a unique id for each field for a proper enter/exit animation
    // so we keep an internal map between the field position and an auto-increment id
    const nextId = useRef(
        fields && fields.length
            ? fields.length
            : defaultValue
                ? defaultValue.length
                : 0
    );

    // We check whether we have a defaultValue (which must be an array) before checking
    // the fields prop which will always be empty for a new record.
    // Without it, our ids wouldn't match the default value and we would get key warnings
    // on the CssTransition element inside our render method
    const ids = useRef(
        nextId.current > 0 ? Array.from(Array(nextId.current).keys()) : []
    );

    if (!resource || !fields || !meta) return null;

    const removeField = (index: number) => () => {
        ids.current.splice(index, 1);
        fields.remove(index);
    };

    // Returns a boolean to indicate whether to disable the remove button for certain fields.
    // If disableRemove is a function, then call the function with the current record to
    // determining if the button should be disabled. Otherwise, use a boolean property that
    // enables or disables the button for all of the fields.
    const disableRemoveField = (record: Record, disableRemove?: boolean | DisableRemoveFunction) => {
        if (typeof disableRemove === 'boolean') {
            return disableRemove;
        }
        return disableRemove && disableRemove(record);
    };

    const addField = () => {
        ids.current.push(nextId.current++);
        fields.push(undefined);
    };

    // add field and call the onClick event of the button passed as addButton prop
    const handleAddButtonClick = (
        originalOnClickHandler: (event: React.MouseEvent<HTMLElement>) => void
    ) => (event: React.MouseEvent<HTMLElement>) => {
        addField();
        if (originalOnClickHandler) {
            originalOnClickHandler(event);
        }
    };

    // remove field and call the onClick event of the button passed as removeButton prop
    const handleRemoveButtonClick = (
        originalOnClickHandler: (event: React.MouseEvent<HTMLElement>) => void,
        index: number
    ) => (event: React.MouseEvent<HTMLElement>) => {
        removeField(index)();
        if (originalOnClickHandler) {
            originalOnClickHandler(event);
        }
    };


    const records = record && source && record[source]
    return fields ? (<div className="mt-4">
        {meta.submitFailed && typeof meta.error !== 'object' && meta.error && (
            // <FormHelperText error>
            <ValidationError error={meta.error as string} />
            // </FormHelperText>
        )}
        <Table className={classnames("bg-card-table", className)}>
            <TableHead>
                <TableRow>
                    {Children.map(children, (input, index) =>
                        isValidElement(input) ? (
                            <DatagridHeaderCell
                                currentSort={{ field: "", order: "" }}
                                className='border-0'
                                field={input}
                                resource={resource || ""}
                            />
                        ) : null
                    )}
                    {!disableRemove && !disabled && (
                        <th />
                    )}
                </TableRow>
            </TableHead>
            <tbody className={classnames("datagrid-body", "mt-3", className)}>
                {fields.map((member, index) => (
                    <TableRow>
                        {Children.map(
                            children,
                            (input, index2) => {
                                if (!isValidElement<any>(input)) {
                                    return null;
                                }
                                const {
                                    source,
                                    ...inputProps
                                } = input.props;
                                return (
                                    <TableCell className="border-0">
                                        <FormInput
                                            basePath={
                                                input.props.basePath ||
                                                basePath
                                            }
                                            input={cloneElement(input, {
                                                source: source
                                                    ? `${member}.${source}`
                                                    : member,
                                                index: source
                                                    ? undefined
                                                    : index2,
                                                disabled,
                                                ...inputProps,
                                                label: false,
                                                formGroupClassName: "m-0"
                                            })}
                                            record={
                                                (records &&
                                                    records[index]) ||
                                                {}
                                            }
                                            resource={resource}
                                        />
                                    </TableCell>
                                );
                            }
                        )}
                        {!disabled && !disableRemove && (
                            <TableCell className="border-0">
                                {!disableRemoveField(
                                    (records && records[index]) || {},
                                    disableRemove
                                ) && cloneElement(removeButton, {
                                    onClick: handleRemoveButtonClick(
                                        removeButton.props.onClick,
                                        index
                                    ),
                                    className: classnames(
                                        'button-remove',
                                        `button-remove-${source}-${index}`,
                                        'mt-2'
                                    ),
                                })
                                }
                            </TableCell>
                        )}
                    </TableRow>
                ))}
                {!disabled && !disableAdd &&
                    cloneElement(addButton, {
                        onClick: handleAddButtonClick(
                            addButton.props.onClick
                        ),
                        className: classnames(
                            'button-add',
                            `button-add-${source}`
                        ),
                    })
                }
            </tbody>
        </Table>
    </div>) : null;
};

ReceiptItemsIterator.defaultProps = {
    disableAdd: false,
    disableRemove: false,
};

