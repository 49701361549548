import React, {
    Component,
    createElement,
    ErrorInfo,
    ReactElement,
    ComponentType,
    HtmlHTMLAttributes,
    useRef,
    useState,
    useEffect
} from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { CoreLayoutProps } from 'ra-core';
import compose from 'lodash/flowRight';

import {
    createMuiTheme
} from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import { AppBar as DefaultAppBar, AppBarProps } from './AppBar';
import { Sidebar } from './Sidebar';

import { History } from 'history'
import { Error, Menu, MenuProps, Notification } from 'react-admin';
import { Loader } from '../utils/Loader';
import { QuickAddActionsType, QuickAddItemType } from '../button/QuickAdd';

export interface LayoutProps
    extends CoreLayoutProps,
    Omit<HtmlHTMLAttributes<HTMLDivElement>, 'title'> {
    className?: string;
    error?: ComponentType<{
        error?: Error;
        errorInfo?: ErrorInfo;
        title?: string | ReactElement<any>;
    }>;
    appBar: ComponentType<AppBarProps>;
    quickAddActions?: QuickAddActionsType;
    menu: ComponentType<MenuProps>;
    notification: ComponentType;
    sidebar: ComponentType<{ children: JSX.Element }>;
}

interface ConnectedLayoutProps extends LayoutProps {
    history: History;
    open: boolean;
}

export interface LayoutState {
    hasError: boolean;
    error?: Error;
    errorInfo?: ErrorInfo;
}

class LayoutComponent extends Component<ConnectedLayoutProps, LayoutState> {
    state = { hasError: false, error: undefined, errorInfo: undefined };

    constructor(props: ConnectedLayoutProps) {
        super(props);
        /**
         * Reset the error state upon navigation
         *
         * @see https://stackoverflow.com/questions/48121750/browser-navigation-broken-by-use-of-react-error-boundaries
         */
        props.history.listen(() => {
            if (this.state.hasError) {
                this.setState({ hasError: false });
            }
        });
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({ hasError: true, error, errorInfo });
    }

    render() {
        const {
            appBar,
            quickAddActions,
            children,
            className,
            error: errorElement,
            dashboard,
            logout,
            menu,
            notification,
            open,
            sidebar,
            title
        } = this.props;
        const { hasError, error, errorInfo } = this.state;
        return (
            <>
                <div className={classnames('layout', 'has-loader', className)}>
                    <Loader />
                    {createElement(sidebar, {
                        //logout,
                        children: createElement(menu, {
                            logout,
                            hasDashboard: !!dashboard,
                        }),
                    })}
                    <div className="flex-grow-1 d-flex flex-column">
                        {createElement(appBar, { title, quickAddActions })}
                        <main className="main">
                            {hasError && errorElement
                                ? createElement(errorElement, {
                                    error: error,
                                    errorInfo: errorInfo,
                                    title,
                                })
                                : children}
                        </main>
                    </div>
                </div>
                {createElement(notification)}
            </>
        );
    }

    static defaultProps = {
        appBar: DefaultAppBar,
        error: Error,
        menu: Menu,
        notification: Notification,
        sidebar: Sidebar,
    };
}

const mapStateToProps = (state: any) => ({
    open: state.admin.ui.sidebarOpen,
});

export const ConnectedLayout = compose(
    connect(
        mapStateToProps,
        {} // Avoid connect passing dispatch in props
    ),
    withRouter,
)(LayoutComponent);

// TODO: Remove after all MUI components are replaced
export const Layout = ({
    theme: themeOverride,
    ...props
}: Partial<LayoutProps>): JSX.Element => {
    const themeProp = useRef(themeOverride);
    const [theme, setTheme] = useState(createMuiTheme(themeOverride));

    useEffect(() => {
        if (themeProp.current !== themeOverride) {
            themeProp.current = themeOverride;
            setTheme(createMuiTheme(themeOverride));
        }
    }, [themeOverride, themeProp, theme, setTheme]);

    return (
        <ThemeProvider theme={theme}>
            <ConnectedLayout {...props} />
        </ThemeProvider>
    );
};
