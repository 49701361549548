import { makeStyles } from '@material-ui/core/styles';
import React, { ReactElement } from "react";
import { DeleteButton } from '../ui/button/DeleteButton';
import { SaveButton } from '../ui/button/SaveButton';
import { Edit, EditProps } from "../ui/detaill/Edit";
import { Toolbar } from '../ui/form/Toolbar';


export const UndoableDeleteToolbar: typeof Toolbar = (props) => (
    <Toolbar className="d-flex align-items-center justify-content-between" {...props}>
        <SaveButton submitOnEnter={true} />
        <DeleteButton undoable={false} />
    </Toolbar>
)

export const PessimisticEdit: React.FC<EditProps & {
    children: ReactElement;
}> = (props) => (<Edit mutationMode="pessimistic" {...props}>{props.children}</Edit>)
