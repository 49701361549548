import * as React from 'react';
import {
    Children,
    isValidElement,
    ReactElement,
    FC,
    ReactNode,
} from 'react';
import { Record, RedirectionSideEffect, MutationMode } from 'ra-core';
import { FormRenderProps } from 'react-final-form';
import { SaveButton } from '../button/SaveButton';
import { DeleteButton } from '../button/DeleteButton';
import classnames from 'classnames';

const valueOrDefault = (value: any, defaultValue: any) =>
    typeof value === 'undefined' ? defaultValue : value;

/**
 * The Toolbar displayed at the bottom of forms.
 *
 * @example Always enable the <SaveButton />
 *
 * import * as React from 'react';
 * import {
 *     Create,
 *     DateInput,
 *     TextInput,
 *     SimpleForm,
 *     Toolbar,
 *     required,
 * } from 'react-admin';
 *
 * const now = new Date();
 * const defaultSort = { field: 'title', order: 'ASC' };
 *
 * const CommentCreate = props => (
 *     <Create {...props}>
 *         <SimpleForm redirect={false} toolbar={<Toolbar alwaysEnableSaveButton={true} />}>
 *             <TextInput
 *                 source="author.name"
 *                 fullWidth
 *             />
 *             <DateInput source="created_at" defaultValue={now} />
 *             <TextInput source="body" fullWidth={true} multiline={true} />
 *         </SimpleForm>
 *     </Create>
 * );
 *
 * @typedef {Object} Props the props you can use (other props are injected by the <SimpleForm>)
 * @prop {boolean} alwaysEnableSaveButton Force enabling the <SaveButton>. If it's not defined, the <SaveButton> will be enabled using the `pristine` prop (disabled if pristine, enabled otherwise).
 * @prop {ReactElement[]} children Customize the buttons you want to display in the <Toolbar>.
 * @prop {string} width Apply to the mobile or desktop classes depending on its value. Pass `xs` to display the mobile version.
 *
 */
export const Toolbar: FC<ToolbarProps> = props => {
    const {
        alwaysEnableSaveButton,
        basePath,
        children,
        className,
        handleSubmit,
        handleSubmitWithRedirect,
        invalid,
        pristine,
        record,
        redirect,
        resource,
        saving,
        submitOnEnter,
        undoable,
        mutationMode,
        width,
        ...rest
    } = props;

    // Use form pristine to enable or disable the save button
    // if alwaysEnableSaveButton is undefined
    const disabled = !valueOrDefault(alwaysEnableSaveButton, !pristine);

    return (
        <div
            className={className}
            role="toolbar"
            {...rest}
        >
            {Children.count(children) === 0 ? (
                <div className="d-flex align-items-center justify-content-between">
                    <SaveButton
                        handleSubmitWithRedirect={
                            handleSubmitWithRedirect || handleSubmit
                        }
                        disabled={disabled}
                        invalid={invalid}
                        redirect={redirect}
                        saving={saving}
                        submitOnEnter={submitOnEnter}
                    />
                    {record && typeof record.id !== 'undefined' && (
                        <DeleteButton
                            basePath={basePath}
                            record={record}
                            resource={resource}
                            undoable={undoable}
                            mutationMode={mutationMode}
                        />
                    )}
                </div>
            ) : (
                Children.map(children, (button) =>
                    button && isValidElement<any>(button)
                        ? React.cloneElement(button, {
                            basePath: valueOrDefault(
                                button.props.basePath,
                                basePath
                            ),
                            handleSubmit: valueOrDefault(
                                button.props.handleSubmit,
                                handleSubmit
                            ),
                            handleSubmitWithRedirect: valueOrDefault(
                                button.props.handleSubmitWithRedirect,
                                handleSubmitWithRedirect
                            ),
                            onSave: button.props.onSave,
                            invalid,
                            pristine,
                            record: valueOrDefault(
                                button.props.record,
                                record
                            ),
                            resource: valueOrDefault(
                                button.props.resource,
                                resource
                            ),
                            saving,
                            submitOnEnter: valueOrDefault(
                                button.props.submitOnEnter,
                                submitOnEnter
                            ),
                            undoable: valueOrDefault(
                                button.props.undoable,
                                undoable
                            ),
                        })
                        : null
                )
            )}
        </div>
    );
};

export interface ToolbarProps<RecordType extends Record = Record> {
    alwaysEnableSaveButton?: boolean;
    className?: string;
    handleSubmitWithRedirect?: (redirect?: RedirectionSideEffect) => void;
    handleSubmit?: FormRenderProps['handleSubmit'];
    invalid?: boolean;
    mutationMode?: MutationMode;
    pristine?: boolean;
    saving?: boolean;
    submitOnEnter?: boolean;
    redirect?: RedirectionSideEffect;
    basePath?: string;
    record?: RecordType;
    resource?: string;
    /** @deprecated use mutationMode: undoable instead */
    undoable?: boolean;
    width?: string;
}

Toolbar.defaultProps = {
    submitOnEnter: true,
};

