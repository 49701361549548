import React, { Children, cloneElement, memo } from 'react';
import { FC, useMemo } from 'react';
import { linkToRecord, useTranslate } from 'ra-core';
import classnames from "classnames";
import { ShowButtonProps } from '../ui/button/ShowButton';
import { Icon } from '../ui/utils/Icon';
import { Span } from '../ui/utils/Inlines';
import Link from '../ui/Link';
import { TextFieldProps } from '../ui/field/TextField';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import { LinkProps, Link as RRLink } from 'react-router-dom';
import { useId } from 'react-id-generator';
import DropdownItem, { DropdownItemProps } from 'react-bootstrap/esm/DropdownItem';

export const ListShowButton: FC<ShowButtonProps> = ({
    basePath = '',
    record,
    scrollToTop = true,
    className,
    label = 'ra.action.show',
    ...rest
}) => {
    const translate = useTranslate();

    return (<Link
        to={useMemo(
            () => ({
                pathname: record
                    ? `${linkToRecord(basePath, record.id)}/show`
                    : '',
                state: { _scrollToTop: scrollToTop },
            }),
            [basePath, record, scrollToTop]
        )}
        className={classnames("text-lowercase text-primary cell-action", className)}
    >
        <Icon stroke name="search" />
        <Span className="description">{translate(label)}</Span>
    </Link>)
}

export const ListMenuButton: FC<Omit<ShowButtonProps, "children"> & {
    children: React.ReactNode
}> = ({
    basePath = '',
    record,
    scrollToTop = true,
    className,
    label = 'actions',
    children,
    resource,
    ...rest
}) => {
        const translate = useTranslate();
        const [id] = useId();

        return (<Dropdown className={classnames("dropdown-caret", className)} onClick={(e: React.MouseEvent) => e.stopPropagation()} alignRight>
            <Dropdown.Toggle id={`list-dropdown-${id}`} variant="text" className="text-lowercase text-dark cell-action">
                <Icon stroke name="more_horiz" />
                <Span className="description">{translate(label)}</Span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {React.Children.map(
                    children,
                    child => React.isValidElement(child) ?
                        React.cloneElement(child, { resource, record }) :
                        null
                )}
            </Dropdown.Menu>
        </Dropdown>)
    }

export type ListMenuButtonLinkProps = Omit<LinkProps, "component">
export const ListMenuButtonLink: React.FC<ListMenuButtonLinkProps & DropdownItemProps> = (props) => (
    <Link {...props} component={ListMenuButtonItem} />
)

export const ListMenuButtonItem = DropdownItem

export const ListActions: React.FC<Omit<TextFieldProps, "childern"> & {
    children: React.ReactNode
}> = ({ children, ...props }) => {
    return (
        <Span className="cell-actions">
            {children ? React.Children.map(children, child =>
                React.isValidElement(child) && cloneElement(child, {
                    ...props
                })) : null}
        </Span>
    )
}

