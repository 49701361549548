import React from 'react';
import { memo } from 'react';
import classnames from 'classnames';
import {
    FieldTitle,
    useTranslate,
    SortPayload,
    useResourceContext,
} from 'ra-core';
import { TableSortLabel } from './TableSortLabel';


export const DatagridHeaderCell: React.FC<DatagridHeaderCellProps> = (props) => {
    const {
        className,
        field,
        currentSort,
        updateSort,
        isSorting
    } = props;
    const resource = useResourceContext(props);

    if (!field) return null

    return (
        <th
            className={classnames(className, field.props.headerClassName)}
        >
            {updateSort &&
                field.props.sortable !== false &&
                (field.props.sortBy || field.props.source) ? (
                // <Tooltip
                //     title={translate('ra.action.sort')}
                //     placement={
                //         field.props.textAlign === 'right'
                //             ? 'bottom-end'
                //             : 'bottom-start'
                //     }
                //     enterDelay={300}
                // >
                // </Tooltip>
                <TableSortLabel
                    active={
                        currentSort.field ===
                        (field.props.sortBy || field.props.source)
                    }
                    direction={currentSort.order === 'ASC' ? 'asc' : 'desc'}
                    data-sort={field.props.sortBy || field.props.source} // @deprecated. Use data-field instead.
                    data-field={field.props.sortBy || field.props.source}
                    data-order={field.props.sortByOrder || 'ASC'}
                    onClick={updateSort}
                >
                    <FieldTitle
                        label={field.props.label}
                        source={field.props.source}
                        resource={resource}
                    />
                </TableSortLabel>
            ) : (
                <FieldTitle
                    label={field.props.label}
                    source={field.props.source}
                    resource={resource}
                />
            )}
        </th>
    );
};

export interface DatagridHeaderCellProps
    extends React.HTMLProps<HTMLTableCellElement> {
    className?: string;
    field?: JSX.Element;
    isSorting?: boolean;
    resource: string;
    currentSort: SortPayload;
    updateSort?: (event: any) => void;
}

export default memo(
    DatagridHeaderCell,
    (props, nextProps) =>
        props.updateSort === nextProps.updateSort &&
        props.currentSort.field === nextProps.currentSort.field &&
        props.currentSort.order === nextProps.currentSort.order &&
        props.isSorting === nextProps.isSorting &&
        props.resource === nextProps.resource
);
