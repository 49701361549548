import * as React from 'react';
import {
    useEffect,
    useCallback,
    useContext,
    HtmlHTMLAttributes,
    ReactNode,
} from 'react';
import PropTypes from 'prop-types';
import { useListContext, useResourceContext } from 'ra-core';
import { Form, FormRenderProps, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import lodashSet from 'lodash/set';
import lodashGet from 'lodash/get';
import FilterFormInput from './FilterFormInput';

export const FilterForm = (props: FilterFormProps) => {
    const {
        className,
        filters,
        margin,
        initialValues,
        ...rest
    } = props;

    const {
        setFilters,
        displayedFilters,
        filterValues,
        hideFilter
    } = useListContext(props);


    const resource = useResourceContext(props);
    useEffect(() => {
        (filters as JSX.Element[]).forEach((filter) => {
            if (filter?.props?.alwaysOn && filter?.props?.defaultValue) {
                throw new Error(
                    'Cannot use alwaysOn and defaultValue on a filter input. Please set the filterDefaultValues props on the <List> element instead.'
                );
            }
        });
    }, [filters]);

    const getShownFilters = () =>
        (filters as JSX.Element[]).filter(
            (filterElement) =>
                filterElement?.props?.alwaysOn ||
                displayedFilters[filterElement.props.source] ||
                typeof lodashGet(initialValues, filterElement.props.source) !==
                'undefined'
        );

    const handleHide = useCallback(
        (event: React.MouseEvent<HTMLElement>) => hideFilter(event.currentTarget.dataset.key || ""),
        [hideFilter]
    );

    const mergedInitialValuesWithDefaultValues = mergeInitialValuesWithDefaultValues(
        initialValues || filterValues,
        filters
    );

    return (<Form
        onSubmit={handleFinalFormSubmit}
        initialValues={mergedInitialValuesWithDefaultValues}
        mutators={{ ...arrayMutators }}
        render={formProps => (
            <>
                <FormSpy
                    subscription={FormSpySubscription}
                    onChange={({ pristine, values, invalid }) => {
                        if (pristine || invalid) {
                            return;
                        }
                        setFilters(values, displayedFilters);
                    }}
                />
                <form
                    className={"form d-flex"}
                    onSubmit={handleSubmit}
                >
                    {getShownFilters().map((filterElement: JSX.Element) => (
                        <FilterFormInput
                            key={filterElement.props.source}
                            filterElement={filterElement}
                            handleHide={handleHide}
                            resource={resource}
                            margin={filterElement.props.margin || margin}
                            formGroupClassName="mr-3"
                        />
                    ))}
                </form>
            </>
        )}
    />
    );
};

const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    return false;
};


export interface FilterFormProps {
    className?: string;
    resource: string;
    filters: React.ReactNode[];
    margin?: string;
    initialValues: any;
}

export const mergeInitialValuesWithDefaultValues = (
    initialValues: any,
    filters: any
) => ({
    ...filters
        .filter(
            (filterElement: JSX.Element) =>
                filterElement.props.alwaysOn && filterElement.props.defaultValue
        )
        .reduce(
            (acc: any, filterElement: JSX.Element) =>
                lodashSet(
                    { ...acc },
                    filterElement.props.source,
                    filterElement.props.defaultValue
                ),
            {} as any
        ),
    ...initialValues,
});

const handleFinalFormSubmit = () => { };

// Options to instruct the FormSpy that it should only listen to the values and pristine changes
const FormSpySubscription = { values: true, pristine: true, invalid: true };

export default FilterForm;
