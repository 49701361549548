import * as React from 'react';
import { FC, ReactElement, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { FormGroupContextProvider, Record } from 'ra-core';
import { FormInput } from './FormInput';
import Card from 'react-bootstrap/esm/Card';

const hiddenStyle = { display: 'none' };

export const FormTab: FC<FormTabProps> = ({
    basePath = "",
    className,
    contentClassName,
    component: Component = Card.Body,
    children,
    hidden,
    icon,
    label,
    path,
    record,
    resource,
    value = "",
    ...rest
}) => {
    return (
        <FormGroupContextProvider name={value.toString()}>
            <span
                style={hidden ? hiddenStyle : {}}
                className={contentClassName}
                id={`tabpanel-${value}`}
                aria-labelledby={`tabheader-${value}`}
                // Set undefined instead of false because WAI-ARIA Authoring Practices 1.1
                // notes that aria-hidden="false" currently behaves inconsistently across browsers.
                aria-hidden={hidden || undefined}
            >
                <Component>
                    {React.Children.map(
                        children,
                        (input) =>
                            input && React.isValidElement(input) && (
                                <FormInput
                                    basePath={basePath}
                                    input={input}
                                    record={record}
                                    resource={resource}
                                    //TODO: REMOVE AFTER FORM INPUT REWRITE
                                    variant={"outlined"}
                                />
                            )
                    )}
                </Component>
            </span>
        </FormGroupContextProvider>
    )
};

export interface FormTabProps {
    basePath?: string;
    className?: string;
    children?: ReactNode;
    component?: React.ComponentType<any>;
    contentClassName?: string;
    hidden?: boolean;
    icon?: ReactElement;
    label: string | ReactElement;
    path?: string;
    record?: Record;
    resource?: string;
    value?: string | number;
}

FormTab.displayName = 'FormTab';