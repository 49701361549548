import {
    Typography,
    FormControl,
    FormHelperText,
    Checkbox,
} from '@material-ui/core';

import { Done as DoneIcon, Clear as ClearIcon, ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { green, red } from '@material-ui/core/colors';
import { InputProps, useInput, useGetIdentity } from 'react-admin';
import { useFormState } from "react-final-form"
import { Privilege, PrivilegeAssignment, User, WithPrivileges } from '@faktio/jsclient';
import Accordion from 'react-bootstrap/esm/Accordion';
import ListGroup from 'react-bootstrap/esm/ListGroup';
import Button from 'react-bootstrap/esm/Button';
import Table from 'react-bootstrap/esm/Table';
import { AccordionToggleProps } from 'react-bootstrap/esm/AccordionToggle';
import { useContext } from 'react';
import AccordionContext from 'react-bootstrap/esm/AccordionContext';
import classnames from 'classnames';
import { Icon } from '../ui/utils/Icon';

const permissions: { [key: string]: { [key: string]: Privilege } } = {
    "Obecné": {
        "Přihlásit se do webového rozraní": Privilege.LogIn,
        "Přihlásit se do pokladny": Privilege.LogInToCashRegister,
    },
    "Správa uživatelů - SYSTEM": {
        "Zobrazit uživatele": Privilege.ListSystemUsers,
        "Přidat uživatele": Privilege.AddSystemUsers,
        "Upravit uživatele": Privilege.ModifySystemUsers,
        "Smazat uživatele": Privilege.DeleteSystemUsers,
    },
    "Správa rolí - SYSTEM": {
        "Přiřadit libovolnou roli": Privilege.AssignAnyRole,
        "Zobrazit systémové role": Privilege.ListSystemRoles,
        "Přidat systémovou roli": Privilege.AddSystemRoles,
        "Upravit systémovou roli": Privilege.ModifySystemRoles,
        "Smazat systémovou roli": Privilege.DeleteSystemRoles,
    },
    "Společnosti": {
        "Zobrazit společnost": Privilege.ListCompanies,
        "Přidat společnost": Privilege.AddCompany,
        "Upravit společnost": Privilege.ModifyCompanies,
        "Smazat společnost": Privilege.DeleteCompanies,
    },
    "Uživatelé": {
        "Zobrazit uživatele": Privilege.ListUsers,
        "Přidatho uživatele": Privilege.AddUsers,
        "Upravit uživatele": Privilege.ModifyUsers,
        "Smazat uživatele": Privilege.DeleteUsers,
    },
    "Role": {
        "Zobrazit role": Privilege.ListRoles,
        "Přidat role": Privilege.AddRoles,
        "Upravit role": Privilege.ModifyRoles,
        "Smazat role": Privilege.DeleteRoles,
    },
    "Pokladní zařízení": {
        "Zobrazit pokladní zařízení": Privilege.ListCashRegisters,
        "Přidat pokladní zařízení": Privilege.AddCashRegisters,
        "Upravit pokladní zařízení": Privilege.ModifyCashRegisters,
        "Smazat pokladní zařízení": Privilege.DeleteCashRegisters,
        "Aktivovat pokladní zařízení": Privilege.ActivateCashRegisters,
    },
    "Položky ceníku": {
        "Zobrazit položky": Privilege.ListProducts,
        "Přidat položky": Privilege.AddProducts,
        "Upravit položky": Privilege.ModifyProducts,
        "Smazat položky": Privilege.DeleteProducts,
    },
    "Položky adresáře": {
        "Zobrazit položky": Privilege.ListPersons,
        "Přidat položky": Privilege.AddPersons,
        "Upravit položky": Privilege.ModifyPersons,
        "Smazat položky": Privilege.DeletePersons,
    },
    "Vydané faktury": {
        "Zobrazit vydané faktury": Privilege.ListSalesInvoices,
        "Přidat vydanou fakturu": Privilege.AddSalesInvoices,
        "Upravit vydanou fakturu": Privilege.ModifySalesInvoices,
        "Smazat vydanou fakturu": Privilege.DeleteSalesInvoices,
    },
    "Přijaté faktury": {
        "Zobrazit přijaté faktury": Privilege.ListPurchaseInvoices,
        "Přidat přijatou fakturu": Privilege.AddPurchaseInvoices,
        "Upravit přijatou fakturu": Privilege.ModifyPurchaseInvoices,
        "Smazat přijatou fakturu": Privilege.DeletePurchaseInvoices,
    },
    "Prodejky": {
        "Zobrazit prodejky": Privilege.ListRetail,
        "Přidat prodejku": Privilege.AddRetail,
        "Upravit prodejku": Privilege.ModifyRetail,
        "Smazat prodejku": Privilege.DeleteRetail,
    },
}

const CheckboxField = (props: InputProps & { disabled?: boolean }) => {
    const {
        input: { name, onChange, value, ...rest },
        meta: { touched, error },
        isRequired
    } = useInput({ ...props, type: "checkbox" });

    return (
        <FormControl error={error}>
            <Checkbox
                onChange={e => onChange(e?.target.checked)}
                required={isRequired}
                checked={value}
                disabled={props.disabled}
                {...rest}
            />
            <FormHelperText>{touched && error}</FormHelperText>
        </FormControl>
    );
};

// TODO: Move
export const AccordionListItem = (props: Omit<AccordionToggleProps, "as">) => {
    const { children, eventKey, ...rest } = props
    const currentEventKey = useContext(AccordionContext);

    const isActive = currentEventKey === eventKey;

    //TODO: Rewrite as <Row />
    const Component: React.FC = (props: any) => <ListGroup.Item
        className={classnames(
            "list-group-item-action",
            "bg-light",
            "d-flex",
            "flex-row",
            "justify-content-between",
            "align-items-center",
            "h5 m-0",
            //isActive && "active"
        )}
        as="button"
        {...props}>
        <>
            {children}
            <Icon name="expand_more" style={{ transition: "transform 0.3 ease-in-out", transform: isActive ? "rotate(-180deg)" : "rotate(0deg)" }} />
        </>
    </ListGroup.Item>

    return <Accordion.Toggle as={Component} eventKey={eventKey} {...rest} />
}

export const PermissionsListDatagrid = ({ record }: { record?: WithPrivileges }) => {
    return (
        <Accordion>
            <ListGroup className="list-group-flush">
                {Object.keys(permissions).map((title, i) => <>
                    <AccordionListItem eventKey={`permission-${i}`}>
                        {title}
                    </AccordionListItem>
                    <Accordion.Collapse eventKey={`permission-${i}`}>
                        <ListGroup className="list-group-flush">
                            <ListGroup.Item className="p-0">
                                <Table size={"small"}>
                                    <thead>
                                        <tr>
                                            <th className="expand">Název</th>
                                            <th className="shrink">Přiřazeno</th>
                                            <th className="shrink">Může přiřadit</th>
                                        </tr>
                                    </thead>
                                    <tbody className="datagrid-body border-bottom">
                                        {Object.keys(permissions[title]).map(name => {
                                            const assignment = record?.privileges?.find(assignment => assignment.privilege === permissions[title][name])
                                            return (
                                                <tr key={name} >
                                                    <td>
                                                        {name}
                                                    </td>
                                                    <td className="text-center">{assignment ?
                                                        <DoneIcon style={{ color: green[500] }} /> :
                                                        <ClearIcon style={{ color: red[500] }} />
                                                    }</td>
                                                    <td className="text-center">{assignment?.grantOption ?
                                                        <DoneIcon style={{ color: green[500] }} /> :
                                                        <ClearIcon style={{ color: red[500] }} />
                                                    }</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </ListGroup.Item>
                        </ListGroup>
                    </Accordion.Collapse>
                </>)
                }
            </ListGroup>
        </Accordion >
    )
}

// TODO: Refactor, both list and edit use a lot of common components
// TODO: Show only user grantable permissions (IN BOTH LISTS)
export const PermissionsEditDatagrid = () => {
    const { values } = useFormState({ subscription: { values: true } })
    const currentValue: PrivilegeAssignment[] = values.privileges

    const { loading: identityLoading, identity: rawIdentity } = useGetIdentity()
    const identity = identityLoading ? null : rawIdentity as User

    return (
        <Accordion>
            <ListGroup className="list-group-flush">
                {Object.keys(permissions).map((title, i) => <>
                    <AccordionListItem eventKey={`permission-${i}`}>
                        {title}
                    </AccordionListItem>
                    <Accordion.Collapse eventKey={`permission-${i}`}>
                        <ListGroup className="list-group-flush">
                            <ListGroup.Item className="p-0">

                                <Table size={"small"}>
                                    <thead>
                                        <tr>
                                            <th className="expand">Název</th>
                                            <th className="shrink">Přiřazeno</th>
                                            <th className="shrink">Může přiřadit</th>
                                        </tr>
                                    </thead>
                                    <tbody className="datagrid-body border-bottom">
                                        {Object.keys(permissions[title]).map(name => (
                                            <tr key={name}>
                                                <td>
                                                    {name}
                                                </td>
                                                <td className="text-center">
                                                    {//TODO: Own component
                                                    }
                                                    <CheckboxField
                                                        disabled={!identity?.canGrant(permissions[title][name])}
                                                        source="privileges"
                                                        format={() => currentValue?.find(assignment => assignment.privilege === permissions[title][name]) ? true : false}
                                                        parse={(value: boolean) => {
                                                            if (value) {
                                                                return [...currentValue, {
                                                                    privilege: permissions[title][name],
                                                                    grantOption: false
                                                                }]
                                                            } else {
                                                                const data = [...currentValue]
                                                                const index = data?.findIndex(assignment => assignment.privilege === permissions[title][name])
                                                                data.splice(index, 1)
                                                                return data
                                                            }
                                                        }}
                                                    />
                                                </td>
                                                <td className="text-center">
                                                    {//TODO: Own component
                                                    }
                                                    <CheckboxField
                                                        disabled={!identity?.canGrant(permissions[title][name]) || !currentValue?.find(assignment => assignment.privilege === permissions[title][name])}
                                                        source="privileges"
                                                        format={() => currentValue?.find(assignment => assignment.privilege === permissions[title][name] && assignment.grantOption) ? true : false}
                                                        parse={(value: boolean) => {
                                                            const data = [...currentValue]
                                                            const index = data.findIndex(assignment => assignment.privilege === permissions[title][name])
                                                            data[index].grantOption = value
                                                            return data
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </ListGroup.Item>
                        </ListGroup>
                    </Accordion.Collapse>
                </>)
                }
            </ListGroup>
        </Accordion>)
}
