import { escapePath, Record } from "ra-core";
import { ChangeEvent, Children, cloneElement, isValidElement, ReactElement, ReactNode, useState } from "react";
import Nav from "react-bootstrap/esm/Nav";
import { Route, useRouteMatch } from "react-router-dom";
import { CardNav } from "../layout/Card";
import { getTabFullPath, TabbedShowLayoutTabs } from "./TabbedShowLayoutTabs";

const sanitizeRestProps = ({
    children,
    className,
    record,
    resource,
    basePath,
    version,
    initialValues,
    staticContext,
    translate,
    tabs,
    ...rest
}: any) => rest;

export const TabbedShowLayout = (props: TabbedShowLayoutProps) => {
    const {
        basePath,
        children,
        tabsComponent: TabsComponent = CardNav,
        className,
        record,
        resource,
        syncWithLocation = true,
        tabs = <TabbedShowLayoutTabs />,
        value,
        version,
        ...rest
    } = props;
    const match = useRouteMatch();
    const nonNullChildren = Children.toArray(children).filter(
        child => child !== null
    );
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event: ChangeEvent<{}>, value: any): void => {
        if (!syncWithLocation) {
            setTabValue(value);
        }
    };

    if (!tabs) return null

    return (
        <div className={className} key={version} {...sanitizeRestProps(rest)}>
            <TabsComponent>
                {cloneElement(
                    tabs,
                    {
                        syncWithLocation,
                        onChange: handleTabChange,
                        value: tabValue,
                    },
                    nonNullChildren
                )}
            </TabsComponent>
            {Children.map(nonNullChildren, (tab, index) =>
                tab && isValidElement(tab) ? (
                    syncWithLocation ? (
                        <Route
                            exact
                            path={escapePath(
                                getTabFullPath(tab, index, match.url)
                            )}
                            render={() =>
                                cloneElement(tab, {
                                    context: 'content',
                                    resource,
                                    record,
                                    basePath,
                                })
                            }
                        />
                    ) : tabValue === index ? (
                        cloneElement(tab, {
                            context: 'content',
                            resource,
                            record,
                            basePath,
                        })
                    ) : null
                ) : null
            )}
        </div>
    );
};

export interface TabbedShowLayoutProps {
    basePath?: string;
    className?: string;
    children: ReactNode;
    record?: Record;
    resource?: string;
    syncWithLocation?: boolean;
    tabs?: ReactElement;
    tabsComponent?: React.ComponentType<any>;
    value?: any;
    version?: number;
}
