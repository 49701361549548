import * as React from 'react';
import { FunctionComponent, useCallback } from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup, { FormGroupProps } from '@material-ui/core/FormGroup';
import { FieldTitle, useInput, InputProps, useTranslate } from 'ra-core';

import { sanitizeInputRestProps } from './sanitizeInputRestProps';
import { BasicFormGroup, BasicFormGroupProps } from './BasicFormGroup';
import Form from 'react-bootstrap/esm/Form';
import { FormCheckProps } from 'react-bootstrap';

const BooleanInput: FunctionComponent<BooleanInputProps> = ({
    format,
    label,
    fullWidth,
    helperText,
    onBlur,
    onChange,
    onFocus,
    options,
    disabled,
    parse,
    resource,
    source,
    validate,
    formGroupOptions,
    formGroupClassName,
    ...rest
}) => {
    const {
        id,
        input: { type, ...inputProps },
        isRequired,
        meta,
    } = useInput({
        format,
        onBlur,
        onChange,
        onFocus,
        parse,
        resource,
        source,
        type: 'checkbox',
        validate,
        ...rest,
    });

    const translate = useTranslate()
    const { error, submitError, touched } = meta

    const isInvalid = !!(touched && (error || submitError))

    return (
        <Form.Group className={formGroupClassName}>
            <Form.Check
                id={id}
                label={label}
                isInvalid={isInvalid}
                feedback={translate(error || submitError || "")}
                {...inputProps}
                disabled={disabled}
                type={"switch"}
            />
        </Form.Group>
    );
};

export type BooleanInputProps = InputProps<FormCheckProps> &
    Omit<FormGroupProps, 'defaultValue' | 'onChange' | 'onBlur' | 'onFocus'> &
    Partial<BasicFormGroupProps>

export default BooleanInput;