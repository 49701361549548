import React from 'react';
import PropTypes from 'prop-types';
import { Record, useEditContext, useResourceDefinition } from 'ra-core';
import { ShowButton } from '../button/ShowButton';

// import { EditButton } from '../button';
// import TopToolbar from '../layout/TopToolbar';

/**
 * Action Toolbar for the Edit view
 *
 * Internal component. If you want to add or remove actions for a Edit view,
 * write your own EditActions Component. Then, in the <Edit> component,
 * use it in the `actions` prop to pass a custom component.
 *
 */
export const EditActions = ({ className, ...rest }: EditActionsProps) => {
    const { basePath, record } = useEditContext(rest);
    const { hasShow } = useResourceDefinition(rest);
    return (<>
        { hasShow && <ShowButton basePath={basePath} record={record} size="sm" />}
    </>);
};

export interface EditActionsProps {
    basePath?: string;
    className?: string;
    data?: Record;
    hasEdit?: boolean;
    hasList?: boolean;
    resource?: string;
}

