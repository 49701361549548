import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState, refreshView, useRefreshWhenVisible } from 'ra-core';

import { CircularProgress } from '../layout/CircularProgress';
import { Icon } from "../utils/Icon";
import Button from 'react-bootstrap/esm/Button';
import { useCallback } from 'react';

export interface RefreshProps {
    className?: string;
}

export const RefreshButton: React.FC<RefreshProps> = ({ className }) => {
    useRefreshWhenVisible();

    const dispatch = useDispatch();
    const handleClick = useCallback(
        event => {
            event.preventDefault();
            dispatch(refreshView());
        },
        [dispatch]
    );

    const loading = useSelector<ReduxState>(state => state.admin.loading > 0);

    return (
        <Button
            onClick={handleClick}
            variant="refresh"
            className={classNames(loading ? "text-primary" : null, className)}
            style={{ "transition": "color .3s ease-in-out" }}
            disabled={!!loading}>

            {loading ? (
                <CircularProgress />
            ) : (
                <Icon name={"cached"} />
            )}

        </Button>
    )
};

