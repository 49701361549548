import * as React from 'react';
import { cloneElement, memo, FC, ReactElement } from 'react';
import classnames from 'classnames';
import { shallowEqual } from 'react-redux';
import { Identifier, Record, RecordMap } from 'ra-core';
import DatagridRow, { PureDatagridRow, RowClickFunction } from './DatagridRow';

// TODO: tbody to TableBody
const DatagridBody = React.forwardRef<HTMLTableSectionElement, DatagridBodyProps>(
    (
        {
            basePath,
            children,
            className,
            data,
            expand,
            hasBulkActions,
            hover,
            ids,
            onToggleItem,
            resource,
            row,
            rowClick,
            rowStyle,
            selectedIds,
            isRowSelectable,
            ...rest
        },
        ref
    ) => {
        if (!ids || !row || !data) return null
        return (
            <tbody
                ref={ref}
                className={classnames('datagrid-body', className)}
                {...rest}
            >
                {ids.map((id, rowIndex) =>
                    cloneElement(
                        row,
                        {
                            basePath,
                            // className: classnames(classes.row, {
                            //     [classes.rowEven]: rowIndex % 2 === 0,
                            //     [classes.rowOdd]: rowIndex % 2 !== 0,
                            //     [classes.clickableRow]: rowClick,
                            // }),
                            expand,
                            hasBulkActions: hasBulkActions && selectedIds,
                            hover,
                            id,
                            key: id,
                            onToggleItem,
                            record: data[id],
                            resource,
                            rowClick,
                            selectable:
                                !isRowSelectable || isRowSelectable(data[id]),
                            selected: selectedIds?.includes(id),
                            style: rowStyle ? rowStyle(data[id], rowIndex) : null,
                        },
                        children
                    )
                )}
            </tbody>
        )
    }
);

DatagridBody.defaultProps = {
    data: {},
    hasBulkActions: false,
    ids: [],
    row: <DatagridRow />,
};

export interface DatagridBodyProps extends Omit<React.HTMLProps<HTMLTableSectionElement>, "data"> {
    basePath?: string;
    className?: string;
    data?: RecordMap;
    expand?:
    | ReactElement
    | FC<{
        basePath: string;
        id: Identifier;
        record: Record;
        resource: string;
    }>;
    hasBulkActions?: boolean;
    hover?: boolean;
    ids?: Identifier[];
    onToggleItem?: (
        id: Identifier,
        event: React.TouchEvent | React.MouseEvent
    ) => void;
    record?: Record;
    resource?: string;
    row?: ReactElement;
    rowClick?: string | RowClickFunction;
    rowStyle?: (record: Record, index: number) => any;
    selectedIds?: Identifier[];
    isRowSelectable?: (record: Record) => boolean;
}

const areEqual = (prevProps: DatagridBodyProps, nextProps: DatagridBodyProps) => {
    const {
        children: _1,
        expand: _2,
        row: _3,
        ...prevPropsWithoutChildren
    } = prevProps;
    const {
        children: _4,
        expand: _5,
        row: _6,
        ...nextPropsWithoutChildren
    } = nextProps;
    return shallowEqual(prevPropsWithoutChildren, nextPropsWithoutChildren);
};

export const PureDatagridBody = memo(DatagridBody, areEqual);

// @ts-ignore
PureDatagridBody.defaultProps = {
    row: <PureDatagridRow />,
};

export default DatagridBody;