import React from 'react';
import { FunctionComponent } from 'react';
import { useInput, InputProps } from 'ra-core';

import { sanitizeInputRestProps } from './sanitizeInputRestProps';
import { BasicInput, BasicInputProps } from './BasicInput';
import { BasicFormGroupProps } from './BasicFormGroup';

export type NumberInputProps = InputProps<BasicInputProps> &
    Partial<BasicFormGroupProps> &
    Omit<BasicInputProps, 'label' | 'helperText'> & {
        step?: string | number;
        min?: string | number;
        max?: string | number;
    }

const convertStringToNumber = (value: string) => {
    const float = parseFloat(value);

    return isNaN(float) ? null : float;
};

const NumberInput: FunctionComponent<NumberInputProps> = ({
    source,
    resource,
    parse = convertStringToNumber,
    ...rest
}) => {
    const {
        id,
        input,
        isRequired,
        meta: { error, submitError, touched },
    } = useInput({
        type: 'number',
        parse,
        source,
        resource,
        ...rest,
    });

    return (<BasicInput id={id} error={error} submitError={submitError} touched={touched} {...sanitizeInputRestProps(rest)} {...input} />);
};

export default NumberInput;