import * as React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useResourceContext, useTranslate } from 'ra-core';
import { Icon } from '../../utils/Icon';
import Button from '../../button/Button';

const emptyRecord = {};

const FilterFormInput: React.FC<FilterFormInputProps> = (props) => {
    const { filterElement, handleHide, resource: _resource, ...rest } = props;
    const resource = useResourceContext(props);
    const translate = useTranslate();

    const hideButton = !filterElement?.props.alwaysOn && (<Button
        icon={"highlight_off"}
        size="sm"
        className="hide-filter"
        data-key={filterElement.props.source}
        onClick={handleHide}
        variant='outline-dark'
    />)

    return (
        <div data-source={filterElement.props.source}>
            {React.cloneElement(filterElement, {
                allowEmpty:
                    filterElement.props.allowEmpty === undefined
                        ? true
                        : filterElement.props.allowEmpty,
                resource,
                record: emptyRecord,
                helperText: false,
                // ignore defaultValue in Field because it was already set in Form (via mergedInitialValuesWithDefaultValues)
                defaultValue: undefined,
                append: hideButton,
                ...rest
            })}
        </div>
    );
};

export interface FilterFormInputProps {
    filterElement: JSX.Element,
    handleHide: (event: React.MouseEvent<HTMLElement>) => void,
    resource: string,
    [key: string]: any
}

export default FilterFormInput;
