import { Company } from "@faktio/jsclient";
import { VATRate } from '@marekl/prices';
import React from "react";
import { FormDataConsumer, RadioButtonGroupInput, required } from "react-admin";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { Create, CreateProps } from "../ui/detaill/Create";
import { Edit, EditProps } from "../ui/detaill/Edit";
import TextField from "../ui/field/TextField";
import { FormTab } from "../ui/form/FormTab";
import SimpleForm from "../ui/form/SimpleForm";
import { TabbedForm } from "../ui/form/TabbedForm";
import BooleanInput from "../ui/inputs/BooleanInput";
import NumberInput from "../ui/inputs/NumberInput";
import SelectInput from "../ui/inputs/SelectInput";
import TextInput from "../ui/inputs/TextInput";
import Datagrid from "../ui/list/datagrid/Datagrid";
import { List, ListProps } from "../ui/list/List";
import { Title } from "../ui/utils/Inlines";
import { ARESAutocompleteField, ARESInput } from "../utils/Inputs";
import { ContactLineField } from "./Contacts";

// Todo: pessimistic delete many
export const CompaniesList = (props: ListProps) => (
    <List {...props} title="Společnosti">
        <Datagrid rowClick="edit">
            {/* @ts-ignore */}
            <TextField source="contact.businessName" label="Obchodní název" />
            <ContactLineField label="Adresa" />
            <TextField source="contact.ICO" label="IČO" />
            <TextField source="contact.DIC" label="DIČ" />
        </Datagrid>
    </List>
);

export const CompanyCreate = (props: CreateProps) => (
    <Create {...props} title="Nová společnost">
        <SimpleForm mutationMode="pessimistic" redirect="edit" variant="outlined">
            <>
                <Row className="justify-content-between">
                    <Col md={6} className="pb-3">
                        <Title as="h4">Základní informace</Title>
                        <ARESAutocompleteField source="contact.businessName" label="Obchodní název" validate={required()} />
                        <Row>
                            <Col>
                                <ARESInput source="contact.ICO" label="IČO" />
                            </Col>
                            <Col>
                                <FormDataConsumer subscription={{ values: true }}>
                                    {({ formData, ...rest }) => formData?.contact?.VATPayer ?
                                        <TextInput source="contact.DIC" label="DIČ" {...rest} />
                                        : null
                                    }
                                </FormDataConsumer>
                            </Col>
                            <Col xs="auto" className="d-flex align-items-center">
                                <BooleanInput source="contact.VATPayer" label="Plátce DPH" formGroupClassName={"mb-0"} />
                            </Col>
                        </Row>

                        <TextInput source="contact.bankAccount" label="Číslo účtu" />

                        <Title as="h4" className="pt-3">Adresa</Title>
                        <TextInput source="contact.street" label="Ulice" />
                        <Row className="flex-nowrap">
                            <Col xs="auto">
                                <TextInput source="contact.postCode" label="PSČ" />
                            </Col>
                            <Col>
                                <TextInput source="contact.city" label="Město" />
                            </Col>
                        </Row>
                        <SelectInput source="contact.state" label="Stát" choices={[
                            { id: 'Česká republika', name: 'Česká republika' },
                        ]} defaultValue={'Česká republika'} />

                    </Col>
                    <Col md={5} lg={4}>
                        <Title as="h4">Kontaktní údaje</Title>
                        <TextInput source="contact.email" label="Email" validate={required()} />
                        <TextInput source="contact.phone" label="Telefon" />
                    </Col>
                </Row>
            </>
        </SimpleForm>
    </Create >
);

export const CompanyEdit = (props: EditProps) => (
    <Edit
        {...props}
        mutationMode="pessimistic"
        title="Detail firmy"
        subtitle={(record) => record && (record as Company).getContact().getBusinessName()}
    >

        <TabbedForm redirect="edit">
            <FormTab label="Kontaktní údaje">
                <>
                    <Row className="justify-content-between">
                        <Col md={6} className="pb-3">
                            {/* <TextInput source="contact.businessName" label="Obchodní název" validate={required()} fullWidth variant="outlined" /> */}
                            <ARESAutocompleteField source="contact.businessName" label="Obchodní název" validate={required()} />
                            <Row>
                                <Col>
                                    <ARESInput source="contact.ICO" label="IČO" />
                                </Col>
                                <Col>
                                    <FormDataConsumer subscription={{ values: true }}>
                                        {({ formData, ...rest }) => formData?.contact?.VATPayer ?
                                            <TextInput source="contact.DIC" label="DIČ" {...rest} validate={required()} />
                                            : null
                                        }
                                    </FormDataConsumer>
                                </Col>
                                <Col xs="auto" className="d-flex align-items-center">
                                    <BooleanInput source="contact.VATPayer" label="Plátce DPH" formGroupClassName={"mb-0 mt-2"} />
                                </Col>
                            </Row>

                            <TextInput source="contact.bankAccount" label="Číslo účtu" />

                            <Title as="h4" className="pt-3">Adresa</Title>
                            <TextInput source="contact.street" label="Ulice" />
                            <Row className="flex-nowrap">
                                <Col xs="auto">
                                    <TextInput source="contact.postCode" label="PSČ" />
                                </Col>
                                <Col>
                                    <TextInput source="contact.city" label="Město" />
                                </Col>
                            </Row>
                            <SelectInput source="contact.state" label="Stát" choices={[
                                { id: 'Česká republika', name: 'Česká republika' },
                            ]} defaultValue={'Česká republika'} />

                        </Col>
                        <Col md={5} lg={4}>
                            <TextInput source="contact.email" label="Email" />
                            <TextInput source="contact.phone" label="Telefon" />
                        </Col>
                    </Row>
                </>
            </FormTab>
            <FormTab label="Nastavení" path="settings">
                <FormDataConsumer subscription={{ values: true }}>
                    {({ formData, ...rest }) => formData.contact.VATPayer &&
                        <RadioButtonGroupInput
                            label="Výchozí sazba DPH"
                            source="defaultVATRate"
                            choices={[
                                { id: VATRate.ZeroVATRate, name: 'Bez DPH' },
                                { id: VATRate.SecondReducedVATRate, name: '10 %' },
                                { id: VATRate.FirstReducedVATRate, name: '15 %' },
                                { id: VATRate.BasicVATRate, name: '21 %' }
                            ]}
                            {...rest}
                        />
                    }
                </FormDataConsumer>
                <NumberInput label="Výchozí splatnost faktury (dny)" source="defaultDueDays" />
            </FormTab>
        </TabbedForm>
    </Edit >
);


