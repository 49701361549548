import React from 'react';
import { FC, ReactElement } from 'react';
import BButton, { ButtonProps as BButtonProps } from 'react-bootstrap/esm/Button';
import classnames from 'classnames';
import { Record, useTranslate } from 'ra-core';
import { LocationDescriptor } from 'history';
import { Icon, IconType, IconVariant } from '../utils/Icon';
import { CircularProgress } from '../layout/CircularProgress';

/**
 * A generic Button with side icon. Only the icon is displayed on small screens.
 *
 * The component translates the label. Pass the icon as child.
 * The icon displays on the left side of the button by default. Set alignIcon prop to 'right' to inverse.
 *
 * @example
 *
 * <Button label="Edit" color="secondary" onClick={doEdit}>
 *   <ContentCreate />
 * </Button>
 *
 */
//TODO: What is RedirectionSideEffect?
const Button: FC<ButtonProps> = React.forwardRef((props, ref) => {
    const {
        alignIcon = 'left',
        children,
        className,
        disabled,
        label,
        icon,
        loading,
        iconVariant,
        ...rest
    } = props;
    const translate = useTranslate();

    const iconElement = typeof icon === "string" ? <Icon name={icon} variant={iconVariant} className={label || children ? "mr-1" : "m-0"} /> : icon

    const spinnerElement = <CircularProgress className="mr-1" />

    return (
        <BButton
            className={classnames(className)}
            aria-label={label ? translate(label, { _: label }) : undefined}
            disabled={disabled}
            ref={ref}
            {...sanitizeButtonRestProps(rest)}
        >
            {alignIcon === 'left' && (
                loading ? spinnerElement : iconElement)
            }
            {label && translate(label, { _: label })}
            {children}
            {alignIcon === 'right' &&
                (loading ? spinnerElement : iconElement)
            }
        </BButton>
    );
});

Button.defaultProps = {
    color: 'primary',
};

interface Props {
    alignIcon?: 'left' | 'right';
    children?: ReactElement;
    to?: string | LocationDescriptor;
    label?: string;
    icon?: ReactElement | IconType;
    iconVariant?: IconVariant;
    // May be injected by Toolbar
    basePath?: string;
    // TODO: What is this?
    // redirect?: RedirectionSideEffect;
    // handleSubmit?: (event?: SyntheticEvent<HTMLFormElement>) => Promise<Object>;
    // handleSubmitWithRedirect?: (redirect?: RedirectionSideEffect) => void;
    // invalid?: boolean;
    // onSave?: (values: object, redirect: RedirectionSideEffect) => void;
    loading?: boolean;
    // submitOnEnter?: boolean;
    // pristine?: boolean;
    // undoable?: boolean;
    record?: Record;
    resource?: string;
}

export type ButtonProps = Props & BButtonProps;

export const sanitizeButtonRestProps = ({
    // The next props are injected by Toolbar
    basePath,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    onSave,
    pristine,
    record,
    redirect,
    resource,
    saving,
    submitOnEnter,
    undoable,
    ...rest
}: any) => rest;


export default Button;