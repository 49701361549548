import React, { cloneElement, FC, ReactElement, SyntheticEvent } from 'react';
import {
    useTranslate,
    useNotify,
    RedirectionSideEffect,
    OnSuccess,
    OnFailure,
    TransformData,
    Record,
    HandleSubmitWithRedirect,
    useSaveContext,
    useFormContext,
} from 'ra-core';

import Button, { ButtonProps, sanitizeButtonRestProps } from './Button';
import { FormRenderProps } from 'react-final-form';
import classnames from 'classnames';

/**
 * Submit button for resource forms (Edit and Create).
 *
 * @typedef {Object} Props the props you can use (other props are injected by the <Toolbar>)
 * @prop {string} className
 * @prop {string} label Button label. Defaults to 'ra.action.save', translated.
 * @prop {boolean} disabled Disable the button.
 * @prop {string} variant Material-ui variant for the button. Defaults to 'contained'.
 * @prop {ReactElement} icon
 * @prop {string|boolean} redirect Override of the default redirect in case of success. Can be 'list', 'show', 'edit' (for create views), or false (to stay on the creation form).
 * @prop {function} onSave (deprecated)
 * @prop {function} onSuccess Callback to execute instead of the default success side effects. Receives the dataProvider response as argument.
 * @prop {function} onFailure Callback to execute instead of the default error side effects. Receives the dataProvider error response as argument.
 * @prop {function} transform Callback to execute before calling the dataProvider. Receives the data from the form, must return that transformed data. Can be asynchronous (and return a Promise)
 *
 * @param {Props} props
 *
 * @example // with custom redirection
 *
 * <SaveButton label="post.action.save_and_edit" redirect="edit" />
 *
 * @example // with no redirection
 *
 * <SaveButton label="post.action.save_and_add" redirect={false} />
 *
 * @example // with custom success side effect
 *
 * const MySaveButton = props => {
 *     const notify = useNotify();
 *     const redirect = useRedirect();
 *     const onSuccess = (response) => {
 *         notify(`Post "${response.data.title}" saved!`);
 *         redirect('/posts');
 *     };
 *     return <SaveButton {...props} onSuccess={onSuccess} />;
 * }
 */
export const SaveButton: FC<SaveButtonProps> = props => {
    const {
        className,
        invalid,
        label = 'ra.action.save',
        disabled,
        redirect,
        saving,
        submitOnEnter,
        icon = "save_alt",
        onClick,
        handleSubmitWithRedirect,
        onSave,
        onSuccess,
        onFailure,
        transform,
        ...rest
    } = props;

    const notify = useNotify();
    const translate = useTranslate();
    const formContext = useFormContext();
    const { setOnSuccess, setOnFailure, setTransform } = useSaveContext(props);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        // deprecated: use onSuccess and transform instead of onSave
        if (typeof onSave === 'function') {
            if (process.env.NODE_ENV !== 'production') {
                console.warn(
                    '<SaveButton onSave> prop is deprecated, use the onSuccess prop instead.'
                );
                if (!formContext || !formContext.setOnSave) {
                    console.warn(
                        'Using <SaveButton> outside a FormContext is deprecated.'
                    );
                }
            }
            if (formContext && formContext.setOnSave) {
                formContext.setOnSave(onSave);
            }
        } else {
            if (
                process.env.NODE_ENV !== 'production' &&
                (!formContext || !formContext.setOnSave)
            ) {
                console.warn(
                    'Using <SaveButton> outside a FormContext is deprecated.'
                );
            }

            if (formContext && formContext.setOnSave) {
                // we reset to the Form default save function
                formContext.setOnSave();
            }
        }
        if (onSuccess && setOnSuccess) {
            setOnSuccess(onSuccess);
        }
        if (onFailure && setOnFailure) {
            setOnFailure(onFailure);
        }
        if (transform && setTransform) {
            setTransform(transform);
        }
        if (saving) {
            // prevent double submission
            event.preventDefault();
        } else {
            if (invalid) {
                notify('ra.message.invalid_form', 'warning');
            }
            // always submit form explicitly regardless of button type
            if (event) {
                event.preventDefault();
            }
            if (handleSubmitWithRedirect)
                handleSubmitWithRedirect(redirect);
        }

        if (typeof onClick === 'function') {
            onClick(event);
        }
    };

    const type = submitOnEnter ? 'submit' : 'button';
    const displayedLabel = label && translate(label, { _: label });
    return (
        <Button
            type={type}
            onClick={handleClick}
            label={displayedLabel}
            disabled={disabled || saving}
            loading={saving}
            icon={icon}
            className={classnames("text-lowercase", className)}
            {...sanitizeButtonRestProps(rest)}
        />
    );
};

interface Props {
    classes?: object;
    className?: string;
    handleSubmitWithRedirect?:
    | HandleSubmitWithRedirect
    | FormRenderProps['handleSubmit'];
    // @deprecated
    onSave?: (values: object, redirect: RedirectionSideEffect) => void;
    onSuccess?: OnSuccess;
    onFailure?: OnFailure;
    transform?: TransformData;
    icon?: ReactElement;
    invalid?: boolean;
    label?: string;
    onClick?: () => void;
    disabled?: boolean;
    redirect?: RedirectionSideEffect;
    saving?: boolean;
    submitOnEnter?: boolean;
    variant?: string;
    // May be injected by Toolbar - sanitized in Button
    basePath?: string;
    handleSubmit?: (event?: SyntheticEvent<HTMLFormElement>) => Promise<Object>;
    record?: Record;
    resource?: string;
    undoable?: boolean;
}

export type SaveButtonProps = Props & ButtonProps;

