import * as React from 'react';
import { FC, ReactElement, ReactEventHandler, SyntheticEvent } from 'react';
import {
    Record,
    RedirectionSideEffect,
    useDeleteWithUndoController,
    OnSuccess,
    OnFailure,
    useResourceContext,
} from 'ra-core';

import Button, { ButtonProps } from './Button';
import classnames from 'classnames';

export const DeleteWithUndoButton: FC<DeleteWithUndoButtonProps> = props => {
    const {
        label = 'ra.action.delete',
        className,
        icon = "delete",
        onClick,
        record,
        basePath,
        redirect = 'list',
        onSuccess,
        onFailure,
        ...rest
    } = props;
    const resource = useResourceContext(props);
    const { loading, handleDelete } = useDeleteWithUndoController({
        record,
        resource,
        basePath,
        redirect,
        onClick,
        onSuccess,
        onFailure,
    });

    return (
        <Button
            onClick={handleDelete}
            disabled={loading}
            label={label}
            loading={loading}
            icon={icon}
            className={classnames("text-lowercase", className)}
            {...rest}
        />
    );
};


interface Props {
    basePath?: string;
    classes?: object;
    className?: string;
    icon?: ReactElement;
    label?: string;
    onClick?: ReactEventHandler<any>;
    record?: Record;
    redirect?: RedirectionSideEffect;
    resource?: string;
    // May be injected by Toolbar - sanitized in Button
    handleSubmit?: (event?: SyntheticEvent<HTMLFormElement>) => Promise<Object>;
    handleSubmitWithRedirect?: (redirect?: RedirectionSideEffect) => void;
    invalid?: boolean;
    pristine?: boolean;
    saving?: boolean;
    submitOnEnter?: boolean;
    undoable?: boolean;
    onSuccess?: OnSuccess;
    onFailure?: OnFailure;
}

export type DeleteWithUndoButtonProps = Props & ButtonProps;
