import React, { memo } from 'react';

import classnames from "classnames";
import { Card, CardProps } from "react-bootstrap";
import { createComponent } from '../tools/createComponent';
import { Identifier } from 'ra-core';
import { Icon } from '../utils/Icon';
import { ButtonBase } from '../button/ButtonBase';

/*
 * CardViewProps
 * Omitted border, because this card has none
 */
interface CardViewProps extends Omit<CardProps, "border"> {
    actions?: JSX.Element,
    filters?: JSX.Element,
    title: string,
    subtitle?: string,
    bulkActions?: JSX.Element,
    selectedIds?: Identifier[],
    onUnselectItems?: () => void,
    footer?: JSX.Element
}

export const CardActions = createComponent("div", "card-actions")
export const CardBulkActions = createComponent("div", "card-bulk-actions")
export const CardNav = createComponent("div", "card-nav")

export const CardTitles = memo((
    { title, subtitle, titleComponent, subtitleComponent, className }: {
        title: string | JSX.Element, subtitle?: string | JSX.Element,
        titleComponent?: React.ElementType,
        subtitleComponent?: React.ElementType,
        className?: string
    }) => {
    const Title = titleComponent || "h2"
    const Subtitle = subtitleComponent || "h3"

    return (
        <div className={"card-titles"}>
            <Title className="card-title mb-0 d-inline-flex align-items-center">{title}</Title>
            {subtitle && <Subtitle className="card-subtitle h4 text-muted">{subtitle}</Subtitle>}
        </div>
    )
})

export const CardView: React.FC<CardViewProps> = ({ actions, filters, title, subtitle, className, footer, children, bulkActions, selectedIds, onUnselectItems, ...rest }) => {
    return (
        <Card className={classnames("card-main", "shadow-sm", "border-0", className)} {...rest}>
            <Card.Header>
                <CardTitles title={title} subtitle={subtitle} />
                <CardActions>
                    {actions}
                    {filters && React.cloneElement(filters, { context: 'button' })}
                </CardActions>
                <CardBulkActions className={classnames(selectedIds && selectedIds.length > 0 && "active")}>
                    <CardTitles
                        title={<>
                            <ButtonBase
                                onClick={() => onUnselectItems && onUnselectItems()}
                                className="d-inline-flex align-items-center"
                            >
                                <Icon name="close" className="mr-2" />
                            </ButtonBase>
                            {selectedIds && selectedIds.length} items selected
                        </>}
                        titleComponent="h5"
                    />
                    <CardActions>
                        {bulkActions}
                    </CardActions>
                </CardBulkActions>
            </Card.Header>
            {filters}
            {children}
            {footer && <Card.Footer>
                {footer}
            </Card.Footer>}
        </Card >)
}

export const CardBody = Card.Body
export type CardBodyProps = React.HTMLProps<HTMLDivElement>

export const CardFooter = Card.Footer
export type CardFooterProps = React.HTMLProps<HTMLDivElement>