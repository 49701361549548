import * as React from 'react';
import { Children, cloneElement, ReactElement } from 'react';
import {
    defaultExporter,
    getListControllerProps,
    ListControllerProps,
    useListContext,
    useResourceDefinition,
    useVersion,
} from 'ra-core';

import { Pagination } from "react-admin"

import { ListActions as DefaultActions } from './ListActions';
import { MainTitle } from '../layout/MainTitle';
import { ListProps } from './List';
import { CardView } from '../layout/Card';
import BulkDeleteWithConfirmButton from '../button/BulkDeleteWithConfirmButton';
import { Empty } from './Empty';
import { Card } from 'react-bootstrap';

export const ListView = (props: ListViewProps) => {
    const {
        actions,
        aside,
        filters,
        bulkActionButtons,
        pagination,
        children,
        className,
        component: Content,
        exporter = defaultExporter,
        title,
        subtitle,
        mainTitle,
        empty,
        footer,
        ...rest
    } = props;
    const controllerProps = getListControllerProps(props); // deprecated, to be removed in v4
    const listContext = useListContext(props);
    const {
        defaultTitle,
        total,
        loaded,
        loading,
        filterValues,
        selectedIds,
    } = listContext
    const version = useVersion();

    const finalActions =
        actions === true || typeof actions === 'undefined' ?
            (<DefaultActions />) : (actions);


    const renderList = () => (
        <>
            <Content
                title={title || defaultTitle}
                subtitle={subtitle}
                key={version}
                actions={finalActions &&
                    cloneElement(finalActions, {
                        ...controllerProps,
                        ...finalActions.props
                    })
                }
                bulkActions={bulkActionButtons &&
                    cloneElement(bulkActionButtons, {
                        onUnselectItems: listContext.onUnselectItems,
                        ...controllerProps,
                        ...bulkActionButtons.props
                    })
                }
                filters={filters}
                selectedIds={selectedIds}
                onUnselectItems={listContext.onUnselectItems}
                className={className}
                footer={footer}
            >
                {children &&
                    // @ts-ignore-line
                    cloneElement(Children.only(children), {
                        ...controllerProps, // deprecated, use ListContext instead, to be removed in v4
                        hasBulkActions: bulkActionButtons !== false,
                    })}
                {pagination && cloneElement(pagination, listContext)}
            </Content>
        </>
    );

    const shouldRenderEmptyPage =
        loaded && !loading && total === 0 && !Object.keys(filterValues).length;

    const { options } = useResourceDefinition(props);

    return (<>
        <MainTitle defaultTitle={defaultTitle} title={mainTitle || options?.label} />
        {shouldRenderEmptyPage && empty !== false
            ? cloneElement(empty, listContext)
            : renderList()}
    </>);
};

const DefaultBulkActionButtons = (props: any) =>
    <BulkDeleteWithConfirmButton variant="danger" size="sm" {...props} />;

ListView.defaultProps = {
    classes: {},
    component: CardView,
    bulkActionButtons: <DefaultBulkActionButtons />,
    pagination: <Pagination />,
    empty: <Empty />,
};

export interface ListViewProps
    extends Omit<ListProps, 'basePath' | 'hasCreate' | 'perPage' | 'resource'>,
    ListControllerProps {
    children: ReactElement;
    footer?: JSX.Element;
}

export default ListView;