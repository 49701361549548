import React, { useState } from 'react';
import { useInput, InputProps } from 'ra-core';

import { sanitizeInputRestProps } from './sanitizeInputRestProps';
import { BasicInput, BasicInputProps } from './BasicInput';
import { TextInputProps } from './TextInput';
import Button from '../button/Button';

export type PasswordInputProps = TextInputProps & {
    initiallyVisible?: boolean;
}

const PasswordInput: React.FC<PasswordInputProps> = ({
    source,
    resource,
    initiallyVisible,
    ...rest
}) => {
    const {
        id,
        input,
        isRequired,
        meta: { error, submitError, touched },
    } = useInput({
        type: 'text',
        source,
        resource,
        ...rest,
    });

    const [visible, setVisible] = useState(initiallyVisible);
    const handleClick = () => {
        setVisible(!visible);
    };

    if (!rest.append)
        rest.append = <Button
            icon={visible ? "visibility_off" : "visibility"}
            size="sm"
            className="visibility-toggle"
            onClick={handleClick}
            variant='outline-dark'
        />

    return (<BasicInput
        id={id}
        error={error}
        submitError={submitError}
        touched={touched}
        {...sanitizeInputRestProps(rest)}
        {...input}
        type={visible ? 'text' : 'password'}

    />);
};

export default PasswordInput;